import {
  Flex,
} from "@chakra-ui/react";
import React from "react";

export default function SidebarDocs() {
  const appVersion = process.env.REACT_APP_VERSION;
  return (
    <Flex
      justify="center"
      direction="column"
      align="center"
      opacity={0.4}
      borderRadius="30px"
      position="relative"
      fontWeight={700}
      fontSize={16}
    >
      {appVersion}
    </Flex>
  );
}
