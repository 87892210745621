import {
    Divider,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Table,
    Td,
    Tr,
    Th,
    Tbody,
    Thead,
    Tfoot,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  
  import Form from "react-bootstrap/Form";
  import Col from "react-bootstrap/Col";
  import Row from "react-bootstrap/Row";
  import moment from "moment";
  import dateTimeFormat from "jsFunctions/dateTimeFormat";
  import { poNumberFormate } from "jsFunctions/helperFunctions";
  import { MdOutlineFileDownload } from "react-icons/md";
  import { InputGroup, Tab, Tabs } from "react-bootstrap";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
  
  const DataRow = ({ label, value,type }) => {
    return (
      <>
        <Form.Label>{label}</Form.Label>
      {type === 'Amount' 
      ?
      <InputGroup>
        <InputGroup.Text style={{height:'2rem', border:0, background:'lightGray'}}><span className="rupee-symbol">₹</span> </InputGroup.Text>
        <Form.Control
        type="text"
        value={value !== "undefined" && value !== "" ? formatAmountIndianRupees(value) : " "}
        disabled
      />
      </InputGroup>
      :
      <Form.Control
        type="text"
        value={value !== "undefined" && value !== "" ? value : " "}
        disabled
      />
      }
      </>
    );
  };
  
  const ExternalInfoModel = ({ openInfoModal, closeInfoModal, selectedData, role }) => {
    const [getPoMonth, setGetPoMonth] = useState(moment().format("yyyy-MM"));
    const [selectedItem, setSelectedItem] = useState(selectedData);
    const [totalSpentAmount , setTotalSpentAmount] = useState(0);
  
    const [activeTab, setActiveTab] = useState("information");
    const [historyTab, setHistoryTab] = useState("requested");
  
    const [invoiceData, setInvoiceData] = useState([])
  
    useEffect(() => {
      setSelectedItem(selectedData);
    }, [selectedData]);
  
    useEffect(() => {
      if (selectedItem.orderMonth) {
        const dateString = selectedItem.orderMonth;
        const date = moment(dateString);
  
        const month = date.format("MMM");
        const year = date.format("YYYY");
        setGetPoMonth(`${year}-${month}`);
      } else {
        setGetPoMonth("");
      }
     
      
      if(selectedItem?.paymentInfo?.list && selectedItem?.paymentInfo?.list?.length > 0){
        const listOfPayment = selectedItem?.paymentInfo?.list
        const calculateTotalAmount = listOfPayment.reduce((acc, current) => {
          if (current && current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setTotalSpentAmount(calculateTotalAmount)
  
        let invoices = []
        if(listOfPayment?.length > 0){
          invoices = listOfPayment?.flatMap((item, index) => {
            if (item?.invoice) {
              return item.invoice.map(invoice => ({...invoice, listIndex: index }));
            }
            return []; // or return an empty array to avoid undefined values
          });
        }
  
        setInvoiceData(invoices)
      }
    }, [selectedItem]);
  
    const [remainingAmount, setRemainingAmount] = useState('')

    useEffect(()=>{
      if(selectedItem?.paymentInfo?.list) {
        const array = selectedItem?.paymentInfo?.list;
        if(array.length > 0){
  
          const sum = array.reduce((acc, current) => {
            if (current?.amount && current?.amount !== '') {
              return acc + parseFloat(current?.amount);
            } else {
              return acc;
            }
          }, 0);
          setRemainingAmount(parseFloat(selectedItem.paymentInfo.totalPayAmount) - parseFloat(sum))
        }
        else{
          setRemainingAmount(parseFloat(selectedItem.paymentInfo.totalPayAmount))
        }
  
  
  
      }
    },[selectedItem?.paymentInfo?.list || selectedItem.paymentInfo.totalPayAmount])

    const splitTitle = (Key) => {
      let label = "";
      if (Key) {
        let splitKey = Key.split("/");
        label = Key && splitKey[splitKey.length - 1];
      }
      return label;
    };
  
    const handleDownload = (loc) => {
      const s3Link = loc;
  
      const link = document.createElement("a");
      link.href = s3Link;
      link.download = ".pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    return (
      <div>
        <Modal
          isOpen={openInfoModal}
          onClose={closeInfoModal}
          size="xl"
          className="modal-main"
        >
          <ModalOverlay />
          <ModalContent className="info-model">
            <div className="fixed-header">
              <ModalHeader className="expense-modal-header">
                <span>External – Client / Production Information</span>
              </ModalHeader>
              <ModalCloseButton className="expense-modal-header" />
            </div>
            <ModalBody>
              <Form className="addForm">
                <Tabs
                  id="justify-tab-example1"
                  activeKey={activeTab}
                  onSelect={(k) => setActiveTab(k)}
                  className=""
                  style={{ marginTop: "4rem" }}
                  justify
                >
                  <Tab
                    eventKey="information"
                    title="Information"
                    className="main-tab-item"
                  >
                    <div className="card">
                      <h5 className="heading-h5"> Purchase Order Details</h5>
  
                      <Row className="info-row">
                        <Col>
                          <DataRow
                            label="Order Number"
                            value={poNumberFormate(selectedItem.poNo)}
                          />
                        </Col>
                        <Col>
                          <DataRow label="Order Month" value={getPoMonth} />
                        </Col>
                      </Row>
                      <Row className="info-row">
                        <Col>
                          <DataRow
                            label="Order Date"
                            value={
                              selectedItem.orderDate
                                ? moment(selectedItem.orderDate).format(
                                    "DD-MMM-yyyy"
                                  )
                                : ""
                            }
                          />
                        </Col>
                        <Col></Col>
                      </Row>
  
                      <Divider
                        className="info-divider"
                        orientation="horizontal"
                        m={3}
                      />
                      <h5 className="heading-h5">Expense</h5>
  
                      <Row className="info-row">
                       
                        <Col>
                          <DataRow
                            label="Major Type"
                            value={selectedItem.expense.majorType}
                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="Minor Type"
                            value={selectedItem.minorType}
                          />
                        </Col>
                      </Row>
                      <Row className="info-row">
                      
                        <Col>
                          <DataRow
                            label="Minor Sub Category"
                            value={selectedItem.minorSubCategory}
                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="Client Name"
                            value={selectedItem.clientName}
                          />
                        </Col>
                      </Row>
                      <Row className="info-row">
                        
                      
                        <Col>
                          <DataRow
                            label="Project Name"
                            value={selectedItem.projectName}
                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="Merchant Name"
                            value={selectedItem.merchantName}
                          />
                        </Col>
                      </Row>
                      <Row className="info-row">
                        <Col>
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control
                            as="textarea"
                            disabled
                            rows={4}
                            value={selectedItem?.remarks}
                          />
                        </Col>
                      </Row>
                    
                      <Row className="info-row">
                      
  
                        <Col className="check-cross-mark">
                          <Form.Label>
                            <span
                              className={
                                selectedItem.expense.isExpense
                                  ? "checkmark"
                                  : "crossmark"
                              }
                            ></span>{" "}
                            Is it Freight Expense?
                          </Form.Label>
                        </Col>
                        <Col></Col>
                      </Row>
  
                      {selectedItem.expense.isExpense && (
                        <Row className="info-row mb-3">
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                label="Delivery Partner"
                                value={selectedItem.expense.agency}
                              />
                            </Flex>
                          </Col>
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                label="Tracking Number"
                                value={selectedItem.expense.trackingNum}
                              />
                            </Flex>
                          </Col>
                        </Row>
                      )}
  
                      <Divider
                        className="info-divider"
                        orientation="horizontal"
                      />
                      <h5 className="heading-h5">Reimbursement Information</h5>
                     
                      <Row className="info-row">
                        <Col className="check-cross-mark mt-0">
                          <Form.Label>
                            <span
                              className={
                                selectedItem.reimbursementInfo
                                .isReimbursement?.isReimburs
                                  ? "checkmark"
                                  : "crossmark"
                              }
                            ></span>{" "}
                            Is it Reimbursed?
                          </Form.Label>
                        </Col>
                      </Row>
  
                      {selectedItem.reimbursementInfo
                                .isReimbursement?.isReimburs && (
                        <>
                         <Row className="info-row mb-3">
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                label="Invoice Number "
                                value={selectedItem.reimbursementInfo?.invoiceNum}
                              />
                            </Flex>
                          </Col>
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                label="Invoice Date"
                                value={
                                  selectedItem.reimbursementInfo?.invoiceDate
                                    ? moment(selectedItem.reimbursementInfo?.invoiceDate).format(
                                        "DD-MMM-yyyy"
                                      )
                                    : ""
                                }
                              />
                            </Flex>
                          </Col>
                        </Row>
                          <Row className="info-row">
                            <Col>
                              <Flex direction="column">
                                <DataRow
                                  label="Payment Date"
                                  value={
                                    selectedItem.reimbursementInfo
                                .isReimbursement?.paymentDate
                                      ? moment(selectedItem.reimbursementInfo
                                        .isReimbursement?.paymentDate).format(
                                          "DD-MMM-yyyy"
                                        )
                                      : ""
                                  }
                                />
                              </Flex>
                            </Col>
                            <Col></Col>
                           
                          </Row>
                          <Row className="info-row">
                          <Col>
                              <Flex direction="column">
                                <DataRow
                                  label="Currency "
                                  value={selectedItem.reimbursementInfo
                                    .isReimbursement?.currency}
                                />
                              </Flex>
                            </Col>
                            <Col>
                              <Flex direction="column">
                                <DataRow
                                  type='Amount'
                                  label="Amount  "
                                  value={selectedItem.reimbursementInfo
                                    .isReimbursement?.amount}
                                />
                              </Flex>
                            </Col>
                          </Row>
                          <Row className="info-row">
                           
                            <Col>
                              <Flex direction="column">
                                <DataRow
                                  label="Coversion Rate  "
                                  value={selectedItem.reimbursementInfo
                                    .isReimbursement?.conversionRate}
                                />
                              </Flex>
                            </Col>
                            <Col>
                              <Flex direction="column">
                                <DataRow
                                  type='Amount'

                                  label="Amount in INR "
                                  value={selectedItem.reimbursementInfo
                                    .isReimbursement?.amountInINR}
                                />
                              </Flex>
                            </Col>
                           
                           
                          </Row>
                        </>
                      )}
                       <Divider
                      className="info-divider mt-3"
                      orientation="horizontal"
                    />
                    <h5 className="heading-h5">GST Applicable</h5>
                    <Row className="info-row">
                      <Col className="check-cross-mark mt-0">
                        <Form.Label>
                          <span
                            className={
                              selectedItem?.gstApplicable?.isGST
                                ? "checkmark"
                                : "crossmark"
                            }
                          ></span>{" "}
                          Is GST applicable?
                        </Form.Label>
                      </Col>
                    </Row>
  
                    {selectedItem?.gstApplicable?.isGST && (
                      <>
                      <Row className="info-row">
                      <Col>
                          <DataRow
                            label="Company Name"
                            value={selectedItem?.gstApplicable?.companyName}
                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="GST Number"
                            value={selectedItem?.gstApplicable?.gstNumber}
                          />
                        </Col>
                       
                      </Row>
                      <Row className="info-row">
                      <Col>
                          <DataRow
                            label="IGST"
                            value={selectedItem.gstApplicable.gstAmount?.igst}
                            type='Amount'

                          />
                        </Col>
                      <Col>
                          <DataRow
                            label="SGST"
                            value={selectedItem.gstApplicable.gstAmount?.sgst}
                            type='Amount'

                          />
                        </Col>
                        
                    </Row>
                    <Row className="info-row">
                    <Col>
                          <DataRow
                            label="CGST"
                            value={selectedItem.gstApplicable.gstAmount?.cgst}
                            type='Amount'

                          />
                        </Col>
                   
                      <Col>
                          <DataRow
                            label="Total GST"
                            value={Number(selectedItem.gstApplicable.gstAmount?.cgst) + Number(selectedItem.gstApplicable.gstAmount?.sgst) + Number(selectedItem.gstApplicable.gstAmount?.igst)}
                            type='Amount'

                          />
                        </Col>
                       
                    </Row>
                      </>
                    )}
                      <Divider
                        className="info-divider mt-3"
                        orientation="horizontal"
                      />
                      <h5 className="heading-h5">Payment Information</h5>
                      <Row className="info-row">
                        <Col>
                          <DataRow
                            label="Requested Amount"
                            value={selectedItem.paymentInfo.approvalAmount}
                            type='Amount'

                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="Approved Amount"
                            value={
                              selectedItem.paymentInfo.totalPayAmount
                                ? selectedItem.paymentInfo.totalPayAmount
                                : 0
                            }
                            type='Amount'

                          />
                        </Col>
                      </Row>
  
                      <Row className="info-row">
                        <Col>
                          <DataRow
                            label="Net Payable Amount"
                            type='Amount'

                            value={remainingAmount}
                          />
                        </Col>
                        {selectedItem.paymentInfo?.totalPayAmountIsRejected ? (
                          <Col className="check-cross-mark ">
                            <Form.Label>
                              <span
                                className={
                                  selectedItem.paymentInfo
                                    ?.totalPayAmountIsRejected
                                    ? "checkmark"
                                    : "crossmark"
                                }
                              ></span>{" "}
                            Is requested amount is rejected ?
                            </Form.Label>
                            <br />
                          </Col>
                        ) : (
                          <Col className="check-cross-mark ">
                            <Form.Label>
                              <span
                                className={
                                  selectedItem.paymentInfo
                                    ?.totalPayAmountIsApproved
                                    ? "checkmark"
                                    : "crossmark"
                                }
                              ></span>{" "}
                              Is requested amount is approved ?
                            </Form.Label>
                            <br />
                          </Col>
                        )}
                      </Row>
                      <Row className="info-row">
                        <Col>
                          <Form.Label>Approve OR Reject Remark</Form.Label>
                          <Form.Control
                            as="textarea"
                            disabled
                            rows={4}
                            value={selectedItem?.approveRejectRemarks}
                          />
                        </Col>
                      </Row>
  
                      {selectedItem.paymentInfo.list &&
                      selectedItem.paymentInfo.list.length ? (
                        <div className="info-table">
                          <Table size="md" className="table-info">
                            <Thead>
                              <Tr>
                                <Th>No.</Th>
                                <Th>Payee</Th>
                                <Th>Spent Amount</Th>
                                <Th>Bank</Th>
                                <Th>Reference Number</Th>
                                <Th>Date</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {selectedItem.paymentInfo.list &&
                                selectedItem.paymentInfo.list.length &&
                                selectedItem.paymentInfo.list.map(
                                  (item, index) => {
                                    return (
                                      <Tr>
                                        <Td>{index + 1}</Td>
                                        <Td>{item?.payee}</Td>
                                        <Td>
                                          <span className="rupee-symbol">₹ </span>

                                        {item?.amount 
                                        ? 
                                        formatAmountIndianRupees(Number(item?.amount))
                                        // Number(item?.amount)?.toFixed(2) 
                                        : 
                                        Number(0).toFixed(2)}
                                        </Td>
                                        <Td>{item?.bank}</Td>
                                        <Td>{item?.refNo}</Td>
                                        <Td>
                                          {dateTimeFormat(item?.paymentDate)}
                                        </Td>
                                      </Tr>
                                    );
                                  }
                                )}
                            </Tbody>
                            <Tfoot>
                              <Tr>
                                <Th colSpan={2}>Total Spent Amount</Th>
                                <Td colSpan={4} className="total-spent"><span className="rupee-symbol">₹</span>  {formatAmountIndianRupees(totalSpentAmount)}</Td>
                               
                              </Tr>
                            </Tfoot>
                          </Table>
                        </div>
                      ) : (
                        ""
                      )}
                     
  
                      <Divider
                        className="info-divider mt-3"
                        orientation="horizontal"
                      />
                      <h5 className="heading-h5">Invoice</h5>
                      <Row className="info-row">
                        <Col className="check-cross-mark mt-0">
                          <Form.Label>
                            <span
                              className={
                                invoiceData &&
                                invoiceData.length
                                  ? "checkmark"
                                  : "crossmark"
                              }
                            ></span>{" "}
                            Invoice Attached?
                          </Form.Label>
                        </Col>
                      </Row>
                      {invoiceData && invoiceData.length ? (
                        <div className="info-table">
                          <Table size="md" className="table-info">
                            <Thead>
                              <Tr>
                                <Th>No.</Th>
                                <Th>Title</Th>
                                <Th>Remark</Th>
                                <Th>Action</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {invoiceData &&
                                invoiceData.length &&
                                invoiceData.map((item, index) => {
                                  return (
                                    <Tr>
                                      <Td>{index + 1}</Td>
                                      <Td>{splitTitle(item?.Key)}</Td>
                                      <Td>{item?.remark}</Td>
                                      <Td
                                        className="download-data"
                                        style={{ textAlign: "center" }}
                                      >
                                        <MdOutlineFileDownload
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleDownload(item?.Location);
                                          }}
                                        />
                                      </Td>
                                    </Tr>
                                  );
                                })}
                            </Tbody>
                          </Table>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Tab>
                  {role === "Manager" || role === "Admin" ? (
                    <Tab eventKey="history" title="History">
                      <Form className="addForm addform2">
                        <Tabs
                          id="justify-tab-example3"
                          activeKey={historyTab}
                          onSelect={(k) => setHistoryTab(k)}
                          transition={true}
                          className="mt-0"
                        >
                          <Tab
                            eventKey="requested"
                            className="inner-tab"
                            title="Requested"
                          >
                            <div className="inner-card">
                              <h5 className="heading-h5">Requested Amount Details</h5>
                              <Row className="info-row">
                                <Col className="check-cross-mark mt-2">
                                  <Form.Label>
                                    <span
                                      className={
                                        selectedItem.paymentInfo
                                          ?.payAmountApproveIsRequest
                                          ? "checkmark"
                                          : "crossmark"
                                      }
                                    ></span>{" "}
                                    Is requested amount send for approval?
                                  </Form.Label>
                                  <br />
                                </Col>
                              </Row>
                              <Row className="info-row">
                                <Col>
                                  <DataRow
                                    label="Requested At"
                                    value={
                                      selectedItem.paymentInfo?.requestedAt
                                        ? moment(
                                            selectedItem.paymentInfo?.requestedAt
                                          ).format("DD-MMM-yyyy hh:mm A")
                                        : ""
                                    }
                                  />
                                </Col>
                                <Col>
                                  <DataRow
                                    label="Requested By"
                                    value={selectedItem.paymentInfo?.requestedBy}
                                  />
                                </Col>
                              </Row>
  
                              <Divider
                                className="info-divider mt-3"
                                orientation="horizontal"
                              />
                              <h5 className="heading-h5">Last Updated Details</h5>
  
                              <Row className="info-row mb-3">
                                <Col>
                                  <DataRow
                                    label="Last Updated At"
                                    value={
                                      selectedItem.lastUpdatedAt
                                        ? moment(
                                            selectedItem.lastUpdatedAt
                                          ).format("DD-MMM-yyyy hh:mm A")
                                        : ""
                                    }
                                  />
                                </Col>
                                <Col>
                                  <DataRow
                                    label="Last Updated By"
                                    value={selectedItem.updatedBy}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Tab>
  
                          {!selectedItem.paymentInfo?.totalPayAmountIsRejected
                      ?
                      <Tab eventKey="approved" title="Approved">
                      <div className="inner-card">
                        <h5 className="heading-h5">Requested Amount Details</h5>
                        <Row className="info-row">
                          <Col className="check-cross-mark mt-2">
                            <Form.Label>
                              <span
                                className={
                                  selectedItem.paymentInfo
                                    ?.totalPayAmountIsApproved
                                    ? "checkmark"
                                    : "crossmark"
                                }
                              ></span>{" "}
                              Is requested amount is approved ?
                            </Form.Label>
                            <br />
                          </Col>
                        </Row>
                        <Row className="info-row">
                          <Col>
                            <DataRow
                              label="Approved At"
                              value={
                                selectedItem.paymentInfo?.approvedAt
                                  ? moment(
                                      selectedItem.paymentInfo?.approvedAt
                                    ).format("DD-MMM-yyyy hh:mm A")
                                  : ""
                              }
                            />
                          </Col>
                          <Col>
                            <DataRow
                              label="Approved By"
                              value={selectedItem.paymentInfo?.approvedBy}
                            />
                          </Col>
                        </Row>

                        <Divider
                          className="info-divider mt-3"
                          orientation="horizontal"
                        />
                        <h5 className="heading-h5">Last Updated Details</h5>

                        <Row className="info-row mb-3">
                          <Col>
                            <DataRow
                              label="Last Updated At"
                              value={
                                selectedItem.lastUpdatedAt
                                  ? moment(
                                      selectedItem.lastUpdatedAt
                                    ).format("DD-MMM-yyyy hh:mm A")
                                  : ""
                              }
                            />
                          </Col>
                          <Col>
                            <DataRow
                              label="Last Updated By"
                              value={selectedItem.updatedBy}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Tab>
                    :
                    <Tab eventKey="approved" title="Rejected">
                    <div className="inner-card">
                      <h5 className="heading-h5">Requested Amount Details</h5>
                      <Row className="info-row">
                        <Col className="check-cross-mark mt-2">
                          <Form.Label>
                            <span
                              className={
                                selectedItem.paymentInfo
                                  ?.totalPayAmountIsRejected
                                  ? "checkmark"
                                  : "crossmark"
                              }
                            ></span>{" "}
                            Is requested amount is rejected ?
                          </Form.Label>
                          <br />
                        </Col>
                      </Row>
                      <Row className="info-row">
                        <Col>
                          <DataRow
                            label="Rejected At"
                            value={
                              selectedItem.paymentInfo?.rejectedAt
                                ? moment(
                                    selectedItem.paymentInfo?.rejectedAt
                                  ).format("DD-MMM-yyyy hh:mm A")
                                : ""
                            }
                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="Rejected By"
                            value={selectedItem.paymentInfo?.rejectedBy}
                          />
                        </Col>
                      </Row>

                      <Divider
                        className="info-divider mt-3"
                        orientation="horizontal"
                      />
                      <h5 className="heading-h5">Last Updated Details</h5>

                      <Row className="info-row mb-3">
                        <Col>
                          <DataRow
                            label="Last Updated At"
                            value={
                              selectedItem.lastUpdatedAt
                                ? moment(
                                    selectedItem.lastUpdatedAt
                                  ).format("DD-MMM-yyyy hh:mm A")
                                : ""
                            }
                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="Last Updated By"
                            value={selectedItem.updatedBy}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Tab>
                      }
                        </Tabs>
                      </Form>
                    </Tab>
                  ) : (
                    <Tab eventKey="" title="" disabled></Tab>
                  )}
                </Tabs>
              </Form>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    );
  };
  
  export default ExternalInfoModel;
  