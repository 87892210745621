import React from "react";
import moment from "moment";


export const dateCompare = (date1, date2)=>{
  const d1 = date1?.props?.children;
  const d2 = date2?.props?.children;
  if( d1 === d2 ){
    return true
  }
  return false

}
const dateTimeFormat = (dateTime) => {
  return <>{moment(dateTime).format("DD-MMM-yyyy")}</>;
};

export default dateTimeFormat;
