// Chakra Imports
import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
// Assets
import { MdNotificationsNone, MdPerson } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import routes from "routes.js";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { clearToken } from "redux/slices/Auth";

import "react-datepicker/dist/react-datepicker.css";
import { fetchAsyncSearchExternalData } from "redux/externalSlice";
import { fetchAsyncSearchInternalData } from "redux/internalSlice";
import { fetchAsyncSearchResourceData } from "redux/resourceSlice";
import { fetchAsyncSearchTdsData } from "redux/tdsSlice";
import { fetchAsyncSearchDTData } from "redux/directTaxSlice";
import { getStartDate } from "jsFunctions/helperFunctions";
import { getEndDate } from "jsFunctions/helperFunctions";

import { setDateRangeGlobal } from "redux/slices/dateRangeSlice";
import { fetchAsyncSearchGstData } from "redux/slices/gstSlice";
const apiURL = process.env.REACT_APP_BASE_URL;

export default function HeaderLinks(props) {
  const { currUser } = useSelector((state) => state.auth);
  const { secondary } = props;
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [currentLocation, setCurrentLocation] = useState("/dashboard");
  // Chakra Color Mode

  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  // const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
  const location = useLocation();
  const search = useCallback(
    (value, startDate, endDate) => {
      if (startDate && endDate) {
        dispatch(
          setDateRangeGlobal({
            startDate: startDate,
            endDate: endDate,
          })
        );
      } else {
        dispatch(
          setDateRangeGlobal({
            startDate: "",
            endDate: "",
          })
        );
      }
      if (location.pathname === "/external") {
        dispatch(fetchAsyncSearchExternalData({ value, startDate, endDate }));
      }
      if (location.pathname === "/internal") {
        dispatch(fetchAsyncSearchInternalData({ value, startDate, endDate }));
      }
      if (location.pathname === "/resource-expanse") {
        dispatch(fetchAsyncSearchResourceData({ value, startDate, endDate }));
      }
      if (location.pathname === "/tds") {
        dispatch(fetchAsyncSearchTdsData({ value, startDate, endDate }));
      }
      if (location.pathname === "/direct-tax") {
        dispatch(fetchAsyncSearchDTData({ value, startDate, endDate }));
      }
      if (location.pathname === "/gst") {
        dispatch(fetchAsyncSearchGstData({ value, startDate, endDate }));
      }
    },
    [location, dispatch]
  );

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      let start = getStartDate(dateRange[0]);
      let end = getEndDate(dateRange[1]);
      search(searchText, start, end);
    } else {
      search(searchText);
    }
  }, [searchText, search, dateRange]);

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);
  const logOutHandle = async () => {
    try {
      localStorage.removeItem("userData");
      dispatch(clearToken());

      window.location.href = `${apiURL}/signout`;
    } catch (error) {
      console.log(error);
    }
  };

  const [role, setRole] = useState('')


  
  
 
  useEffect(()=>{
    if(props?.brandText){
      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === props?.brandText);
      if(roleObj){
        setRole(roleObj?.access)
      }
    }
  },[currUser?.auth,props?.brandText])
  return (
    <>
      <Flex
        w={{ sm: "100%", md: "auto" }}
        alignItems="center"
        flexDirection="row"
        bg={menuBg}
        flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
        p="10px"
        borderRadius="30px"
        boxShadow={shadow}
      >
        {(currentLocation !== "/dashboard" && currentLocation !== "/all-category") && (
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            isClearable={true}
            className="rangeDatepicker custom-datepicker" // Apply custom class"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
      
            placeholderText="Select Date Range"
          />
        )}

        {(currentLocation !== "/dashboard" && currentLocation !== "/all-category") && (
          <SearchBar
            mb={secondary ? { base: "10px", md: "unset" } : "unset"}
            me="10px"
            ms="10px"
            borderRadius="30px"
            onChange={(value) => {
              setSearchText(value);
            }}
          />
        )}
        <Flex
          bg={ethBg}
          display={secondary ? "flex" : "none"}
          borderRadius="30px"
          ms="auto"
          p="6px"
          align="center"
          me="6px"
        >
          <Flex
            align="center"
            justify="center"
            bg={ethBox}
            h="29px"
            w="29px"
            borderRadius="30px"
            me="7px"
          >
            <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
          </Flex>
          <Text
            w="max-content"
            color={ethColor}
            fontSize="sm"
            fontWeight="700"
            me="6px"
          >
            1,924
            <Text as="span" display={{ base: "none", md: "unset" }}>
              {" "}
              ETH
            </Text>
          </Text>
        </Flex>
        <SidebarResponsive routes={routes} />
        <Menu>
          <MenuButton p="0px">
            <Icon
              mt="6px"
              as={MdNotificationsNone}
              color={navbarIcon}
              w="18px"
              h="18px"
              me="10px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: "30px", md: "unset" }}
            minW={{ base: "unset", md: "400px", xl: "450px" }}
            maxW={{ base: "360px", md: "unset" }}
          >
            <Flex jusitfy="space-between" w="100%" mb="20px">
              <Text fontSize="md" fontWeight="600" color={textColor}>
                Notifications
              </Text>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textColorBrand}
                ms="auto"
                cursor="pointer"
              >
                Mark all read
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                px="0"
                borderRadius="8px"
                mb="10px"
              >
                <ItemContent info="Test Notification" aName="Alicia" />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>

        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              name={currUser?.name}
              bg="#11047A"
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px" flexDirection="column">
              <Text
                ps="20px"
                pt="16px"
                w="100%"
                // borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; Hey, {currUser?.name}
                
              </Text>
              {role &&
              <Text
                ps="20px"
                textTransform="uppercase"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="600"
                color={textColor}
              >
                <Flex w="100%" mb="0px">
                <MdPerson  size={20} color="red"/>  <span style={{color: "#007bff"}}>&nbsp;{role}</span>

                </Flex>
                
              </Text>
              }
            </Flex>
            <Flex flexDirection="column" p="10px">
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Profile Settings</Text>
              </MenuItem>
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                color="red.400"
                borderRadius="8px"
                px="14px"
                onClick={() => {
                  logOutHandle();
                }}
              >
                <Text fontSize="sm">Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    </>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
