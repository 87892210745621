import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdPerson } from "react-icons/md";
import { BsDoorOpen, BsFillHouseHeartFill } from "react-icons/bs";
import { TbReceiptTax } from "react-icons/tb";
import { HiReceiptTax } from "react-icons/hi";
import { AiOutlineDashboard } from "react-icons/ai";
import { VscSignOut } from "react-icons/vsc";
import { GrResources } from "react-icons/gr";
import { BiCategoryAlt } from "react-icons/bi";
import { TfiReceipt } from "react-icons/tfi";

// Admin Imports
import MainDashboard from "views/admin/default";
import EmbrillInternal from "views/admin/EmbrillInternal";
import EmbrillExternal from "views/admin/EmbrillExternal";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import EmbrillResourceExpense from "views/admin/EmbrillResourceExpense";
import EmbrillTds from "views/admin/EmbrillTds";
import EmbrillDirectTax from "views/admin/EmbrillDirectTax";
import Users from "views/admin/Users";
import AllCategory from "views/admin/AllCategory";
import EmbrillGst from "views/admin/EmbrillGst";


const routes = [
  {
    name: "Main Dashboard",
    layout: "/",
    path: "/dashboard",
    icon: (
      <Icon
        as={AiOutlineDashboard}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    access: ["Admin", "Manager", "Accountant"],
    place: "top",
    component: <MainDashboard />,
  },
  {
    name: "All Category",
    layout: "/",
    path: "/all-category",
    icon: <Icon as={BiCategoryAlt} width="20px" height="20px" color="inherit" />,
    access: ["Admin", "Manager", "Accountant"],
    place: "top",
    component: <AllCategory />,
  },

  {
    name: "External-Client/Production",
    layout: "/",
    path: "/external",
    icon: <Icon as={BsDoorOpen} width="20px" height="20px" color="inherit" />,
    component: <EmbrillExternal />,
    access: ["Admin", "Manager", "Accountant"],
    place: "top",
    secondary: true,
  },
  {
    name: "Internal-Local expense",
    layout: "/",
    path: "/internal",
    icon: (
      <Icon
        as={BsFillHouseHeartFill}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    access: ["Admin", "Manager", "Accountant"],
    place: "top",
    component: <EmbrillInternal />,
  },
  {
    name: "Resource/Service Expense",
    layout: "/",
    icon: <Icon as={GrResources} width="20px" height="20px" color="inherit" />,
    path: "/resource-expanse",
    access: ["Admin", "Manager", "Accountant"],
    place: "top",
    component: <EmbrillResourceExpense />,
  },
  {
    name: "Tax Deducted at Source (TDS)",
    layout: "/",
    path: "/tds",
    icon: <Icon as={TbReceiptTax} width="20px" height="20px" color="inherit" />,
    access: ["Admin", "Manager", "Accountant"],
    place: "top",
    component: <EmbrillTds />,
  },
  {
    name: "Direct Tax",
    layout: "/",
    icon: <Icon as={HiReceiptTax} width="20px" height="20px" color="inherit" />,
    path: "/direct-tax",
    access: ["Admin", "Manager", "Accountant"],
    place: "top",
    component: <EmbrillDirectTax />,
  },
  {
    name: "Gst",
    layout: "/",
    icon: <Icon as={TfiReceipt} width="20px" height="20px" color="inherit" />,
    path: "/gst",
    access: ["Admin", "Manager", "Accountant"],
    place: "top",
    component: <EmbrillGst />,
  },
  
  {
    name: "Users",
    layout: "/",
    path: "/users",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    access: ["Admin"],
    place: "bottom",
    component: <Users />,
  },
  {
    name: "Log Out",
    layout: "/",
    path: "/sign-in",
    icon: <Icon as={VscSignOut} width="20px" height="20px" color="inherit" />,
    access: ["Admin", "Manager", "Accountant"],
    place: "bottom",
    component: <SignInCentered />,
  },
];

export default routes;
