/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

// import "antd/dist/antd.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "rsuite/dist/rsuite.min.css";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import DatePicker from "react-datepicker";
import CurrencyFormat from 'react-currency-format';

import "react-datepicker/dist/react-datepicker.css";
import { createListForSingleOption, createOption } from "./createableSelect";

import getINRTotal from "jsFunctions/getINRAmount";
import PaymentInfoComponent from "./paymentInfoComponent";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncAddExternalExpense } from "redux/externalSlice";
import { fetchAsyncGetExternalExpense } from "redux/externalSlice";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { fetchAsyncGetPONumExternalExpense } from "redux/externalSlice";
import { fetchAsyncAddValueToFormOptions } from "redux/formOptions";
import Swal from "sweetalert2";
import CustomSelect from "components/CustomSelect";
import { fetchAsyncDeleteFormOptions } from "redux/formOptions";
import { fetchAsyncUpdateExternalExpense } from "redux/externalSlice";
import { fetchAsyncDeleteExternalPdf } from "redux/externalSlice";
import { fetchAsyncUploadExternalPdf } from "redux/externalSlice";
import { FormValidation } from "validation";
import DataChangeModalOnEdit from "components/models/DataChangeModalOnEdit";
import { fetchAsyncAddInvoiceRemark } from "redux/externalSlice";
import { fetchAsyncEditFormOptions } from "redux/formOptions";
import { fetchAsyncGetFormOptions } from "redux/formOptions";
import { fetchAsyncGetMinorSubCategory } from "redux/formOptions";
import { fetchAsyncGetSubCategory } from "redux/formOptions";
import { fetchAsyncEditMinorSubCategory } from "redux/formOptions";
import { fetchAsyncEditSubCategory } from "redux/formOptions";
import { fetchAsyncDeleteSubCategory } from "redux/formOptions";
import { fetchAsyncCreateSubCategory } from "redux/formOptions";
import { fetchAsyncDeleteMinorSubCategory } from "redux/formOptions";
import { fetchAsyncCreateMinorSubCategory } from "redux/formOptions";
import { checkTotalApprovedAmountWithSpentAmount } from "jsFunctions/helperFunctions";
import { checkApprovalAmount } from "jsFunctions/helperFunctions";
import { fetchAsyncRejectExternalTotalPayAmount } from "redux/externalSlice";
import { fetchAsyncApproveExternalTotalPayAmount } from "redux/externalSlice";
import { fetchAsyncSendRequestApprovalExternalTotalPayAmount } from "redux/externalSlice";
import { fetchAsyncSendRequestUpdateApprovalExternalTotalPayAmount } from "redux/externalSlice";
import { checkSpentAmountisEmpty } from "jsFunctions/helperFunctions";
import { fetchAsyncDeleteExternalPaymentListItem } from "redux/externalSlice";
import { InputGroup } from "react-bootstrap";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
// import { Option } from "antd/es/mentions";
import { fetchAsyncGetGstCompanyExpense } from "redux/resourceSlice";
import { fetchAsyncAddGstWithCompanyExpense } from "redux/resourceSlice";
// modal style

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const xl = defineStyle({
  px: "6",
  py: "2",
  fontSize: "xl",
});

const sm = defineStyle({
  fontSize: "sm",
  py: "6",
});

const sizes = {
  xl: definePartsStyle({ header: sm, dialog: xl }),
};

export const modalTheme = defineMultiStyleConfig({
  sizes,
});
const AddNew = ({
  openExternalModal,
  CloseExternalModal,
  isEditForm = false,
  editItem,
  refreshEditData,
  activeList,
  role

}) => {
  //   const { isOpen, onOpen, onClose } = useDisclosure();
  const formOptionsList1 = useSelector((state) => state.formOptions);
  const subCategoryList1 = useSelector((state) => state.subCategory);


  const [poPrefix, setPoPrefix] = useState({
    prefix: "PO-E-",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    number: 1,
  });

  const [isEdit, setIsEdit] = useState(isEditForm)
  const [editData, setEditData] = useState(editItem)
  const [formOptionsList, setFormOptionsList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])

  const [getPoMonth, setGetPoMonth] = useState(moment().format("yyyy-MM"));
  const [generatedNum, setGeneratedNum] = useState("PO-E-");
  const [clientOPtion, setClientOption] = useState([]);
  const [merchantOption, setMerchantOPtion] = useState([]);
  const [minorTypeOption, setMinorTypeOption] = useState([]);
  const [projectOption, setProjectOption] = useState([]);
  const [expenseOption, setExpenseOption] = useState([]);
  const [agencyOption, setAgencyOPtion] = useState([]);
  const [minorSubCategoryOption, setMinorSubCategoryOption] = useState([]);
  const [gstAmount , setGstAmount] = useState({
    cgst:'',
    sgst:'',
    igst:''
  })

  const [editGst, setEditGst] = useState(true);

  const [approveOrRejectRemarks, setApproveOrRejectRemarks] = useState('')

  const [currencyOption, setCurrencyOption] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [INRVal, setINRVal] = useState();
  const [convertedVal, setConvertedVal] = useState("");
  const [isSettled, setIsSettled] = useState(false);
  const [isFreight, setIsFreight] = useState(false);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [approvalAmount, setApprovalAmount] = useState('');
  const [sendPayAmountRequest, setSendPayAmountRequest] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState()
  const [clientValue, setClientValue] = useState("");
  const [minorTypeValue, setMinorTypeValue] = useState("");
  const [officeTypeValue, setOfficeTypeValue] = useState("");
  const [companyTypeOption, setCompanyTypeOption] = useState([]);
  const [companyTypeValue, setCompanyTypeValue] = useState("");

  const [projectValue, setProjectValue] = useState("");
  const [merchantValue, setMerchantValue] = useState("");
  const [expenseValue, setExpenseValue] = useState("");
  const [agencyValue, setAgencyValue] = useState("");
  const [minorSubCategoryValue, setMinorSubCategoryValue] = useState("");
  const [totalPayAmountIsApproved, setTotalPayAmountIsApproved] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [settledDate, setSettledDate] = useState(new Date());
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [reimbursePaymentDate, setReimbursePaymentDate] = useState(new Date());
  const [currencyValue, setCurrencyValue] = useState("");
  const [multipleInvoiceData, setMultipleInvoiceData] = useState([]);
  const dispatch = useDispatch();
  const [multipleAddPaymentInfo, setMultipleAddPaymentInfo] = useState([]);
  // data  change modal
  const [compareDataModal, setCompareDataModal] = useState(false);
  const [comparingData, setComparingData] = useState(null);
  const [isGst, setIsGst] = useState(false);

  // Set edited value for show on submit
  // const [editedValues, setEditedValues] = useState({});

  const finalRef = useRef(null);
  const { addToast } = useToasts();
  const [remarksValue, setRemarksValue] = useState('');
  const [errors, setErrors] = useState({})
  const currUser = useSelector((state) => state.auth.currUser);


  const initialValues = {
    poNo: "",
    orderMonth: null,
    orderDate: null,
    clientName: "",
    projectName: "",
    merchantName: "",
    remarks: "",
    expenseType: "",
    isExpense: false,
    agency: "",
    trackingNum: editData?.expense?.trackingNum || "",
    totalPayAmount: 0,
    payee: "",
    paymentAmount: 0,
    approvalAmount: 0,
    bank: "",
    referenceNo: "",
    paymentdate: null,
    isSettle: false,
    setteledDate: null,
    invoiceNum: editData?.reimbursementInfo?.invoiceNum || "",
    gstNumber:'',
    invoiceDate: null,
    isReimburse:
      editData?.reimbursementInfo?.isReimbursement?.isReimburs || false,
    reimbursePaymentDate: null,
    currency: "",
    reimburseAmount: 0,
    conversionRate: 0,
    amountInINR: 0,
    invoice: "",
  };

  useEffect(() => {
    setGeneratedNum(poNumberFormate(poPrefix));
  }, [poPrefix]);

  useEffect(() => {
    if (!isEdit && getPoMonth) {
      const dataSplit = getPoMonth.split("-");
      dispatch(
        fetchAsyncGetPONumExternalExpense({
          month: dataSplit[1],
          year: dataSplit[0],
        })
      )
        .then((res) => {
          let newNumber = 1;
          if (res.payload?.[0]?.poNo) {
            newNumber = res.payload?.[0]?.poNo.number + 1;
          }
          setPoPrefix((prev) => {
            return {
              prefix: prev.prefix,
              month: dataSplit[1],
              year: dataSplit[0],
              number: newNumber,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getPoMonth]);


  useEffect(()=>{
    if(formOptionsList1 && formOptionsList1?.length > 0){
      const internalFormOptionList = formOptionsList1.filter((item)=> item.expenseType === 'external')
      setFormOptionsList(internalFormOptionList)
    }
  },[formOptionsList1])

  useEffect(()=>{
    if(subCategoryList1 && subCategoryList1?.length > 0){
      const internalSubCategoryList = subCategoryList1.filter((item)=> item.expenseType === 'external')
      setSubCategoryList(internalSubCategoryList)
    }
  },[subCategoryList1])

  useEffect(() => {
    if(currencyValue && currencyValue?.value !== 'INR'){
      setConvertedVal(getINRTotal(totalAmount, INRVal));
    }
    else{
      setConvertedVal(totalAmount);
      setINRVal(1)
    }  
  }, [INRVal, totalAmount,currencyValue]);
  const closeComparedDataModal = () => {
    setCompareDataModal(false);

    setComparingData(null);
  };
  const submitUpdateData = (formData, editData) => {
    dispatch(
      fetchAsyncUpdateExternalExpense({ id: editData._id, data: formData })
    )
      .then((res) => {
        if (res.payload) {
          addToast(`Expense PoNo: ${generatedNum} updated successfully!`, { appearance: "success" });
          dispatch(fetchAsyncGetExternalExpense());
        }
        CloseExternalModal();
        formik.resetForm();
      })
      .catch((error) => {
        console.log(error);
        addToast("Error", { appearance: "error" });
      });
  };

  useEffect(() => {
    let merchantList = createListForSingleOption(formOptionsList, "merchant");
    let clientList = createListForSingleOption(formOptionsList, "client");
    let projectList = createListForSingleOption(formOptionsList, "project");
    let agencyList = createListForSingleOption(formOptionsList, "agency");
    let companyTypeList = createListForSingleOption(
      formOptionsList1,
      "company-type"
    );
    let expenseList = createListForSingleOption(formOptionsList, "expense");
    let currencyList = createListForSingleOption(formOptionsList, "currency");
    setMerchantOPtion(merchantList);
    setClientOption(clientList);
    setProjectOption(projectList);
    setCompanyTypeOption(companyTypeList)

    setAgencyOPtion(agencyList);
    setExpenseOption(expenseList);
    setCurrencyOption(currencyList);
  }, [formOptionsList]);

  const formik = useFormik({
    initialValues,
    validationSchema: FormValidation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (!clientValue || !minorTypeValue  || !projectValue || !merchantValue || !expenseValue || (minorSubCategoryOption && minorSubCategoryOption?.length && !minorSubCategoryValue)) {
        setErrors({
          ...errors,
          projectValue:'Project name is required!',
          minorTypeValue:'Minor type is required!',
          merchantValue:'Merchant name is required!',
          expenseValue:'Major type is required!',
          clientValue:'Client name is required!',
          minorSubCategoryValue:minorSubCategoryOption && minorSubCategoryOption?.length ? 'Minor sub category is required!': ''
        })     
        addToast("Please fill all mandatory fields", { appearance: "error" });
        return;
      }
     
      const checkSpent = checkSpentAmountisEmpty(
        multipleAddPaymentInfo,
      );
      if (!checkSpent.status) {
        addToast(checkSpent.msg, { appearance: "error" });
        return;
      }
      const checkApproval = checkApprovalAmount(
        approvalAmount,
      );
      if (!checkApproval.status) {
        setErrors({
          ...errors,
          approvalAmount:checkApproval.msg
        })         
        return;
      }
      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        totalPaymentAmount,
        multipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })
        // addToast(checkValid.msg, { appearance: "error" });
        return;
      }
      let formData = new FormData();
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", selectedDate);
      formData.append("clientName", clientValue?.value);
      formData.append("minorType", minorTypeValue.value);
      formData.append("officeType", officeTypeValue.value);
      formData.append("gstNumber", values.gstNumber);
      formData.append("gstAmount", JSON.stringify(gstAmount));
      formData.append("companyType", companyTypeValue.value);

      formData.append("minorTypeCategory", minorSubCategoryValue.value ? minorSubCategoryValue.value : '');
      formData.append("projectName", projectValue?.value);
      formData.append("merchantName", merchantValue?.value);
      formData.append("remarks", remarksValue);
      formData.append("expenseType", expenseValue?.value);
      formData.append("isExpense", isFreight);
      formData.append("agency", agencyValue?.value);
      formData.append("trackingNum", values.trackingNum);
      formData.append("totalPaymentAmount", totalPaymentAmount);
      formData.append("approvalAmount", approvalAmount);

      const filteredMultipleAddPaymentInfoPay = multipleAddPaymentInfo.map((item)=>{
        if(item?.invoice){
          return {
            ...item,
            invoice: item?.invoice.filter(entry => 'Key' in entry)
          }
        }
        else{
          return item
        }
      });
      formData.append("isGST", isGst);

      formData.append("payInfo", JSON.stringify(filteredMultipleAddPaymentInfoPay));
      formData.append("isPaymentSettle", isSettled);
      formData.append("paymentSettleDate", isSettled ? settledDate : "");
      formData.append("invoiceNum", values.invoiceNum);
      formData.append("invoiceDate", invoiceDate);
      formData.append("isReimburs", values.isReimburse);
      formData.append("reimbursementPaymentDate", reimbursePaymentDate);
      currencyValue?.value && formData.append("currency", currencyValue?.value);
      totalAmount && formData.append("reimburseAmount", totalAmount);
      INRVal && formData.append("conversionRate", INRVal);
      convertedVal && formData.append("amountInINR", convertedVal);
      if (isEdit) {
        setComparingData(formData);
      } else {
        multipleInvoiceData.forEach((element, i) => {
          formData.append(`file${i}`, element);
        });
        dispatch(fetchAsyncAddExternalExpense(formData))
          .then((res) => {
            if (res.payload) {
              addToast("Successfully Added", { appearance: "success" });
              dispatch(fetchAsyncGetExternalExpense());
            }
            CloseExternalModal();
            resetForm();
          })
          .catch((error) => {
            console.log(error);
            addToast("Something went wrong", { appearance: "error" });
          });
      }
    },
  });

  const handleEditGST = () => {
    setEditGst(false);
  };

   // add resource with pan
   const handleAddCompanyGst = () => {
    if (!companyTypeValue?.value) {
      addToast("Please select a company", { appearance: "error" });
      setEditGst(true);
      return;
    }
    dispatch(
      fetchAsyncAddGstWithCompanyExpense({
        companyName: companyTypeValue?.value,
        companyGst: formik.values.gstNumber,
      })
    ).then((res) => {
      if (res.payload) {
        // setResourcePan(res.payload.resourcePan.resourcePan);
        setEditGst(true);
      }
    });
  };
  useEffect(() => {
    if (companyTypeValue) {
      dispatch(fetchAsyncGetGstCompanyExpense(companyTypeValue.value)).then(
        (res) => {
          if (res?.payload?.length > 0) {
            formik.setFieldValue("gstNumber", res?.payload?.[0]?.companyGst);
          } else {
            formik.setFieldValue("gstNumber", "");
          }
        }
      );
    }
  }, [companyTypeValue]);

  const handleInvoiceChange = (data) => {
    // setOpenInvoice(true);
    // if (multipleInvoiceData.length < 10) {
    //   setMultipleInvoiceData([
    //     ...multipleInvoiceData,
    //     { data: "", invNumber: data.data },
    //   ]);
    // }

    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === data?.id) {

        let invoice = []

        if(item?.invoice){
           if (item?.invoice?.length < 5) {
          invoice = [
            ...item?.invoice,
            { data: "", invNumber: data.data }
          ]
        }
        else{
          invoice = item?.invoice
        }
        }
        else{
          invoice = [
            { data: "", invNumber: data.data }
          ]
        }

        return {...item,invoice};
      } else return {item};
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  const handleMultiplePayInfo = (data) => {
    setMultipleAddPaymentInfo([
      ...multipleAddPaymentInfo,
      { data: "", paydata: data.data,invoice:[] },
    ]);
  };
  const handleUpdateMultiplePayData = (payData, index) => {
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === index) {
        return {...item,...payData};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  const handleUpdateInvoiceData = (invoiceData, index) => {
    const updateList = multipleInvoiceData.map((item, i) => {
      if (i === index) {
        return invoiceData;
      } else return item;
    });
    setMultipleInvoiceData([...updateList]);
  };
  const handelDeleteInvoice = (index,id) => {
    // const updateList = multipleInvoiceData.filter((item, i) => i !== index);
    // setMultipleInvoiceData([...updateList]);
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === id) {

        let invoice = []
        if(item?.invoice){

          invoice = item?.invoice.filter((item, i) => i !== index)
        }
        
        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };

  const createNewFormOption = (data, type) => {
    dispatch(
      fetchAsyncAddValueToFormOptions({
        label: data.label,
        value: data.value,
        type,
        expenseType:'external'
      })
    )
      .then((res) => {
        if (res.payload.value) {
          const setData = {
            label: res.payload.label,
            value: res.payload.value,
          };
          if (type === "client") {
            setClientValue(setData);
          }

          if (type === "project") {
            setProjectValue(setData);
          }

          if (type === "merchant") {
            setMerchantValue(setData);
          }

          if (type === "company-type") {
            setCompanyTypeValue(setData);
          }
          if (type === "expense") {
            setExpenseValue(setData);
          }

          if (type === "agency") {
            setAgencyValue(setData);
          }

          if (type === "currency") {
            setCurrencyValue(setData);
          }
          Swal.fire({
            title: "Successfully Added",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            customClass: {
              container: "sweet_containerImportant",
              confirmButton: "sweet_confirmbuttonImportant",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              // console.log(res.payload);
            }
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Something went wrong",

          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          customClass: {
            container: "sweet_containerImportant",
            confirmButton: "sweet_confirmbuttonImportant",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            // console.log(result);
          }
        });
      });
  };

  const deleteFromOption = (option, type) => {
    let data = {};
    if(type === 'company-type'){
      data = formOptionsList1.find(
        (item) => item.value === option.value && item.type === type
      );
    }
    else{

      data = formOptionsList.find(
        (item) => item.value === option.value && item.type === type
      );
    
    }
    if (data?.value) {
      dispatch(fetchAsyncGetSubCategory(data.value)).then(
        (response) => {
          if (response.payload) {
            const minorOption = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'external');
          
            if (minorOption && minorOption?.length > 0) {
              addToast(`Please delete all related Minor Type first`, { appearance: "error" });
            }
            else{
              dispatch(fetchAsyncDeleteFormOptions(data._id)).then((res)=>{
                console.log(res,'response')
              });
            }
          }
        }
      );
    }
   
  };


  const editFromOption = (option,newValue, type) => {
    let data = {};
    if(type === 'company-type'){
      data = formOptionsList1.find(
        (item) => item.value === option.value && item.type === type
      );
    }
    else{

      data = formOptionsList.find(
        (item) => item.value === option.value && item.type === type
      );
    
    }
    
    dispatch(fetchAsyncEditFormOptions({
      id:data._id,
      newData:{
        optionValue:newValue,
        expenseType:'external'

      }
    })).then((res)=>{
      if (res.payload.value) {
        const setData = {
          label: res.payload.label,
          value: res.payload.value,
        };
        if (type === "client") {
          setClientValue(setData);
        }

        
        // if (type === "office-type") {
        //   setOfficeTypeValue(setData);
        // }

        if (type === "merchant") {
          setMerchantValue(setData);
        }

        if (type === "expense") {
          setExpenseValue(setData);
        }
        if (type === "company-type") {
          setCompanyTypeValue(setData);
        }
        if (type === "agency") {
          setAgencyValue(setData);
        }
        
        if (type === "project") {
          setProjectValue(setData);
        }

       

        if (type === "currency") {
          setCurrencyValue(setData);
        }
        dispatch(fetchAsyncGetFormOptions())
      }
    }).catch((err)=>{
      console.log(err)
    })
  };
  // Changes on Edit

  const editFormPrefiledData = (formOptionsList, editData) => {
    const clientData = getOptionDataOnEdit(
      formOptionsList,
      editData,
      "clientName",
      "client"
    );
    if (clientData) {
      setClientValue({ label: clientData.label, value: clientData.value });
    }
    const projectData = getOptionDataOnEdit(
      formOptionsList,
      editData,
      "projectName",
      "project"
    );
    const companyData = getOptionDataOnEdit(
      formOptionsList1,
      editData.gstApplicable,
      "companyName",
      "company-type"
    );
    if (companyData && !companyTypeValue) {
      setCompanyTypeValue({
        label: companyData.label,
        value: companyData.value,
      });
    }
    if (projectData) {
      setProjectValue({
        label: projectData.label,
        value: projectData.value,
      });
    }
    const officeData = getOptionDataOnEdit(
      formOptionsList,
      editData,
      "officeType",
      "office-type"
    );
    if (officeData && !officeTypeValue) {
      setOfficeTypeValue({
        label: officeData.label,
        value: officeData.value,
      });
    }
    const merchantData = getOptionDataOnEdit(
      formOptionsList,
      editData,
      "merchantName",
      "merchant"
    );
    if (merchantData) {
      setMerchantValue({
        label: merchantData.label,
        value: merchantData.value,
      });
    }
    const expense = getOptionDataOnEdit(
      formOptionsList,
      editData?.expense,
      "majorType",
      "expense"
    );
    if (expense) {
      setExpenseValue({
        label: expense.label,
        value: expense.value,
      });
    }
    if (editData?.expense?.isExpense) {
      const expenseData = getOptionDataOnEdit(
        formOptionsList,
        editData?.expense,
        "agency",
        "agency"
      );
      if (expenseData) {
        setAgencyValue({
          label: expenseData.label,
          value: expenseData.value,
        });
      }
    }
  };

  const getOptionDataOnEdit = (formOptionsList, editData, value, type) => {
    const data = formOptionsList.find(
      (item) => item.value === editData[value] && item.type === type
    );
    return data;
  };

  useEffect(() => {
    // fill data in form on edit
    if (isEdit && editData) {
      setPoPrefix(editData.poNo);
      setSelectedDate(new Date(editData.orderDate));

      setGetPoMonth(
        `${editData.poNo.year}-${
          editData.poNo.month < 9
            ? "0" + editData.poNo.month
            : editData.poNo.month
        }`
      );
      if (formOptionsList?.length > 0 || formOptionsList1?.length > 0) {
        editFormPrefiledData(formOptionsList, editData);
      }
      setIsFreight(editData?.expense?.isExpense || false);
      if (editData.paymentInfo?.totalPayAmount) {
        setTotalPaymentAmount(editData.paymentInfo?.totalPayAmount);
      }
      if (editData.paymentInfo?.list && editData.paymentInfo?.list.length > 0) {
        setMultipleAddPaymentInfo(editData.paymentInfo?.list);
      }
      if (editData.paymentInfo?.payAmountApproveIsRequest) {
        setSendPayAmountRequest(true)
      }
      if (editData?.approveRejectRemarks) {
        setApproveOrRejectRemarks(editData?.approveRejectRemarks)
      }
      if (editData?.remarks) {
        setRemarksValue(editData?.remarks)
      }
       if(editData?.paymentInfo?.totalPayAmountIsApproved){
        setTotalPayAmountIsApproved(true)
      }
      if (editData?.gstApplicable && editData?.gstApplicable?.isGST) {
        setIsGst(editData?.gstApplicable?.isGST);
      }
      
      if (editData?.gstApplicable && editData?.gstApplicable?.gstAmount) {
        setGstAmount(editData?.gstApplicable?.gstAmount);
      }
      if (editData.paymentInfo?.approvalAmount) {
        setApprovalAmount(editData.paymentInfo?.approvalAmount);
      }
      if (editData.paymentInfo?.isSettle) {
        setIsSettled(editData.paymentInfo.isSettle);
        editData.paymentInfo.setteledDate &&
          setSettledDate(new Date(editData.paymentInfo.setteledDate));
      }
      if (editData.reimbursementInfo?.invoiceDate) {
        setInvoiceDate(new Date(editData.reimbursementInfo.invoiceDate));
      }
     
      if (editData.reimbursementInfo?.isReimbursement) {
        const { paymentDate, amount, conversionRate, amountInINR } =
          editData.reimbursementInfo?.isReimbursement;
        paymentDate && setReimbursePaymentDate(new Date(paymentDate));
        setTotalAmount(amount);
        setINRVal(conversionRate);
        setConvertedVal(amountInINR);
        const currencyData = getOptionDataOnEdit(
          formOptionsList,
          editData.reimbursementInfo?.isReimbursement,
          "currency",
          "currency"
        );
        if (currencyData) {
          setCurrencyValue({
            label: currencyData.label,
            value: currencyData.value,
          });
        }
      }
      if (editData.invoice?.length > 0) {
        setMultipleInvoiceData([...editData.invoice]);
      } else {
        setMultipleInvoiceData([]);
      }
    }
  }, [isEdit, editData, formOptionsList]);

  const handleDeleteInvoiceOnEdit = (key,listIndex) => {
    Swal.fire({
      title: "Are you sure? Delete File Permanently.",
      text: `Delete File ${key}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          key: key,
          listIndex:listIndex
        };
        dispatch(fetchAsyncDeleteExternalPdf(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.externalExpense?.paymentInfo?.list)

          refreshEditData();
        });
      }
    });
  };

  const addNewInvoiceOnEdit = (file, index,listIndex) => {
    Swal.fire({
      title: "Are you sure? Upload File Directly.",
      text: `Upload - ${file.name}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (editData) {
          const lastInvoiceKey =
            editData.invoice?.length > 0 &&
            editData.invoice[editData.invoice.length - 1].Key;
          let number = index + 1;
          if (lastInvoiceKey) {
            number = lastInvoiceKey.split("PAY_")[1]?.split(".pdf")[0];
            number = parseInt(number) + 1;
          }
          let formData = new FormData();
          formData.append("poNo", JSON.stringify(editData.poNo));
          formData.append("number", number);
          formData.append("file", file);
          formData.append("merchantName", editData?.merchantName);
          formData.append("listIndex", listIndex);
          formData.append("index", index);
          const listItem = multipleAddPaymentInfo[listIndex];
          formData.append("listItem", JSON.stringify(listItem));

          dispatch(
            fetchAsyncUploadExternalPdf({ id: editData._id, formData })
          ).then((res) => {
            setMultipleAddPaymentInfo(res?.payload?.externalExpense?.paymentInfo?.list)
            refreshEditData();
          });
        }
      }
    });
  };


   // Set SubCategory for selected minorype value
  useEffect(() => {
    if (minorTypeValue?.value) {
      dispatch(fetchAsyncGetMinorSubCategory(minorTypeValue.value)).then(
        (response) => {
          if (response.payload) {
            const minorSubOption = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'external')
            setMinorSubCategoryOption(minorSubOption ? minorSubOption : []);
            setMinorSubCategoryValue("");
            if (isEdit && editData && minorSubOption?.length > 0) {
              const subCat = minorSubOption.filter(
                (option) => option.value === editData.minorSubCategory
              );
              if (subCat[0]) {
                setMinorSubCategoryValue({
                  label: subCat[0].label,
                  value: subCat[0].value,
                });
              }
            }
          }
        }
      );
    }
  }, [minorTypeValue]);


    const createMinorSubCategory = (category, value) => {
      const option = subCategoryList.find((data) => data.value === value);
      if (!option) return;
  
      dispatch(fetchAsyncCreateMinorSubCategory({ id: option._id, category, expenseType:'external'
      })).then(
        (res) => {
          if (res.payload && res.payload.value) {
            setMinorSubCategoryOption((prev) => [
              ...prev,
              {_id:res.payload._id, label: res.payload.label, value: res.payload.value,expenseType:res.payload?.expenseType },
            ]);
            setMinorSubCategoryValue({
              label: res.payload.label,
              value: res.payload.value,
            });
          }
        }
      );
    };
  
    const deleteMinorSubCategory = (option) => {
      const id = option?._id;

      dispatch(fetchAsyncDeleteMinorSubCategory(id)).then((res) => {
        if (res.payload && res.payload.value) {
          setMinorSubCategoryOption((prev) => [
            ...prev.filter((cat) => cat.value !== res.payload.value),
          ]);
        }
      });
    };
    const createSubCategory = (category, value) => {
      const option = formOptionsList.find((data) => data.value === value);
      if (!option) return;
  
      dispatch(fetchAsyncCreateSubCategory({ id: option._id, category, expenseType:'external'
      })).then(
        (res) => {
          if (res.payload && res.payload.value) {
            setMinorTypeOption((prev) => [
              ...prev,
              {_id:res.payload._id, label: res.payload.label, value: res.payload.value,expenseType:res.payload?.expenseType  },
            ]);
            setMinorTypeValue({
              label: res.payload.label,
              value: res.payload.value,
            });
          }
        }
      );
    };
  
    const deleteSubCategory = (option) => {
      const id = option?._id;
      const value = option?.value;
      if (value) {
        dispatch(fetchAsyncGetMinorSubCategory(value)).then(
          (response) => {
            if (response.payload) {
              const minorSubOption = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'external')
  
              if (minorSubOption  && minorSubOption?.length > 0) {
                addToast(`Please delete all related Minor Sub Category first`, { appearance: "error" });
              }
              else{
                dispatch(fetchAsyncDeleteSubCategory(id)).then((res) => {
                  if (res.payload && res.payload.value) {
                    setMinorTypeOption((prev) => [
                      ...prev.filter((cat) => cat.value !== res.payload.value),
                    ]);
                  }
                });
              }
            }
          }
        );
      }
     
    };
  
    const editSubCategory = (option,newValue) => {
      dispatch(fetchAsyncEditSubCategory({
        id:option?._id,
        newData:{
          optionValue:newValue,
          expenseType:'external'

        }
      })).then((res)=>{
        if (res.payload && res.payload.value) {
          if (expenseValue?.value) {
            dispatch(fetchAsyncGetSubCategory(expenseValue.value)).then(
              (response) => {
                if (response.payload) {
                  const minorOption = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'external');
  
                  setMinorTypeOption(minorOption);
                  if (minorOption.length > 0) {
                    const subCat = minorOption.filter(
                      (option) => option.value === newValue
                    );
                    if (subCat[0]) {
                      setMinorTypeValue({
                        label: subCat[0].label,
                        value: subCat[0].value,
                      });
                    }
                  }
                }
              }
            );
          }
        }
      }).catch((err)=>{
        console.log(err)
      })
    };
  
    const editMinorSubCategory = (option,newValue) => {
      dispatch(fetchAsyncEditMinorSubCategory({
        id:option?._id,
        newData:{
          optionValue:newValue,
          expenseType:'external'

        }
      })).then((res)=>{
        if (res.payload && res.payload.value) {
          if (minorTypeValue?.value) {
            dispatch(fetchAsyncGetMinorSubCategory(minorTypeValue.value)).then(
              (response) => {
                if (response.payload) {
                  const minorSubOption = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'external')
                  setMinorSubCategoryOption(minorSubOption ? minorSubOption : []);
                  if (minorSubOption?.length > 0) {
                    const subCat = minorSubOption.filter(
                      (option) => option.value === newValue
                    );
                    if (subCat[0]) {
                      setMinorSubCategoryValue({
                        label: subCat[0].label,
                        value: subCat[0].value,
                      });
                    }
                  }
                }
              }
            );
          }
        }
      }).catch((err)=>{
        console.log(err)
      })
    };
     // Set MinorCategory for selected majorType value
   useEffect(() => {
    if (expenseValue?.value) {
      dispatch(fetchAsyncGetSubCategory(expenseValue.value)).then(
        (response) => {
          if (response.payload) {
            const minorOptions = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'external')
            setMinorTypeOption(minorOptions ? minorOptions : []);
            setMinorTypeValue("");
            if (isEdit && editData && minorOptions.length > 0) {
              const subCat = minorOptions.filter(
                (option) => option.value === editData.minorType
              );
              if (subCat[0]) {
                setMinorTypeValue({
                  label: subCat[0].label,
                  value: subCat[0].value,
                });
              }
            }
          }
        }
      );
    }
  }, [expenseValue]);
  // Changes on Edit End

  // set edited value with older value
  // const getEditedValueFeild = (value) => {
  //   setEditedValues(value);
  // };

  // add Remark for Invoice
  const addRemarkForInvoice = (value, item,listIndex,index) => {
    dispatch(
      fetchAsyncAddInvoiceRemark({
        id: editData._id,
        remarkData: { key: item.Key, remark: value, listIndex, index },
      })
    ).then((res) => {
      setMultipleAddPaymentInfo(res?.payload?.externalExpense?.paymentInfo?.list)

      refreshEditData();
    });
  };

  const handleAmountApproveBtn = (values)=>{   
    if(!isEdit){
  
      if (!clientValue  || !minorTypeValue || !projectValue || !merchantValue || !expenseValue  || (minorSubCategoryOption && minorSubCategoryOption?.length && !minorSubCategoryValue)) {
        setErrors({
          ...errors,
          projectValue:'Project name is required!',
          minorTypeValue:'Minor type is required!',
          merchantValue:'Merchant name is required!',
          expenseValue:'Major type is required!',
          clientValue:'Client name is required!',
          minorSubCategoryValue:minorSubCategoryOption && minorSubCategoryOption?.length ? 'Minor sub category is required!': ''
        }) 
          addToast("Please fill all mandatory fields", { appearance: "error" });
           return;
         }
    }
     
    const checkApproval = checkApprovalAmount(
      approvalAmount,
    );
    if (!checkApproval.status) {
      setErrors({
        ...errors,
        approvalAmount:checkApproval.msg
      }) 
    return;
    }
   
  
    if(approvalAmount <= totalPaymentAmount && remainingAmount >= 0){
  
      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        totalPaymentAmount,
        multipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })           
        return;
      }
    }
    if(remainingAmount < 0 && approvalAmount <= totalPaymentAmount){
      return;
    }
      let formData = new FormData();
      
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", selectedDate);
      formData.append("clientName", clientValue.value);
      formData.append("minorType", minorTypeValue.value);
      formData.append("officeType", officeTypeValue.value);
      formData.append("minorTypeCategory", minorSubCategoryValue.value);
      formData.append("projectName", projectValue?.value);
      formData.append("gstNumber", values.gstNumber);
      formData.append("gstAmount", JSON.stringify(gstAmount));
      formData.append("companyType", companyTypeValue.value);

      formData.append("merchantName", merchantValue.value);
      formData.append("remarks", remarksValue);
      formData.append("expenseType", expenseValue.value);
      formData.append("isExpense", isFreight);
      formData.append("agency", agencyValue.value);
      formData.append("trackingNum", values.trackingNum);
      formData.append("totalPaymentAmount", totalPaymentAmount);
      formData.append("approvalAmount", approvalAmount);
      formData.append("isGST", isGst);

      formData.append("isPaymentSettle", isSettled);
      formData.append("paymentSettleDate", isSettled ? settledDate : "");
      formData.append("invoiceNum", values.invoiceNum);
      formData.append("invoiceDate", invoiceDate);
      formData.append("isReimburs", values.isReimburse);
      formData.append("reimbursementPaymentDate", reimbursePaymentDate);
      currencyValue?.value && formData.append("currency", currencyValue?.value);
      totalAmount && formData.append("reimburseAmount", totalAmount);
      INRVal && formData.append("conversionRate", INRVal);
      convertedVal && formData.append("amountInINR", convertedVal);
    
      if(isEdit && editData){
        dispatch(fetchAsyncSendRequestUpdateApprovalExternalTotalPayAmount
          ({ 
          id: editData._id,
          data:{newApprovalAmount:approvalAmount}
         })).then(
          (res) => {
            if (res.payload && res.payload.updatedData) {
              setIsEdit(true)
              setEditData(res.payload.updatedData)
              setSendPayAmountRequest(true)
              addToast(`Resend updated request for approval for expense PoNo : ${generatedNum} !`, { appearance: "success" });
              CloseExternalModal();
              dispatch(fetchAsyncGetExternalExpense());
            }
            else{
              addToast("Error", { appearance: "error" });
  
            }
  
          
          }
        );
      }
      else{
  
        dispatch(fetchAsyncSendRequestApprovalExternalTotalPayAmount(formData)).then((res) => {
            if (res.payload) {
              addToast(`Send request for approval for expense PoNo : ${generatedNum} !`, { appearance: "success" });
              dispatch(fetchAsyncGetExternalExpense());
              setIsEdit(true)
              setEditData(res.payload)
              setSendPayAmountRequest(true)
              CloseExternalModal();
              dispatch(fetchAsyncGetExternalExpense());
  
            
            }
            else{
              addToast("Error", { appearance: "error" });  
            }
          });
      }
      
      
      }
  const handleTotalPayAmountIsApproved = ()=>{
    const _id = editData._id;
    if(!approveOrRejectRemarks){
      setErrors({
        ...errors,
        approveOrRejectRemarks:'Approve Or Reject Remarks Is Required!'
      })
    }
    else{
    if(!editData.paymentInfo?.totalPayAmountIsApproved){
      Swal.fire({
        title: `Are You Sure? You Want To Approve Amount <span style="color: orange"><span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)}</span> For PoNo: <span style="color:orange">${generatedNum}</span>.`,
        text: `Please confirm to proceed with the approval.`,        
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Approve",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncApproveExternalTotalPayAmount({ 
            id: _id,
            data:{approvalAmount:approvalAmount,totalPayAmountIsApproved:true,approveRejectRemarks:approveOrRejectRemarks}
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsApproved) {
                addToast(`Requested amount for PoNo: ${generatedNum} has been successfully approved.`, { appearance: "success" });
                dispatch(fetchAsyncGetExternalExpense());
                CloseExternalModal();
              }
              
            }
          );
        }
      });
     
    }
    }
  }

  const handleTotalPayAmountIsRejected = ()=>{
    const _id = editData._id;
    if(!approveOrRejectRemarks){
      setErrors({
        ...errors,
        approveOrRejectRemarks:'Approve Or Reject Remarks Is Required!'
      })
    }
    else{
    if(!editData.paymentInfo?.totalPayAmountIsApproved &&  !editData.paymentInfo?.totalPayAmountIsRejected){
      Swal.fire({
        title: `Are You Sure? You Want To Reject Amount <span style='color:orange'> <span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)} </span> For PoNo: <span style="color:orange">${generatedNum}.</span>`,
        text: `Please confirm to reject. This action cannot be undone.`,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reject",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncRejectExternalTotalPayAmount({ 
            id: _id,
            data:{totalPayAmountIsRejected:true,approveRejectRemarks:approveOrRejectRemarks}
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsRejected) {
                addToast(`Requested amount for PoNo: ${generatedNum} has been successfully rejected.`, { appearance: "success" });
                dispatch(fetchAsyncGetExternalExpense());
                CloseExternalModal();
              }
              
            }
          );
        }
      });
     
    }
  }
  }
  useEffect(()=>{
    if(multipleAddPaymentInfo) {
      const array = multipleAddPaymentInfo;
      if(array.length > 0){

        const sum = array.reduce((acc, current) => {
          if (current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setRemainingAmount(parseFloat(totalPaymentAmount) - parseFloat(sum))
      }



    }
  },[multipleAddPaymentInfo])

  useEffect(()=>{
    if(totalPaymentAmount){
      if(multipleAddPaymentInfo) {
        const array = multipleAddPaymentInfo;
        if(array.length > 0){
  
          const sum = array.reduce((acc, current) => {
            if (current?.amount && current?.amount !== '') {
              return acc + parseFloat(current?.amount);
            } else {
              return acc;
            }
          }, 0);
          const total_pay_amount = parseFloat(totalPaymentAmount)
          setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
        }
        else{
          const total_pay_amount = parseFloat(totalPaymentAmount)
          setRemainingAmount(parseFloat(total_pay_amount))
        }
      }

    }
  },[totalPaymentAmount])

 
  const closeModelForm = () => {
    Swal.fire({
      title: "Are you sure you want to close without saving?",
      text: `You will lost all current changes`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        CloseExternalModal();
      }
    });
  };

  const handleRemarksChange = (e)=>{
    const value = e?.target?.value;
    const encoder = new TextEncoder(); // Default is 'utf-8'
    const byteLength = encoder.encode(value).length;
    if(byteLength <=128){

      setRemarksValue(value)
      setErrors({
        ...errors,
        remarks:''
      })
    }
    else{
      setErrors({
        ...errors,
        remarks:'Remarks must be within the 128 byte limit!'
      })
    }
  }

 

  const deleteMultipleListItem = (listIndex)=>{
    Swal.fire({
      title: "Are you sure? Delete Amount Details Permanently.",
      text: ``,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          listIndex:listIndex
        };
        dispatch(fetchAsyncDeleteExternalPaymentListItem(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.externalExpense?.paymentInfo?.list)
          refreshEditData();
        });
      }
    });
}
  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={openExternalModal}
        onClose={() => closeModelForm()}
        size="xl"
        className="modal-main"
      >
        <ModalOverlay />
        <ModalContent>
          <div className="fixed-header">
            <ModalHeader className="expense-modal-header">
              {isEdit && "Edit "}Extenal – Client / Production{" "}
            </ModalHeader>
            <ModalCloseButton />
          </div>

          <ModalBody>
            <Form className="addForm" onSubmit={formik.handleSubmit}>
              <div className="card1">
                <h5>Purchase Order Details</h5>
                <Row>
                  <Col>
                    <Form.Label>Order Number</Form.Label>
                    <span className="span-star">*</span>
                    <Form.Control
                      type="text"
                      name="poNo"
                      placeholder="No"
                      value={generatedNum && generatedNum}
                      disabled
                    />
                  </Col>

                  <Col>
                    <Form.Label>Order - Month</Form.Label>
                    <span className="span-star">*</span>
                    <DatePicker
                      className="monthDate"
                      dateFormat="MM-yyyy"
                      showMonthYearPicker
                      name="orderMonth"
                      onChange={(e) => {
                        const dateTime = moment(e).format("yyyy-MM");
                        setGetPoMonth(dateTime);
                      }}
                      disabled={isEdit}
                      // defaultValue={new Date()}
                      selected={getPoMonth ? new Date(moment(getPoMonth)) : ""}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Label>Order Date</Form.Label>
                    <span className="span-star">*</span>
                    <DatePicker
                      name="orderDate"
                      className="monthDate"
                      dateFormat="dd-MMM-yyyy"
                      // disabled={isEdit}
                      onChange={(date) => {
                        setSelectedDate(date);
                      }}
                      selected={selectedDate}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </Col>
                  <Col></Col>
                </Row>
              </div>
              <div className="card1">
                <h5>Expense</h5>
                <Row>
                  <Col>
                    <Form.Label>Major Type</Form.Label>
                    <span className="span-star">*</span>
                    <CustomSelect
                      options={expenseOption}
                      placeHolder={<div>Type or select...</div>}
                      onChange={(newValue) => setExpenseValue(newValue)}
                      isSearchable
                      onCreateOption={(e) => {
                        createNewFormOption(createOption(e), "expense");
                      }}
                      onDeleteOption={(option) => {
                        deleteFromOption(option, "expense");
                      }}
                      onEditOption={(option, newValue) => {
                        editFromOption(option, newValue, "expense");
                      }}
                      defaultValue={expenseValue}
                      role={role}
                      isInvalid={errors?.expenseValue && !expenseValue}
                    />
                    {errors?.expenseValue && !expenseValue && (
                      <p className="form_error">{errors?.expenseValue}</p>
                    )}
                  </Col>
                  {expenseValue ? (
                    <Col className="custom-select">
                      <Form.Label>Minor Type</Form.Label>
                      <span className="span-star">*</span>
                      <CustomSelect
                        options={minorTypeOption}
                        placeHolder={<div>Type or select...</div>}
                        onChange={(newValue) => setMinorTypeValue(newValue)}
                        isSearchable
                        // onCreateOption={(e) => {
                        //   createNewFormOption(
                        //     createOption(e),
                        //     "minor-type",
                        //     true
                        //   );
                        // }}
                        onCreateOption={(e) => {
                          if (expenseValue?.value) {
                            createSubCategory(
                              createOption(e),
                              expenseValue.value
                            );
                          } else {
                            addToast("Please Select Major Type", {
                              appearance: "error",
                            });
                          }
                        }}
                        // onDeleteOption={(option) => {
                        //   deleteFromOption(option, "minor-type");
                        // }}
                        onDeleteOption={(option) => {
                          deleteSubCategory(option);
                        }}
                        onEditOption={(option, newValue) => {
                          editSubCategory(option, newValue);
                        }}
                        defaultValue={minorTypeValue}
                        role={role}
                        isInvalid={errors?.minorTypeValue && !minorTypeValue}
                      />
                      {errors?.minorTypeValue && !minorTypeValue && (
                        <p className="form_error">{errors?.minorTypeValue}</p>
                      )}
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>
                {minorTypeValue && (
                  <Row>
                    <Col>
                      <Form.Label>Client Name</Form.Label>
                      <span className="span-star">*</span>
                      <CustomSelect
                        options={clientOPtion}
                        placeHolder={<div>Type or select...</div>}
                        onChange={(newValue) => setClientValue(newValue)}
                        isSearchable
                        onCreateOption={(e) => {
                          createNewFormOption(createOption(e), "client");
                        }}
                        onDeleteOption={(option) => {
                          deleteFromOption(option, "client");
                        }}
                        onEditOption={(option, newValue) => {
                          editFromOption(option, newValue, "client");
                        }}
                        defaultValue={clientValue}
                        role={role}
                        isInvalid={errors?.clientValue && !clientValue}
                      />
                      {errors?.clientValue && !clientValue && (
                        <p className="form_error">{errors?.clientValue}</p>
                      )}
                    </Col>

                    <Col className="custom-select">
                      <Form.Label>Minor Sub Category</Form.Label>
                      <span className="span-star">
                        {minorSubCategoryOption &&
                        minorSubCategoryOption?.length
                          ? "*"
                          : ""}
                      </span>
                      <CustomSelect
                        options={minorSubCategoryOption}
                        placeHolder={<div>Type or select...</div>}
                        onChange={(newValue) =>
                          setMinorSubCategoryValue(newValue)
                        }
                        isSearchable
                        onCreateOption={(e) => {
                          if (minorTypeValue?.value) {
                            createMinorSubCategory(
                              createOption(e),
                              minorTypeValue.value
                            );
                          } else {
                            addToast("Please Select Minor Type", {
                              appearance: "error",
                            });
                          }
                        }}
                        onDeleteOption={(option) => {
                          deleteMinorSubCategory(option);
                        }}
                        onEditOption={(option, newValue) => {
                          editMinorSubCategory(option, newValue);
                        }}
                        defaultValue={minorSubCategoryValue}
                        role={role}
                        isInvalid={
                          errors?.minorSubCategoryValue &&
                          !minorSubCategoryValue
                        }
                      />
                      {errors?.minorSubCategoryValue &&
                        !minorSubCategoryValue && (
                          <p className="form_error">
                            {errors?.minorSubCategoryValue}
                          </p>
                        )}
                    </Col>
                  </Row>
                )}
                {minorTypeValue && (
                  <>
                    <Row>
                      <Col className="custom-select">
                        <Form.Label>Project Name</Form.Label>
                        <span className="span-star">*</span>
                        <CustomSelect
                          options={projectOption}
                          placeHolder={<div>Type or select...</div>}
                          onChange={(newValue) => setProjectValue(newValue)}
                          isSearchable
                          onCreateOption={(e) => {
                            createNewFormOption(createOption(e), "project");
                          }}
                          onDeleteOption={(option) => {
                            deleteFromOption(option, "project");
                          }}
                          onEditOption={(option, newValue) => {
                            editFromOption(option, newValue, "project");
                          }}
                          defaultValue={projectValue}
                          role={role}
                          isInvalid={errors?.projectValue && !projectValue}
                        />
                        {errors?.projectValue && !projectValue && (
                          <p className="form_error">{errors?.projectValue}</p>
                        )}
                      </Col>
                      <Col>
                        <Form.Label>Merchant Name </Form.Label>
                        <span className="span-star">*</span>
                        <CustomSelect
                          options={merchantOption}
                          placeHolder={<div>Type or select...</div>}
                          onChange={(newValue) => setMerchantValue(newValue)}
                          isSearchable
                          onCreateOption={(e) => {
                            createNewFormOption(createOption(e), "merchant");
                          }}
                          onDeleteOption={(option) => {
                            deleteFromOption(option, "merchant");
                          }}
                          onEditOption={(option, newValue) => {
                            editFromOption(option, newValue, "merchant");
                          }}
                          defaultValue={merchantValue}
                          role={role}
                          isInvalid={errors?.merchantValue && !merchantValue}
                        />
                        {errors?.merchantValue && !merchantValue && (
                          <p className="form_error">{errors?.merchantValue}</p>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col>
                    <Form.Label>Remarks </Form.Label>

                    <Form.Control
                      type="text"
                      name="remarks"
                      placeholder="Type Here..."
                      value={remarksValue}
                      // defaultValue={remarksValue}
                      onChange={(e) => handleRemarksChange(e)}
                      isInvalid={!!errors?.remarks}
                    />
                  </Col>
                  <p className="form_error">{errors?.remarks}</p>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Is it Freight Expense?</Form.Label>
                    <label className="switch">
                      <input
                        name="isExpense"
                        type="checkbox"
                        checked={isFreight}
                        onChange={(e) => setIsFreight(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </Col>
                </Row>
                {isFreight && (
                  <>
                    <Row>
                      <Col className="switch-col">
                        <Form.Label>Delivery Partner</Form.Label>
                        <CustomSelect
                          options={agencyOption}
                          placeHolder={<div>Type or select...</div>}
                          onChange={(newValue) => setAgencyValue(newValue)}
                          isSearchable
                          onCreateOption={(e) => {
                            createNewFormOption(createOption(e), "agency");
                          }}
                          onDeleteOption={(option) => {
                            deleteFromOption(option, "agency");
                          }}
                          onEditOption={(option, newValue) => {
                            editFromOption(option, newValue, "agency");
                          }}
                          defaultValue={agencyValue}
                          role={role}
                        />
                      </Col>
                      <Col className="switch-col">
                        <Form.Label>Tracking Number</Form.Label>

                        <Form.Control
                          type="text"
                          name="trackingNum"
                          placeholder="Tracking Number"
                          {...formik.getFieldProps("trackingNum")}
                          defaultValue="XYZabc456qzvytr"
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
              <div className="card1">
                <h5>Payment Information</h5>
                <Row>
                  <Col>
                    <Form.Label>Requested Amount</Form.Label>
                    <span className="span-star">*</span>
                    <InputGroup>
                      <InputGroup.Text>
                        <span className="rupee-symbol">₹</span>{" "}
                      </InputGroup.Text>
                      <CurrencyFormat
                        customInput={Form.Control}
                        type="text"
                        disabled={
                          sendPayAmountRequest &&
                          !totalPayAmountIsApproved &&
                          (role === "Admin" || role === "Manager")
                          &&
                          currUser.name !== editData?.paymentInfo?.requestedBy
                        }
                        name="approvalAmount"
                        placeholder="Requested Amount"
                        value={approvalAmount || ""}
                        onValueChange={(e) => {
                          setApprovalAmount(e.value);
                        }}
                        isInvalid={
                          (remainingAmount < 0 &&
                            approvalAmount <= totalPaymentAmount) ||
                          (!approvalAmount && errors?.approvalAmount)
                        }
                        thousandSeparator={true}
                        // prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </InputGroup>
                    {((remainingAmount < 0 &&
                      approvalAmount <= totalPaymentAmount) ||
                      !approvalAmount) && (
                      <p className="form_error">{errors?.approvalAmount}</p>
                    )}
                  </Col>
                  {totalPayAmountIsApproved ? (
                    <Col>
                      <Form.Label>Approved Amount</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <span className="rupee-symbol">₹</span>{" "}
                        </InputGroup.Text>
                        <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          disabled
                          name="totalPayAmount"
                          placeholder="Total Amount"
                          value={totalPaymentAmount ? totalPaymentAmount : 0}
                          thousandSeparator={true}
                          // prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </InputGroup>
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>
                {totalPayAmountIsApproved && (
                  <Row>
                    <Col>
                      <Form.Label>Net Payable Amount</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <span className="rupee-symbol">₹</span>{" "}
                        </InputGroup.Text>
                        <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          disabled
                          name="Net Payable Amount"
                          // placeholder={
                          //   totalPaymentAmount ? totalPaymentAmount : 0
                          // }
                          // defaultValue={remainingAmount}
                          value={remainingAmount ? remainingAmount : 0}
                          thousandSeparator={true}
                          // prefix={"$"}

                          decimalScale={2}
                          isInvalid={
                            remainingAmount < 0 &&
                            approvalAmount <= totalPaymentAmount
                          }
                          fixedDecimalScale={true}
                        />
                      </InputGroup>
                      {remainingAmount < 0 &&
                        approvalAmount <= totalPaymentAmount && (
                          <p className="form_error">
                            Net payable amount cannot be negative. Please revise
                            the request with an increased requested amount and
                            resubmit for approval.
                          </p>
                        )}
                    </Col>
                    <Col>
                      <Form.Label></Form.Label>
                      <div className="horizental-heading">
                        <Button
                          variant="brand"
                          disabled={totalPaymentAmount === null ? true : false}
                          onClick={() => {
                            handleMultiplePayInfo(
                              multipleAddPaymentInfo?.length + 1
                            );
                          }}
                          className="add-button mt-1 px-4"
                          size="sm"
                        >
                          Add
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
                {totalPayAmountIsApproved &&
                  multipleAddPaymentInfo &&
                  multipleAddPaymentInfo.map((item, index) => {
                    return (
                      <PaymentInfoComponent
                        key={`payment-info-${index}`}
                        getData={(data) => {
                          handleUpdateMultiplePayData(data, index);
                        }}
                        autoCount={index}
                        paymentData={item}
                        editData={editData}
                        refreshEditData={refreshEditData}
                        isEdit={isEdit}
                        handleInvoiceChange={(item) =>
                          handleInvoiceChange(item)
                        }
                        multipleInvoiceData={item?.invoice ? item?.invoice : []}
                        handleUpdateInvoiceData={(item, index) =>
                          handleUpdateInvoiceData(item, index)
                        }
                        handelDeleteInvoice={(index, id) =>
                          handelDeleteInvoice(index, id)
                        }
                        handleDeleteInvoiceOnEdit={(key, listIndex) =>
                          handleDeleteInvoiceOnEdit(key, listIndex)
                        }
                        addNewInvoiceOnEdit={(file, index, listIndex) =>
                          addNewInvoiceOnEdit(file, index, listIndex)
                        }
                        addRemarkForInvoice={(value, item, listIndex, index) =>
                          addRemarkForInvoice(value, item, listIndex, index)
                        }
                        deleteMultipleListItem={() =>
                          deleteMultipleListItem(index)
                        }
                        role={role}
                        expenseType="external"
                      />
                    );
                  })}
              </div>
              {totalPayAmountIsApproved && (
                <>
                  <div className="card1">
                    <h5>Reimbursement Information</h5>

                    {(role === "Manager" || role === "Admin") && (
                      <Row>
                        <Col>
                          <Form.Label>Is it Reimbursed?</Form.Label>
                          <label className="switch">
                            <input
                              name="isReimburse"
                              type="checkbox"
                              checked={formik.values.isReimburse}
                              {...formik.getFieldProps("isReimburse")}
                            />
                            <span className="slider round"></span>
                          </label>
                        </Col>
                      </Row>
                    )}
                    {formik.values.isReimburse && (
                      <>
                        <Row>
                          <Col>
                            <Form.Label>Invoice Number</Form.Label>{" "}
                            <span
                              className="ms-2"
                              style={{ fontSize: "12px", color: "red" }}
                            >
                              {formik.errors.invoiceNum}
                            </span>
                            <Form.Control
                              type="text"
                              name="invoiceNum"
                              placeholder="Invoice Number"
                              defaultValue=""
                              {...formik.getFieldProps("invoiceNum")}
                            />
                          </Col>
                          <Col>
                            <Form.Label>Invoice Date</Form.Label>

                            <DatePicker
                              name="invoiceDate"
                              className="monthDate"
                              dateFormat="dd-MMM-yyyy"
                              selected={invoiceDate}
                              onChange={(date) => setInvoiceDate(date)}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="switch-col mt-2">
                            <Form.Label>Payment Date </Form.Label>

                            <DatePicker
                              name="reimbursePaymentDate"
                              className="monthDate"
                              dateFormat="dd-MMM-yyyy"
                              selected={reimbursePaymentDate}
                              onChange={(date) => setReimbursePaymentDate(date)}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </Col>
                          <Col></Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Label>Currency</Form.Label>
                            <CustomSelect
                              options={currencyOption}
                              placeHolder={<div>Type or select...</div>}
                              onChange={(newValue) =>
                                setCurrencyValue(newValue)
                              }
                              isSearchable
                              onCreateOption={(e) => {
                                createNewFormOption(
                                  createOption(e),
                                  "currency"
                                );
                              }}
                              onDeleteOption={(option) => {
                                deleteFromOption(option, "currency");
                              }}
                              onEditOption={(option, newValue) => {
                                editFromOption(option, newValue, "currency");
                              }}
                              defaultValue={currencyValue}
                              role={role}
                            />
                          </Col>
                          <Col>
                            <Form.Label>Amount</Form.Label>
                            <CurrencyFormat
                              customInput={Form.Control}
                              type="text"
                              onValueChange={(e) => {
                                setTotalAmount(e.value);
                              }}
                              name="reimburseAmount"
                              defaultValue={totalAmount}
                              value={totalAmount || ""}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                            {/* <Form.Control
                          type="number"
                          name="reimburseAmount"
                          placeholder="0"
                          onChange={(e) => setTotalAmount(e.target.value)}
                          defaultValue={totalAmount}
                        /> */}
                          </Col>
                        </Row>
                        {currencyValue?.label !== "INR" && (
                          <>
                            <Row>
                              <Col>
                                <Form.Label>Conversion Rate</Form.Label>

                                <Form.Control
                                  type="number"
                                  name="conversionRate"
                                  placeholder="Coversion Rate"
                                  disabled={
                                    currencyValue &&
                                    currencyValue?.value === "INR"
                                  }
                                  onChange={(e) => {
                                    setINRVal(e.target.value);
                                  }}
                                  defaultValue={INRVal}
                                />
                              </Col>
                              <Col>
                                <Form.Label>Amount in INR</Form.Label>
                                <InputGroup>
                                  <InputGroup.Text>
                                    <span className="rupee-symbol">₹</span>{" "}
                                  </InputGroup.Text>
                                  <CurrencyFormat
                                    customInput={Form.Control}
                                    type="text"
                                    onValueChange={formik.handleChange}
                                    name="amountInINR"
                                    disabled
                                    value={convertedVal || ""}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                  />
                                  {/* <Form.Control
                              type="number"
                              name="amountInINR"
                              disabled
                              placeholder="0"
                              // defaultValue={convertedVal && convertedVal}
                              onChange={formik.handleChange}
                              value={convertedVal && convertedVal}
                            /> */}
                                </InputGroup>
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="card1">
                    <h5>GST Applicable</h5>
                    <Row>
                      <Col>
                        <Form.Label className="switch-btn">
                          Is GST applicable?
                        </Form.Label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isGst}
                            onChange={() => setIsGst(!isGst)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </Col>
                    </Row>
                    {isGst && (
                      <>
                        <Row>
                        <Col className="custom-select">
                    <Form.Label>Company Name</Form.Label>
                    <CustomSelect
                      options={companyTypeOption}
                      placeHolder={<div>Type or select...</div>}
                      onChange={(newValue) => setCompanyTypeValue(newValue)}
                      isSearchable
                      onCreateOption={(e) => {
                        createNewFormOption(
                          createOption(e),
                          "company-type",
                          true
                        );
                      }}
                      onDeleteOption={(option) => {
                        deleteFromOption(option, "company-type");
                      }}
                      onEditOption = {(option,newValue)=>{
                        editFromOption(option,newValue,"company-type")
                      }}
                      defaultValue={companyTypeValue}
                      role = {role}
                    />
                    
                  </Col>
                  <Col>
                    <Form.Label>GST Number</Form.Label>
                    
                    <div className="input_with-edit">
                      <Form.Control
                        type="text"
                        name="gstNumber"
                        placeholder="Company GST Number"
                        disabled={editGst}
                        {...formik.getFieldProps("gstNumber")}
                        isInvalid={formik.errors.gstNumber}
                      />
                      {editGst ? (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                          alt="edit"
                          className="edit-button panEdit"
                          onClick={handleEditGST}
                        />
                      ) : (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                          alt="right"
                          className="edit-button panEdit"
                          onClick={() => {
                            setEditGst(false);
                            handleAddCompanyGst();
                          }}
                        />
                      )}
                    </div>
                    {formik.errors.gstNumber &&
                    <p className="form_error">{formik.errors.gstNumber}</p>
                    }
                  </Col>
                         
                        </Row>
                        <Row>
                        <Col>
                            <Form.Label>IGST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                type="text"
                                onValueChange={(e) => {
                                  setGstAmount({
                                    ...gstAmount,
                                    igst: e?.value,
                                  });
                                }}
                                name="igst"
                                value={gstAmount?.igst || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </InputGroup>
                          </Col>
                          <Col>
                            <Form.Label>SGST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                name="sgst"
                                type="text"
                                onValueChange={(e) => {
                                  setGstAmount({
                                    ...gstAmount,
                                    sgst: e?.value,
                                  });
                                }}
                                value={gstAmount?.sgst || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </InputGroup>
                          </Col>
                         
                        </Row>
                        <Row>
                        <Col>
                            <Form.Label>CGST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                type="text"
                                onValueChange={(e) => {
                                  setGstAmount({
                                    ...gstAmount,
                                    cgst: e?.value,
                                  });
                                }}
                                name="cgst"
                                value={gstAmount?.cgst || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </InputGroup>
                          </Col>
                          
                        <Col>
                            <Form.Label>Total GST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                type="text"
                               
                                name="total_gst"
                                value={(Number(gstAmount?.cgst) + Number(gstAmount?.sgst) + Number(gstAmount?.igst)) || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </>
              )}
              {(role === "Manager" || role === "Admin") &&
                activeList === "pending" &&
                isEdit &&
                !totalPayAmountIsApproved &&
                sendPayAmountRequest && (
                  <>
                    <div className="card1">
                      <h5>Approve OR Reject Remark</h5>

                      <Row>
                        <Col>
                          <Form.Label>Remarks</Form.Label>
                          <span className="span-star">*</span>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder="Type Here..."
                            value={approveOrRejectRemarks}
                            onChange={(e) =>
                              setApproveOrRejectRemarks(e.target.value)
                            }
                            isInvalid={
                              !approveOrRejectRemarks &&
                              errors?.approveOrRejectRemarks
                            }
                          />

                          {!approveOrRejectRemarks && (
                            <p className="form_error">
                              {errors?.approveOrRejectRemarks}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              <Row>
                {(role === "Manager" || role === "Admin") &&
                activeList === "pending" &&
                isEdit &&
                !totalPayAmountIsApproved &&
                sendPayAmountRequest ? (
                  <ModalFooter
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    {!totalPayAmountIsApproved && (
                      <div className="d-flex">
                        <Button
                          variant="brand"
                          disabled={totalPaymentAmount === null ? true : false}
                          onClick={() => {
                            handleTotalPayAmountIsApproved();
                          }}
                          className="submit-btn me-2"
                        >
                          Approve
                        </Button>

                        <Button
                          className="submit-btn"
                          variant="brand"
                          disabled={totalPaymentAmount === null ? true : false}
                          onClick={() => handleTotalPayAmountIsRejected()}
                          style={{ background: "#191063" }}
                        >
                          Reject
                        </Button>
                      </div>
                    )}
                  </ModalFooter>
                ) : (
                  <ModalFooter>
                    {(!totalPayAmountIsApproved && !sendPayAmountRequest) ||
                    remainingAmount < 0 ? (
                      <Button
                        variant="brand"
                        disabled={totalPaymentAmount === null ? true : false}
                        onClick={() => {
                          handleAmountApproveBtn(formik.values);
                        }}
                        className="submit-btn"
                      >
                        Send Request
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        variant="brand"
                        className="submit-btn"
                        onClick={() => setCompareDataModal(true)}
                        style={{ background: "#191063" }}
                      >
                        Submit
                      </Button>
                    )}
                  </ModalFooter>
                )}
              </Row>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>

      {compareDataModal && comparingData && (
        <DataChangeModalOnEdit
          openModal={compareDataModal}
          closeModal={closeComparedDataModal}
          editData={editData}
          currentData={comparingData}
          isConfirmed={() => {
            submitUpdateData(comparingData, editData);
          }}
          type={"external"}
        />
      )}
    </>
  );
};

export default AddNew;
