import React, { Component } from "react";
import Chart from "react-apexcharts";

class ColumnChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      chartOptions: {
      
        yaxis: {
          labels: {
            formatter: (value) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
            style: {
              colors: ['#000000'], // Color of the data labels
              fontSize: '12px', // Font size of the data labels
              fontFamily: 'sans-serif',     
            },
          }
        },
        tooltip: {
          y: {
            formatter: (value) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          offsetY: -15,
          style: {
            colors: ['#000000'], // Color of the data labels
            fontSize: '12px', // Font size of the data labels
            fontFamily: 'sans-serif',     
          },

        },
        // Add other default options as needed
      },
    };
  }

  componentDidMount() {
    this.updateChartData(this.props.chartData, this.props.chartOptions);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chartData !== this.props.chartData || prevProps.chartOptions !== this.props.chartOptions) {
      this.updateChartData(this.props.chartData, this.props.chartOptions);
    }
  }

  updateChartData(chartData, chartOptions) {
    this.setState({
      chartData,
      chartOptions: {
        ...this.state.chartOptions,
        ...chartOptions,
       
        yaxis: {
          ...this.state.chartOptions.yaxis,
          labels: {
            formatter: (value) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
          }
        },
        tooltip: {
          ...this.state.chartOptions.tooltip,
          y: {
            formatter: (value) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
          }
        },
        dataLabels: {
          ...this.state.chartOptions.dataLabels,
          enabled: true, // Ensure dataLabels are enabled
          formatter: (value) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          style: {
            colors: ['#000000'], // Color of the data labels
            fontSize: '12px', // Font size of the data labels
            fontFamily: 'sans-serif',     
          },
          offsetY: -15,
          chart: {
            ...this.state.chartOptions.chart,
            background: '#f0f0f0' // Ensure background color is applied
          }
        }
      },
    });
  }

  render() {
    return (
      <Chart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="bar"
        width="100%"
        height="100%"
      />
    );
  }
}

export default ColumnChart;
