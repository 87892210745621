import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchAsyncDeleteSubCategory } from "redux/formOptions";
import { fetchAsyncCreateSubCategory } from "redux/formOptions";
import { fetchAsyncGetSubCategory } from "redux/formOptions";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];



const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncGetSubCategory.pending]: (state) => {},
    [fetchAsyncGetSubCategory.fulfilled]: (state, { payload }) => {
      return payload;
    },
    [fetchAsyncCreateSubCategory.pending]: (state) => {},
    [fetchAsyncCreateSubCategory.fulfilled]: (state, { payload }) => {
      state = [...state, payload];
      return state;
    },
    [fetchAsyncDeleteSubCategory.pending]: (state) => {},
    [fetchAsyncDeleteSubCategory.fulfilled]: (state, { payload }) => {
      state = state.filter((item) => item._id !== payload._id);
      return state;
    },
  },
});

export const getAllSubCategory = (state) => state.subCategory;

export default subCategorySlice.reducer;

