import React, { useState } from "react";
import { useParams } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";


import { useDispatch } from "react-redux";
import { clearToken } from "redux/slices/Auth";
const API_URL = process.env.REACT_APP_BASE_URL;

function UserNotFound() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const backToHome = async () => {
    try {
      localStorage.removeItem("authToken");
      dispatch(clearToken());

      window.location.href = `${API_URL}/signout`;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <DefaultAuth>
      <Flex
        className="signin-auth"
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px" mt="100px">
            Account not found!
          </Heading>
          <Text
            mb="6px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Your account is not registered at embrill, please contact to the
            administrator.
          </Text>
          <Text
            mb="46px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Requested account: <span>{params?.email}</span>
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              disabled={isLoading}
              onClick={() => {
                backToHome();
              }}
            >
              {`${isLoading ? "Loading" : "Logout & Return"} `}
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default UserNotFound;
