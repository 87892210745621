import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastProvider } from "react-toast-notifications";
import App from "./App";
let persistor = persistStore(store);
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ChakraProvider theme={theme}>
          <React.StrictMode>
            <ToastProvider
              // style={{ zIndex: "1000" }}
              autoDismiss
              autoDismissTimeout={3000}
            >
              <ThemeEditorProvider>
                <App />
              </ThemeEditorProvider>
            </ToastProvider>
          </React.StrictMode>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
