/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

const CategoryCard = ({name,key, icon,path}) => {
  return (
    <>
        <a href={path} key={key}>

        <div className="category-card">
          <span className="category-span">
            <label className="card-label">
              <i className="card-icon">{icon}</i>
            </label>
            <p className="card-heading">{name}</p>   
          </span>
        </div>
        </a>
       
    </>
  );
};

export default CategoryCard;
