import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Button,
  ModalHeader,
  ModalOverlay,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { Col, Row } from "react-bootstrap";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { dateCompare } from "jsFunctions/dateTimeFormat";
import { fetchAsyncResourcePONum } from "redux/tdsSlice";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { useDispatch } from "react-redux";
import { currencyFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";

const DataChangeModalOnEdit = ({
  openModal,
  closeModal,
  editData,
  currentData,
  isConfirmed,
  type,
}) => {
  let getcurrectData = {};
  for (const pair of currentData?.entries()) {
    if (pair[0] === "payInfo") {
      getcurrectData[pair[0]] = JSON.parse(pair[1]);
    } else {
      getcurrectData[pair[0]] = pair[1];
    }
  }

  const [currentPo, setCurrentPo] = useState('')
  const [editPo, setEditPo] = useState('')
  const [gstCurrentAmount, setGstCurrentAmount] = useState({})
  const dispatch = useDispatch();

  console.log(getcurrectData,'current')
  console.log(editData,'editData')

  const fetchResourcePo = (referenceResourcePO,type)=>{
     dispatch(fetchAsyncResourcePONum()).then((res) => {
      if (res.payload) {
        const resourcePoNo = [];
        res.payload.forEach((item, index) => {
          resourcePoNo.push({
            _id: item._id,
            poNo: poNumberFormate(item.poNo),
          });
        });
        const array = resourcePoNo && resourcePoNo?.length > 0 && resourcePoNo.filter((item)=> item._id == referenceResourcePO)
        const obj = array && array?.length && array[0]
        
        const pono = obj && obj?.poNo ? obj?.poNo : '';
        if(type === 'current'){
          setCurrentPo(pono)
        }
        if(type === 'edited'){
          setEditPo(pono)
        }
      
      }
    
    });
  }


  useEffect(()=>{
    if(getcurrectData?.refResourcePONum){
      fetchResourcePo(getcurrectData?.refResourcePONum,'edited')
    }
    if(editData?.referenceResourcePO){
      fetchResourcePo(editData?.referenceResourcePO,'current')
    }
    if(getcurrectData?.gstAmount){
      setGstCurrentAmount(JSON.parse(getcurrectData?.gstAmount))
    }
  },[])


  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={closeModal}
        size="xl"
        className="modal-main data-change-model"
        scrollBehavior={"inside"}
        
      >
        <ModalOverlay />
        <ModalContent className="data-change-content info-model"
>
          <ModalHeader>
            <>
              Compare fields
              <Row className="border-2 py-2">
                <Col>
                  <h6 style={{ fontWeight: "normal" }} className="text-center">
                    Current
                  </h6>
                </Col>
                
                <Col>
                  <h6 style={{ fontWeight: "normal" }} className="text-center">
                    Edited
                  </h6>
                </Col>
              </Row>
            </>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="">
            <Row>
              <Col>
                <div className="row-table">
                  <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Purchase Order Details :
                        </h4>
                      </Row>
                  <Row className="rowC">
                    <Col>
                      Order Month : {dateTimeFormat(editData.orderMonth)}
                    </Col>
                  </Row>
                  <Row className="rowC">
                    <Col>Order Date : {dateTimeFormat(editData.orderDate)}</Col>
                  </Row>

                  {type === "direct-tax" && (
                    <>
                      <Row className="rowC">
                        <Col className={`${editData.receiverName !== getcurrectData.receiverName  ? 'data_compare_red' : '' }`}>Resource Name : {editData.receiverName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.totalTax != getcurrectData.totalTax  ? 'data_compare_red' : '' }`}>PAN Number: {editData.totalTax}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_red' : '' }`}>Remarks : {editData.remarks}</Col>
                      </Row>
                    </>
                  )}
                    {type === "tds" && (
                    <>
                      <Row className="rowC">
                        <Col className={`${editData.resourceName !== getcurrectData.resourceName  ? 'data_compare_red' : '' }`}>Resource Name : {editData.resourceName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.panNumber !== getcurrectData.panNumber  ? 'data_compare_red' : '' }`}>Pan Number : {editData?.panNumber}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_red' : '' }`}>Remarks : {editData.remarks}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.referenceResourcePO !== getcurrectData?.refResourcePONum  ? 'data_compare_red' : '' }`}>Reference Resource PO  : {currentPo}</Col>
                      </Row>
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          TDS Applicable :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.totalBillingAmount != getcurrectData.totalAmount  ? 'data_compare_red' : '' }`}>
                          Total Amount : {formatAmountIndianRupees(editData?.totalBillingAmount)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsPercent != getcurrectData.tdsPercent  ? 'data_compare_red' : '' }`}>
                          TDS Percent : {formatAmountIndianRupees(editData?.tdsPercent)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsAmount != getcurrectData.tdsAmount  ? 'data_compare_red' : '' }`}>
                          TDS Amount : {(formatAmountIndianRupees(parseFloat(editData?.tdsAmount)))}
                        </Col>
                      </Row>

                    
                    </>
                  )}

                  {type === "resource" && (
                    <>
                   
                      <Row className="rowC">
                        <Col className={`${editData?.resourceName !== getcurrectData.resourceName  ? 'data_compare_red' : '' }`}>Resource Name : {editData?.resourceName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.panNumber !== getcurrectData.panNumber  ? 'data_compare_red' : '' }`}>Pan Number : {editData?.panNumber}</Col>
                      </Row>
                    
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Expense :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.expense.majorType !== getcurrectData.expenseType  ? 'data_compare_red' : '' }`}>Major Type : {editData.expense?.majorType}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.minorType !== getcurrectData.minorType  ? 'data_compare_red' : '' }`}>Minor Type : {editData.minorType}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.minorSubCategory !== getcurrectData.minorTypeCategory  ? 'data_compare_red' : '' }`}>Minor Sub Category : {editData.minorSubCategory}</Col>
                      </Row>
                      
                      <Row className="rowC">
                        <Col className={`${editData.clientName !== getcurrectData.clientName  ? 'data_compare_red' : '' }`}>Client Name : {editData.clientName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.projectName !== getcurrectData.projectName  ? 'data_compare_red' : '' }`}>Project Name : {editData.projectName}</Col>
                      </Row>
                      
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_red' : '' }`}>Remarks : {editData.remarks}</Col>
                      </Row>
                     
                     
                      
                    </>
                  )}
                  {type === "internal" || type === "external" ? (
                    <>
                      
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Expense :
                        </h4>
                      </Row>
                      {type === "internal" && 
                      <Row className="rowC" >
                        <Col className={`${editData.officeType !== getcurrectData.officeType  ? 'data_compare_red' : '' }`}>Office : {editData.officeType}</Col>
                      </Row>
                      }
                      <Row className="rowC">
                        <Col className={`${editData.expense.majorType !== getcurrectData.expenseType  ? 'data_compare_red' : '' }`}>Major Type : {editData.expense.majorType}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.minorType !== getcurrectData.minorType  ? 'data_compare_red' : '' }`}>Minor Type : {editData.minorType}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.minorSubCategory !== getcurrectData.minorTypeCategory  ? 'data_compare_red' : '' }`}>Minor Sub Category : {editData.minorSubCategory}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.merchantName !== getcurrectData.merchantName  ? 'data_compare_red' : '' }`}>Merchant Name : {editData.merchantName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.clientName !== getcurrectData.clientName  ? 'data_compare_red' : '' }`}>Client Name : {editData.clientName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_red' : '' }`}>Remarks : {editData.remarks}</Col>
                      </Row>
                      {
                        type === "external" &&

                      <Row className="rowC">
                        <Col className={`${editData.projectName !== getcurrectData.projectName  ? 'data_compare_red' : '' }`}>Project Name : {editData.projectName}</Col>
                      </Row>
                      }
                     
                      <Row className="rowC">
                        <Col>Agency : {editData.expense.agency}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.expense.trackingNum !== getcurrectData.trackingNum  ? 'data_compare_red' : '' }`}> Tracking Num : {editData.expense.trackingNum}</Col>
                      </Row>
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Payment Information : 
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.paymentInfo?.approvalAmount != getcurrectData?.approvalAmount  ? 'data_compare_red' : '' }`}>Requested Amount: {formatAmountIndianRupees(editData?.paymentInfo?.approvalAmount)}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${(editData.paymentInfo?.isSettle ? 'true' : 'false') !== getcurrectData?.isPaymentSettle  ? 'data_compare_red' : '' }`}>
                          Payment Settle :{" "}
                          {editData.paymentInfo?.isSettle ? "true" : "false"}
                        </Col>
                       {type === 'internal' &&
                        <Col 
                        className={`${!dateCompare(dateTimeFormat(editData.paymentInfo?.setteledDate) ,dateTimeFormat(getcurrectData.paymmentSettleDate))  ? 'data_compare_red' : '' }`}
                        >
                          Date :{" "}
                          {editData.paymentInfo?.setteledDate &&
                            dateTimeFormat(editData.paymentInfo?.setteledDate)}
                        </Col>
                       }
                        {type === 'external' &&
                        <Col 
                        className={`${!dateCompare(dateTimeFormat(editData.paymentInfo?.setteledDate) ,dateTimeFormat(getcurrectData.paymentSettleDate))  ? 'data_compare_red' : '' }`}
                        >
                          Date :{" "}
                          {editData.paymentInfo?.setteledDate &&
                            dateTimeFormat(editData.paymentInfo?.setteledDate)}
                        </Col>
                       }
                      </Row>
                    
                    </>
                  ) : null}
                 
                 
                
                  {(type === 'resource' || type === 'direct-tax' || type === 'tds') &&
                  <>
                  <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Payment Information : 
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.paymentInfo?.approvalAmount != getcurrectData?.approvalAmount  ? 'data_compare_red' : '' }`}>Requested Amount: {formatAmountIndianRupees(editData?.paymentInfo?.approvalAmount)}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${(editData.paymentInfo?.isSettle ? 'true' : 'false') !== getcurrectData?.isPaymentSettle  ? 'data_compare_red' : '' }`}>
                          Payment Settle :{" "}
                          {editData.paymentInfo?.isSettle ? "true" : "false"}
                        </Col>
                        
                        <Col 
                        className={`${!dateCompare(dateTimeFormat(editData.paymentInfo?.setteledDate) ,dateTimeFormat(getcurrectData.setteledDate))  ? 'data_compare_red' : '' }`}
                        >
                          Date :{" "}
                          {editData.paymentInfo?.setteledDate &&
                            dateTimeFormat(editData.paymentInfo?.setteledDate)}
                        </Col>
                       
                      </Row>
                    
                  </>
                  }
                  <div className="info-table">

                   <Table className="table-info">
                    <Thead>
                      <Tr>
                        <Th>Payee</Th>
                        <Th>Bank</Th>
                        <Th>Amount</Th>
                        <Th>Date</Th>

                      </Tr>
                    </Thead>
                    <Tbody>
                    {editData?.paymentInfo?.list.map((item,index) => {
                    return (
                      <Tr>
                        <Td className={`${item?.payee !== getcurrectData?.payInfo[index]?.payee  ? 'data_compare_red' : '' }`}>{item?.payee}</Td>
                        <Td className={`${item?.bank !== getcurrectData?.payInfo[index]?.bank  ? 'data_compare_red' : '' }`}>{item?.bank}</Td>
                        <Td className={`${item?.amount !== getcurrectData?.payInfo[index]?.amount  ? 'data_compare_red' : '' }`}>{formatAmountIndianRupees(item?.amount)}</Td>
                        <Td className={`${!(dateTimeFormat(item?.paymentDate) ,dateTimeFormat(getcurrectData?.payInfo[index]?.paymentDate))  ? 'data_compare_red' : '' }`}>{dateTimeFormat(item?.paymentDate)}</Td>

                      </Tr>

                    );
                  })}
                    </Tbody>
                  </Table>
                  </div>
                  {type === 'internal' &&
                  <>
                  <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          TDS Applicable :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.totalAmount != getcurrectData.totalAmount  ? 'data_compare_red' : '' }`}>
                          Total Amount : {formatAmountIndianRupees(editData?.tdsApplicable?.totalAmount)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.tdsPercent != getcurrectData.tdsPercent  ? 'data_compare_red' : '' }`}>
                          TDS Percent : {editData?.tdsApplicable?.tdsPercent}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.totalTDS != getcurrectData.totalTDS  ? 'data_compare_red' : '' }`}>
                          TDS Total Amount : {formatAmountIndianRupees(parseFloat(editData?.tdsApplicable?.totalTDS))}
                        </Col>
                      </Row>

                      <Row className="rowC">
                        <Col className={`${(editData?.tdsApplicable?.generateTdsPO?.isTDSPO ? 'true' : 'false') !== getcurrectData?.isTDSPO  ? 'data_compare_red' : '' }`}>
                          TDS PO :{" "}
                          {editData?.tdsApplicable?.generateTdsPO?.isTDSPO
                            ? "true"
                            : "false"}
                        </Col>
                      </Row>
                  </>
                  }
                  {(type === "external" || type === "internal") && (
                    <>
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Reimbursement Information
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${!dateCompare(dateTimeFormat(editData?.reimbursementInfo?.invoiceDate) ,dateTimeFormat(getcurrectData?.invoiceDate))  ? 'data_compare_red' : '' }`}>
                          Invoice Date :{" "}
                          {dateTimeFormat(
                            editData?.reimbursementInfo?.invoiceDate
                          )}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col 
                        className={`${editData?.reimbursementInfo?.invoiceNum !== getcurrectData.invoiceNum  ? 'data_compare_red' : '' }`}
                        >
                          Invoice Number :{" "}
                          {editData?.reimbursementInfo?.invoiceNum}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${(editData?.reimbursementInfo?.isReimbursement
                            ?.isReimburs ? 'true' : 'false') !== getcurrectData?.isReimburs  ? 'data_compare_red' : '' }`}>
                          Is Reimbursed :{" "}
                          {editData?.reimbursementInfo?.isReimbursement
                            ?.isReimburs
                            ? "true"
                            : "false"}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col  className={`${editData?.reimbursementInfo?.isReimbursement?.amount != getcurrectData.reimburseAmount  ? 'data_compare_red' : '' }`}>
                          Reimbursed Amount:{" "}
                          {formatAmountIndianRupees(editData?.reimbursementInfo?.isReimbursement?.amount)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.isReimbursement
                              ?.amountInINR != getcurrectData.amountInINR  ? 'data_compare_red' : '' }`}>
                          Reimbursed Amount In INR:{" "}
                          {
                            editData?.reimbursementInfo?.isReimbursement
                              ?.amountInINR
                          }
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.isReimbursement
                              ?.conversionRate != getcurrectData.conversionRate  ? 'data_compare_red' : '' }`}>
                          Reimbursed Conversion Rate:{" "}
                          {
                            editData?.reimbursementInfo?.isReimbursement
                              ?.conversionRate
                          }
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.isReimbursement
                              ?.currency != getcurrectData.currency  ? 'data_compare_red' : '' }`}>
                          Reimbursed Currency:{" "}
                          {
                            editData?.reimbursementInfo?.isReimbursement
                              ?.currency
                          }
                        </Col>
                      </Row>
                    </>
                  )}
                   {type === "resource" && (
                    <>
                    <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          TDS Applicable :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.totalAmount != getcurrectData.totalAmount  ? 'data_compare_red' : '' }`}>
                          Total Amount : {formatAmountIndianRupees(editData?.tdsApplicable?.totalAmount)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.tdsPercent != getcurrectData.tdsPercent  ? 'data_compare_red' : '' }`}>
                          TDS Percent : {editData?.tdsApplicable?.tdsPercent}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.totalTDS != getcurrectData.totalTDS  ? 'data_compare_red' : '' }`}>
                          TDS Total Amount : {formatAmountIndianRupees(parseFloat(editData?.tdsApplicable?.totalTDS))}
                        </Col>
                      </Row>

                      <Row className="rowC">
                        <Col className={`${(editData?.tdsApplicable?.generateTdsPO?.isTDSPO ? 'true' : 'false') !== getcurrectData?.isTDSPO  ? 'data_compare_red' : '' }`}>
                          TDS PO :{" "}
                          {editData?.tdsApplicable?.generateTdsPO?.isTDSPO
                            ? "true"
                            : "false"}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Reimbursement Information :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${(editData?.reimbursementInfo?.isReimbursement
                             ? 'true' : 'false') != getcurrectData?.isReimbursement  ? 'data_compare_red' : '' }`}>
                          Is Reimbursed :{" "}
                          {editData?.reimbursementInfo?.isReimbursement
                            ? "true"
                            : "false"}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.amount != getcurrectData.amount  ? 'data_compare_red' : '' }`}>
                          Reimbursed Amount :{" "}
                          {formatAmountIndianRupees(editData?.reimbursementInfo?.amount)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.invoiceNum != getcurrectData?.invoiceNum  ? 'data_compare_red' : '' }`} >
                          Reimbursed Invoice Number :{" "}
                          {editData?.reimbursementInfo?.invoiceNum}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${!dateCompare(dateTimeFormat(editData?.reimbursementInfo?.invoiceDate) ,dateTimeFormat(getcurrectData?.invoiceDate))  ? 'data_compare_red' : '' }`}>
                          Reimbursed Invoice Date :{" "}

                          {editData?.reimbursementInfo?.invoiceDate && dateTimeFormat(
                            editData?.reimbursementInfo?.invoiceDate
                          )}
                        </Col>
                      </Row>
                      
                    </>
                  )}
                    <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          GST Applicable :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${(editData?.gstApplicable?.isGST
                             ? 'true' : 'false') != getcurrectData?.isGST  ? 'data_compare_red' : '' }`}>
                          Is GST Applicable :{" "}
                          {editData?.gstApplicable?.isGST
                            ? "true"
                            : "false"}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.gstApplicable?.companyName != getcurrectData?.companyType  ? 'data_compare_red' : '' }`} >
                          Comapny Name:{" "}
                          {editData?.gstApplicable?.companyName}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.gstApplicable?.gstNumber != getcurrectData?.gstNumber  ? 'data_compare_red' : '' }`} >
                          GST Number :{" "}
                          {editData?.gstApplicable?.gstNumber}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.gstApplicable?.gstAmount?.cgst !== gstCurrentAmount?.cgst  ? 'data_compare_red' : '' }`} >
                          CGST :{" "}
                          {formatAmountIndianRupees(editData?.gstApplicable?.gstAmount?.cgst)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.gstApplicable?.gstAmount?.sgst !== gstCurrentAmount?.sgst  ? 'data_compare_red' : '' }`} >
                          SGST :{" "}
                          {formatAmountIndianRupees(editData?.gstApplicable?.gstAmount?.sgst)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.gstApplicable?.gstAmount?.igst !== gstCurrentAmount?.igst  ? 'data_compare_red' : '' }`} >
                          IGST :{" "}
                          {formatAmountIndianRupees(editData?.gstApplicable?.gstAmount?.igst)}
                        </Col>
                      </Row>
                </div>
              </Col>
              <Col className="col1">
                <div className="row-table">
                <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Purchase Order Details :
                        </h4>
                      </Row>
                  <Row className="rowC">
                    <Col>
                      Order Month : {dateTimeFormat(getcurrectData.orderMonth)}
                    </Col>
                  </Row>
                  <Row className="rowC">
                    <Col>
                      Order Date : {dateTimeFormat(getcurrectData.orderDate)}
                    </Col>
                  </Row>
                  {type === "resource" && (
                    <>
                    
                      <Row className="rowC">
                        <Col className={`${editData?.resourceName !== getcurrectData.resourceName  ? 'data_compare_green' : '' }`}>Resource Name : {getcurrectData?.resourceName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.panNumber !== getcurrectData.panNumber  ? 'data_compare_green' : '' }`}>Pan Number : {getcurrectData?.panNumber}</Col>
                      </Row>
                     
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Expense :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.expense.majorType !== getcurrectData.expenseType  ? 'data_compare_green' : '' }`}>Major Type : {getcurrectData.expenseType}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.minorType !== getcurrectData.minorType  ? 'data_compare_green' : '' }`}>Minor Type : {getcurrectData.minorType}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.minorSubCategory !== getcurrectData.minorTypeCategory  ? 'data_compare_green' : '' }`}>Minor Sub Category : {getcurrectData.minorTypeCategory ? getcurrectData.minorTypeCategory : ''}</Col>
                      </Row>
                     
                      <Row className="rowC">
                        <Col className={`${editData.clientName !== getcurrectData.clientName  ? 'data_compare_green' : '' }`}>Client Name : {getcurrectData.clientName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.projectName !== getcurrectData.projectName  ? 'data_compare_green' : '' }`}>Project Name : {getcurrectData.projectName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_green' : '' }`}>Remarks : {getcurrectData.remarks}</Col>
                      </Row>
                   
                      
                    </>
                  )}
                  
                  {type === "direct-tax" && (
                    <>
                    
                      <Row className="rowC">
                        <Col className={`${editData.receiverName !== getcurrectData.receiverName  ? 'data_compare_green' : '' }`}>Resource Name: {getcurrectData.receiverName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.totalTax != getcurrectData.totalTax  ? 'data_compare_green' : '' }`}>Total Tax:{getcurrectData.totalTax}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_green' : '' }`}>Remarks : {getcurrectData.remarks}</Col>
                      </Row>

                    </>
                  )}
                   {type === "tds" && (
                    <>
                    
                      <Row className="rowC">
                        <Col className={`${editData.resourceName !== getcurrectData.resourceName  ? 'data_compare_green' : '' }`}>Resource Name : {getcurrectData.resourceName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.panNumber !== getcurrectData.panNumber  ? 'data_compare_green' : '' }`}>Pan Number : {getcurrectData?.panNumber}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_green' : '' }`}>Remarks : {getcurrectData.remarks}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.referenceResourcePO !== getcurrectData?.refResourcePONum  ? 'data_compare_green' : '' }`}>Reference Resource PO : {editPo}</Col>
                      </Row>
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          TDS Applicable :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.totalBillingAmount != getcurrectData.totalAmount  ? 'data_compare_green' : '' }`}>Total Amount : {formatAmountIndianRupees(getcurrectData.totalAmount)}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsPercent != getcurrectData.tdsPercent  ? 'data_compare_green' : '' }`}>TDS Percent : {getcurrectData?.tdsPercent}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsAmount != getcurrectData.tdsAmount  ? 'data_compare_green' : '' }`}>TDS Amount : {formatAmountIndianRupees(parseFloat(getcurrectData?.tdsAmount))}</Col>
                      </Row>
                     
                    </>
                  )}
                  {type === "internal" || type === "external" ? (
                    <>
                     
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Expense :
                        </h4>
                      </Row>
                      {type === 'internal' &&

                      <Row className="rowC">
                        <Col className={`${editData.officeType !== getcurrectData.officeType  ? 'data_compare_green' : '' }`}>Office : {getcurrectData.officeType}</Col>
                      </Row>
                      }
                      <Row className="rowC">
                        <Col className={`${editData.expense.majorType !== getcurrectData.expenseType  ? 'data_compare_green' : '' }`}>Major Type : {getcurrectData.expenseType}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.minorType !== getcurrectData.minorType  ? 'data_compare_green' : '' }`}>Minor Type : {getcurrectData.minorType}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.minorSubCategory !== getcurrectData.minorTypeCategory  ? 'data_compare_green' : '' }`}>Minor Sub Category : {getcurrectData.minorTypeCategory ? getcurrectData.minorTypeCategory : ''}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.merchantName !== getcurrectData.merchantName  ? 'data_compare_green' : '' }`}>Merchant Name : {getcurrectData.merchantName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.clientName !== getcurrectData.clientName  ? 'data_compare_green' : '' }`}>Client Name : {getcurrectData.clientName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_green' : '' }`}>Remarks : {getcurrectData.remarks}</Col>
                      </Row>
                      {type === 'external' &&
                      <Row className="rowC">
                        <Col className={`${editData.projectName !== getcurrectData.projectName  ? 'data_compare_green' : '' }`}>Project Name : {getcurrectData.projectName}</Col>
                      </Row>
                      }
                     
                      <Row className="rowC">
                        <Col className={`${editData.expense.trackingNum !== getcurrectData.trackingNum  ? 'data_compare_green' : '' }`}>Agency : {getcurrectData.agency}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col>Tracking Num : {getcurrectData.trackingNum}</Col>
                      </Row>
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Payment Information :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.paymentInfo?.approvalAmount != getcurrectData?.approvalAmount  ? 'data_compare_green' : '' }`}>Requested Amount: {formatAmountIndianRupees(getcurrectData?.approvalAmount) }</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${(editData.paymentInfo?.isSettle ? 'true' : 'false') !== getcurrectData?.isPaymentSettle  ? 'data_compare_green' : '' }`}>
                          Payment Settle : {getcurrectData.isPaymentSettle}
                        </Col>
                        {type === "internal" &&
                        <Col className={`${!dateCompare(dateTimeFormat(editData.paymentInfo?.setteledDate) ,dateTimeFormat(getcurrectData.paymmentSettleDate))  ? 'data_compare_green' : '' }`}>  
                          Date :{" "}
                          {getcurrectData.paymmentSettleDate &&
                            dateTimeFormat(getcurrectData.paymmentSettleDate)}
                        </Col>
                        } 
                        {type === "external" &&
                        <Col className={`${!dateCompare(dateTimeFormat(editData.paymentInfo?.setteledDate) ,dateTimeFormat(getcurrectData.paymentSettleDate))  ? 'data_compare_green' : '' }`}>  
                          Date :{" "}
                          {getcurrectData.paymentSettleDate &&
                            dateTimeFormat(getcurrectData.paymentSettleDate)}
                        </Col>
                        } 
                      </Row>
                    
                    </>
                  ) : null}
                 

                  {
                    (type === 'resource' || type === 'direct-tax' || type === 'tds') &&
                    <>
                     <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Payment Information :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.paymentInfo?.approvalAmount != getcurrectData?.approvalAmount  ? 'data_compare_green' : '' }`}>Requested Amount: {formatAmountIndianRupees(getcurrectData?.approvalAmount )}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${(editData.paymentInfo?.isSettle ? 'true' : 'false') !== getcurrectData?.isPaymentSettle  ? 'data_compare_green' : '' }`}>
                          Payment Settle : {getcurrectData.isPaymentSettle}
                        </Col>
                       
                        <Col className={`${!dateCompare(dateTimeFormat(editData.paymentInfo?.setteledDate) ,dateTimeFormat(getcurrectData.setteledDate))  ? 'data_compare_green' : '' }`}>  
                          Date :{" "}
                          {getcurrectData.setteledDate &&
                            dateTimeFormat(getcurrectData.setteledDate)}
                        </Col>
                       
                      </Row>
                    </>
                  }

                 
                 <div className="info-table">

                  <Table size="md" className="table-info">
                    <Thead>
                      <Tr>
                        <Th>Payee</Th>
                        <Th>Bank</Th>
                        <Th>Amount</Th>
                        <Th>Date</Th>

                      </Tr>
                    </Thead>
                    <Tbody>
                    {getcurrectData?.payInfo?.map((item,index) => {
                    return (
                      <Tr>
                        <Td className={`${item?.payee !== editData?.paymentInfo?.list[index]?.payee  ? 'data_compare_green' : '' }`}>{item?.payee}</Td>
                        <Td className={`${item?.bank !== editData?.paymentInfo?.list[index]?.bank  ? 'data_compare_green' : '' }`}>{item?.bank}</Td>
                        <Td className={`${item?.amount !== editData?.paymentInfo?.list[index]?.amount  ? 'data_compare_green' : '' }`}>{formatAmountIndianRupees(item?.amount)}</Td>
                        <Td className={`${!dateCompare(dateTimeFormat(item?.paymentDate) ,dateTimeFormat(editData?.paymentInfo?.list[index]?.paymentDate))  ? 'data_compare_green' : '' }`}>{dateTimeFormat(item?.paymentDate)}</Td>

                      </Tr>

                    );
                  })}
                    </Tbody>
                  </Table>
                 </div>
              
                  {type === 'internal' &&
                  <>
                  <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          TDS Applicable :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.totalAmount != getcurrectData.totalAmount  ? 'data_compare_green' : '' }`}>Total Amount : {formatAmountIndianRupees(getcurrectData.totalAmount)}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.tdsPercent != getcurrectData.tdsPercent  ? 'data_compare_green' : '' }`}>TDS Percent : {getcurrectData?.tdsPercent}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.totalTDS != getcurrectData.totalTDS  ? 'data_compare_green' : '' }`}>TDS Total Amount : {formatAmountIndianRupees(parseFloat(getcurrectData?.totalTDS))}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${(editData?.tdsApplicable?.generateTdsPO?.isTDSPO ? 'true' : 'false') != getcurrectData?.isTDSPO  ? 'data_compare_green' : '' }`}>TDS PO : {getcurrectData?.isTDSPO}</Col>
                      </Row>
                  </>
                  }
                 {(type === "external" || type === "internal") && (
                    <>
                    
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Reimbursement Information :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${!dateCompare(dateTimeFormat(editData?.reimbursementInfo?.invoiceDate) ,dateTimeFormat(getcurrectData?.invoiceDate))  ? 'data_compare_green' : '' }`}>
                          Invoice Date :{" "}
                          {dateTimeFormat(getcurrectData?.invoiceDate)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.invoiceNum !== getcurrectData.invoiceNum  ? 'data_compare_green' : '' }`}>Invoice Number : {getcurrectData?.invoiceNum}</Col>
                      </Row>
                      <Row className="rowC" >
                        <Col className={`${(editData?.reimbursementInfo?.isReimbursement
                            ?.isReimburs ? 'true' : 'false') !== getcurrectData?.isReimburs  ? 'data_compare_green' : '' }`}>Is Reimbursed : {getcurrectData?.isReimburs}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.isReimbursement?.amount != getcurrectData.reimburseAmount  ? 'data_compare_green' : '' }`}>
                          Reimbursed Amount: {formatAmountIndianRupees(getcurrectData?.reimburseAmount)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.isReimbursement
                              ?.amountInINR != getcurrectData.amountInINR  ? 'data_compare_green' : '' }`}>
                          Reimbursed Amount In INR:{" "}
                          {getcurrectData?.amountInINR}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.isReimbursement
                              ?.conversionRate != getcurrectData.conversionRate  ? 'data_compare_green' : '' }`}>
                          Reimbursed Conversion Rate:{" "}
                          {getcurrectData?.conversionRate}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.isReimbursement
                              ?.currency != getcurrectData.currency  ? 'data_compare_green' : '' }`}>
                          Reimbursed Currency: {getcurrectData?.currency}
                        </Col>
                      </Row>
                      
                    </>
                  )}
                   {type === "resource" && (
                    <>
                    <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          TDS Applicable :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.totalAmount != getcurrectData.totalAmount  ? 'data_compare_green' : '' }`}>Total Amount : {formatAmountIndianRupees(getcurrectData.totalAmount)}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.tdsPercent != getcurrectData.tdsPercent  ? 'data_compare_green' : '' }`}>TDS Percent : {getcurrectData?.tdsPercent}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsApplicable?.totalTDS != getcurrectData.totalTDS  ? 'data_compare_green' : '' }`}>TDS Total Amount : {formatAmountIndianRupees(parseFloat(getcurrectData?.totalTDS))}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${(editData?.tdsApplicable?.generateTdsPO?.isTDSPO ? 'true' : 'false') !== getcurrectData?.isTDSPO  ? 'data_compare_green' : '' }`}>TDS PO : {getcurrectData?.isTDSPO}</Col>
                      </Row>
                      <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Reimbursement Information :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${(editData?.reimbursementInfo?.isReimbursement
                             ? 'true' : 'false') != getcurrectData?.isReimbursement  ? 'data_compare_green' : '' }`}>
                          Is Reimbursed : {getcurrectData?.isReimbursement}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.amount != getcurrectData.amount  ? 'data_compare_green' : '' }`}>Reimbursed Amount : {formatAmountIndianRupees(getcurrectData?.amount)}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.reimbursementInfo?.invoiceNum != getcurrectData?.invoiceNum  ? 'data_compare_green' : '' }`}>
                          Reimbursed Invoice Number :{" "}
                          {getcurrectData?.invoiceNum}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${!dateCompare(dateTimeFormat(editData?.reimbursementInfo?.invoiceDate) ,dateTimeFormat(getcurrectData?.invoiceDate))  ? 'data_compare_green' : '' }`}>
                          Reimbursed Invoice Date :{" "}
                          {getcurrectData?.invoiceDate &&
                            dateTimeFormat(getcurrectData?.invoiceDate)}
                        </Col>
                      </Row>
                      
                    </>
                  )}
                  <Row className="rowC">
                        <h4
                          style={{
                            fontSize: "18px",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          GST Applicable:
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${(editData?.gstApplicable?.isGST
                             ? 'true' : 'false') != getcurrectData?.isGST  ? 'data_compare_green' : '' }`}>
                          Is GST Applicable : {getcurrectData?.isGST}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.gstApplicable?.companyName != getcurrectData?.companyType  ? 'data_compare_green' : '' }`}>
                          Company Name :{" "}
                          {getcurrectData?.companyType}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.gstApplicable?.gstNumber != getcurrectData?.gstNumber  ? 'data_compare_green' : '' }`}>
                          GST Number :{" "}
                          {getcurrectData?.gstNumber}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.gstApplicable?.gstAmount?.cgst !== gstCurrentAmount?.cgst  ? 'data_compare_green' : '' }`}>
                          CGST :{" "}
                          {formatAmountIndianRupees(gstCurrentAmount?.cgst)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.gstApplicable?.gstAmount?.sgst !== gstCurrentAmount?.sgst  ? 'data_compare_green' : '' }`}>
                          SGST :{" "}
                          {formatAmountIndianRupees(gstCurrentAmount?.sgst)}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.gstApplicable?.gstAmount?.igst !== gstCurrentAmount?.igst  ? 'data_compare_green' : '' }`}>
                          IGST :{" "}
                          {formatAmountIndianRupees(gstCurrentAmount?.igst)}
                        </Col>
                      </Row>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="border-2 p-0 pt-4 pb-2">
            <Button
              type="submit"
              className="submit-btn"
              variant="brand"
              style={{ background: "#191063", minWidth: "100px" }}
              onClick={() => {
                isConfirmed();
                closeModal();
              }}
            >
              ok
            </Button>
            <Button
              type="submit"
              className="submit-btn"
              variant="brand"
              style={{ background: "#191063", minWidth: "100px" }}
              onClick={() => {
                closeModal();
              }}
            >
              cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DataChangeModalOnEdit;
