export const columnsDataColumns = [
  {
    Header: "No",
    accessor: "no",
  },
  {
    Header: "Po No",
    accessor: "poNo",
  },
  {
    Header: "Order Date",
    accessor: "orderdate",
  },
  {
    Header: "Receiver Name",
    accessor: "receivername",
  },
  {
    Header: "Approved Amount",
    accessor: "approvedAmount",
  },
  {
    Header: "Requested Amount",
    accessor: "approvalAmount",
  },
  {
    Header: "Invoice",
    accessor: "invoice",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
export const rejectedDataColumns = [
  {
    Header: "No",
    accessor: "no",
  },
  {
    Header: "Po No",
    accessor: "poNo",
  },
  {
    Header: "Order Date",
    accessor: "orderdate",
  },
  {
    Header: "Receiver Name",
    accessor: "receivername",
  },
  {
    Header: "Approved Amount",
    accessor: "approvedAmount",
  },
  {
    Header: "Requested Amount",
    accessor: "approvalAmount",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
