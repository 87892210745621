/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import { Box, Button, Tooltip } from "@chakra-ui/react";

// import noDataFound from "../../assets/img/no_data_found.png";
import ColumnTable from "./embrillTable/ColumnTable";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchAsyncDeleteUser } from "redux/userSlice";
import { fetchAsyncGetUser } from "redux/userSlice";
import moment from "moment";
import UserForm from "components/allForm/UserForm";
import { fetchAsyncGetAllGst } from "redux/slices/gstSlice";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { currencyFormate } from "jsFunctions/helperFunctions";
import ExportDataModel from "components/exportData/ExportDataModel";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import GstInfoModel from "components/models/GstInfoModel";

const EmbrillGst = () => {
  

  const [gstListData, setGstListData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  const [openGstInfoModel, setOpenGstInfoModel] = useState(false);

  const currUser = useSelector((state) => state.auth.currUser);
  const [role,setRole] = useState('')  

  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Gst');
      if(roleObj){

        setRole(roleObj?.access)
      }
    }
   
  },[currUser])

  const columnsDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "PO No",
      accessor: "poNo",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
    {
      Header: "GST Number",
      accessor: "gstNumber",
    },
    {
      Header: "IGST Amount",
      accessor: "igstAmt",
    },
    {
      Header: "SGST Amount",
      accessor: "sgstAmt",
    },
    {
      Header: "CGST Amount",
      accessor: "cgstAmt",
    },
    {
      Header: "Total GST",
      accessor: "totalGst",
    },
    
    
  ];

  const gstList = useSelector((state) => state.gstData);
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(fetchAsyncGetAllGst());
  }, []);

  useEffect(() => {
    if (gstList && gstList.length > 0) {
      const tableData = gstList.map((data, index) => {
        return {
          _id:data._id,
          no: index + 1,
          poNo: data.poNo && poNumberFormate(data.poNo),
          category: data.category,
          companyName: data?.gstFields?.companyName,
          gstNumber: data?.gstFields?.gstNumber,

          igstAmt: (
            <span>

              {data?.gstFields?.gstAmount?.igst ? currencyFormate(data?.gstFields?.gstAmount?.igst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
            </span>
          ),
          sgstAmt: (
            <span>

              {data?.gstFields?.gstAmount?.sgst ? currencyFormate(data?.gstFields?.gstAmount?.sgst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
            </span>
          ),
          cgstAmt: (
            <span>

              {data?.gstFields?.gstAmount?.cgst ? currencyFormate(data?.gstFields?.gstAmount?.cgst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
            </span>
          ),
          totalGst: (
            <span>

              {data?.gstFields?.totalGst ? currencyFormate(data?.gstFields?.totalGst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
            </span>
          ),
         
          date: dateTimeFormat(data.orderDate),
          
        };
      });
      setGstListData(tableData);
    } else {
      setGstListData([]);
    }
  }, [gstList]);

  const handleRowClick = (e,_id)=>{
    e.stopPropagation();

    const data = gstList.filter((item)=> item?._id === _id)
    setSelectedItem(data[0]);
    setOpenGstInfoModel(true);
  }

  const closeGstInfoModel = () => {
    setOpenGstInfoModel(false);
    setSelectedItem({});
  };
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <div className="button-div">
          {(gstListData) && (
          <ExportDataModel
             filename={"Gst_"}
             type = "gst"
             role = {role}
            />
          )}
        </div>
      
      </Box>
      <Box pt={{ base: "180px", md: "120px", xl: "120px" }}>
        <ColumnTable
          columnsData={columnsDataColumns}
          tableData={gstListData}
          type={"Gst List"}
          role={role}
          expenseType = 'gst'
          //  getAllData={(data) => {
          //   handleOpenDirectTaxModal();
          // }}
          openInfoModal = {handleRowClick}
        />
      </Box>
      {openGstInfoModel && (
        <GstInfoModel
          openInfoModal={openGstInfoModel}
          closeInfoModal={closeGstInfoModel}
          selectedData={selectedItem}   
          role={role}
     
        />
      )}
    </>
  );
};

export default EmbrillGst;
