/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "rsuite/dist/rsuite.min.css";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

import { createListForSingleOption } from "./createableSelect";
import { createOption } from "./createableSelect";
import PaymentInfoComponent from "./paymentInfoComponent";
import CustomSelect from "components/CustomSelect";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncAddValueToFormOptions } from "redux/formOptions";
import { fetchAsyncDeleteFormOptions } from "redux/formOptions";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { fetchAsyncGetPONumDirectTaxExpense } from "redux/directTaxSlice";
import { fetchAsyncAddDirectTaxExpense } from "redux/directTaxSlice";
import { fetchAsyncGetDirectTaxExpense } from "redux/directTaxSlice";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { fetchAsyncDeleteDirectPdf } from "redux/directTaxSlice";
import { fetchAsyncUploadDirectPdf } from "redux/directTaxSlice";
import { fetchAsyncUpdateDirectTaxExpense } from "redux/directTaxSlice";
import DataChangeModalOnEdit from "components/models/DataChangeModalOnEdit";
import { fetchAsyncAddDirectTaxInvoiceRemark } from "redux/directTaxSlice";
import { fetchAsyncEditFormOptions } from "redux/formOptions";
import { fetchAsyncGetFormOptions } from "redux/formOptions";
import { checkApprovalAmount } from "jsFunctions/helperFunctions";
import { checkTotalApprovedAmountWithSpentAmount } from "jsFunctions/helperFunctions";
import { fetchAsyncRejectDirectTaxTotalPayAmount } from "redux/directTaxSlice";
import { fetchAsyncApproveDirectTaxTotalPayAmount } from "redux/directTaxSlice";
import { fetchAsyncSendRequestApprovalDirectTaxTotalPayAmount } from "redux/directTaxSlice";
import { fetchAsyncSendRequestUpdateApprovalDirectTaxTotalPayAmount } from "redux/directTaxSlice";
import { fetchAsyncDeleteDirectTaxPaymentListItem } from "redux/directTaxSlice";
import { checkSpentAmountisEmpty } from "jsFunctions/helperFunctions";
import { InputGroup } from "react-bootstrap";
import CurrencyFormat from 'react-currency-format';
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { fetchAsyncGetGstCompanyExpense } from "redux/resourceSlice";
import { fetchAsyncAddGstWithCompanyExpense } from "redux/resourceSlice";
// import { Option } from "antd/es/mentions";

// modal style

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const xl = defineStyle({
  px: "6",
  py: "2",
  fontSize: "xl",
});

const sm = defineStyle({
  fontSize: "sm",
  py: "6",
});

const sizes = {
  xl: definePartsStyle({ header: sm, dialog: xl }),
};

export const modalTheme = defineMultiStyleConfig({
  sizes,
});

const DirectTax = ({
  openModal,
  closeModal,
  isEditForm = false,
  editItem,
  refreshEditData,
  activeList,
  role
}) => {
  const dispatch = useDispatch();
  const [getPoMonth, setGetPoMonth] = useState(moment().format("yyyy-MM"));
  const [generatedNum, setGeneratedNum] = useState("PO-DT-");
  const [isEdit, setIsEdit] = useState(isEditForm)
  const [editData, setEditData] = useState(editItem)
  const [poPrefix, setPoPrefix] = useState({
    prefix: "PO-DT-",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    number: 1,
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [approveOrRejectRemarks, setApproveOrRejectRemarks] = useState('')

  const [isSettled, setIsSettled] = useState(false);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [approvalAmount, setApprovalAmount] = useState('');
  const [sendPayAmountRequest, setSendPayAmountRequest] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState()
  const [totalPayAmountIsApproved, setTotalPayAmountIsApproved] = useState(false);
  const [isGst, setIsGst] = useState(false);
  const [gstAmount , setGstAmount] = useState({
    cgst:'',
    sgst:'',
    igst:''
  })
  const [companyTypeOption, setCompanyTypeOption] = useState([]);
  const [companyTypeValue, setCompanyTypeValue] = useState("");
  const [editGst, setEditGst] = useState(true);

  const [multipleAddPaymentInfo, setMultipleAddPaymentInfo] = useState([]);
  const [multipleInvoiceData, setMultipleInvoiceData] = useState([]);
  const [receiverOption, setReceiverOption] = useState([]);
  const [settledDate, setSettledSate] = useState(new Date());
  const [todayDate, setTodayDate] = useState(new Date());
  const [receiverValue, setReceiverValue] = useState("");
  const [totalTax, setTotalTax] = useState("");

  const [compareDataModal, setCompareDataModal] = useState(false);
  const [comparingData, setComparingData] = useState(null);
  const { addToast } = useToasts();

  const formOptionsList1 = useSelector((state) => state.formOptions);
  const [formOptionsList, setFormOptionsList] = useState([])
  const [remarksValue, setRemarksValue] = useState('');
  const [errors, setErrors] = useState({})
  const finalRef = useRef(null);
  const closeComparedDataModal = () => {
    setCompareDataModal(false);

    setComparingData(null);
  };

  const currUser = useSelector((state) => state.auth.currUser);

  const initialValues = {
    poNo: "",
    orderMonth: "",
    orderDate: "",
    receiverName: "",
    remarks: "",
    totalTax: "",
    payee: 0,
    paymentAmount: 0,
    approvalAmount: 0,
    gstNumber:'',
    bank: "",
    referance: "",
    date: null,
    isSettle: false,
    setteledDate: null,
    invoice: "",
  };

  useEffect(()=>{
    if(formOptionsList1 && formOptionsList1?.length > 0){
      const internalFormOptionList = formOptionsList1.filter((item)=> item.expenseType === 'directTax')
      setFormOptionsList(internalFormOptionList)
    }
  },[formOptionsList1])


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (!receiverValue || !totalTax) {
        setErrors({
          ...errors,
          receiverValue:'Receiver value is required!',
          totalTax:'Total tax is required!'
        })
        return;
      }

     
      const checkSpent = checkSpentAmountisEmpty(
        multipleAddPaymentInfo,
      );
      if (!checkSpent.status) {
        addToast(checkSpent.msg, { appearance: "error" });
        return;
      }
      const checkApproval = checkApprovalAmount(
        approvalAmount,
      );
      if (!checkApproval.status) {
        setErrors({
          ...errors,
          approvalAmount:checkApproval.msg
        })         
        return;
      }
      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        totalPaymentAmount,
        multipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })
        return;
      }
      let formData = new FormData();
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", todayDate);
      formData.append("receiverName", receiverValue.value);
      formData.append("remarks", remarksValue);
      formData.append("isGST", isGst);
      formData.append("gstNumber", values.gstNumber);
      formData.append("gstAmount", JSON.stringify(gstAmount));
      formData.append("companyType", companyTypeValue.value);

      formData.append("totalPaymentAmount", totalPaymentAmount);
      const filteredMultipleAddPaymentInfoPay = multipleAddPaymentInfo.map((item)=>{
        if(item?.invoice){
          return {
            ...item,
            invoice: item?.invoice.filter(entry => 'Key' in entry)
          }
        }
        else{
          return item
        }
      });
      formData.append("payInfo", JSON.stringify(filteredMultipleAddPaymentInfoPay));
      formData.append("isPaymentSettle", isSettled);
      formData.append("setteledDate", settledDate);
      formData.append("totalTax", totalTax);
      formData.append("approvalAmount", approvalAmount);

      if (isEdit) {
        setComparingData(formData);
      } else {
        multipleInvoiceData.forEach((element, i) => {
          formData.append(`file${i}`, element);
        });
        dispatch(fetchAsyncAddDirectTaxExpense(formData))
          .then((res) => {
            if (res?.payload) {
              closeModal();
              resetForm();
              addToast(`Expense successfully created!`, { appearance: "success" });
              dispatch(fetchAsyncGetDirectTaxExpense())
                .then((res) => {})
                .catch((err) => {
                  console.log(err);
                });
            } else {
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
  });

  const handleEditGST = () => {
    setEditGst(false);
  };

  const handleDeleteInvoiceOnEdit = (key,listIndex) => {
    Swal.fire({
      title: "Are you sure? Delete File Permanently.",
      text: `Delete File ${key}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          key: key,
          listIndex:listIndex

        };
        dispatch(fetchAsyncDeleteDirectPdf(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.directTaxExpense?.paymentInfo?.list)

          refreshEditData();
        });
      }
    });
  };
  // f
  // form list option
  useEffect(() => {
    let receiverList = createListForSingleOption(formOptionsList, "receiver");
    let companyTypeList = createListForSingleOption(
      formOptionsList1,
      "company-type"
    );
    setReceiverOption(receiverList);
    setCompanyTypeOption(companyTypeList)

  }, [formOptionsList]);
  // create new function
  const createNewFormOption = (data, type) => {
    dispatch(
      fetchAsyncAddValueToFormOptions({
        label: data.label,
        value: data.value,
        type,
        expenseType:'directTax'
      })
    )
      .then((res) => {
        if (res.payload.value) {
          const setData = {
            label: res.payload.label,
            value: res.payload.value,
          };
          if (type === "receiver") {
            setReceiverValue(setData);
          }
          if (type === "company-type") {
            setCompanyTypeValue(setData);
          }
          Swal.fire({
            title: "Successfully Added",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
              container: "sweet_containerImportant",
              confirmButton: "sweet_confirmbuttonImportant",
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Something went wrong",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          customClass: {
            container: "sweet_containerImportant",
            confirmButton: "sweet_confirmbuttonImportant",
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      });
  };
  // SET PO NUMBER
// add resource with pan
const handleAddCompanyGst = () => {
  if (!companyTypeValue?.value) {
    addToast("Please select a company", { appearance: "error" });
    setEditGst(true);
    return;
  }
  dispatch(
    fetchAsyncAddGstWithCompanyExpense({
      companyName: companyTypeValue?.value,
      companyGst: formik.values.gstNumber,
    })
  ).then((res) => {
    if (res.payload) {
      // setResourcePan(res.payload.resourcePan.resourcePan);
      setEditGst(true);
    }
  });
};

useEffect(() => {
  if (companyTypeValue) {
    dispatch(fetchAsyncGetGstCompanyExpense(companyTypeValue.value)).then(
      (res) => {
        if (res?.payload?.length > 0) {
          formik.setFieldValue("gstNumber", res?.payload?.[0]?.companyGst);
        } else {
          formik.setFieldValue("gstNumber", "");
        }
      }
    );
  }
}, [companyTypeValue]);


  useEffect(() => {
    setGeneratedNum(poNumberFormate(poPrefix));
  }, [poPrefix]);

  useEffect(() => {
    if (!isEdit && getPoMonth) {
      const dataSplit = getPoMonth.split("-");
      dispatch(
        fetchAsyncGetPONumDirectTaxExpense({
          month: dataSplit[1],
          year: dataSplit[0],
        })
      )
        .then((res) => {
          if (res.payload?.[0]?.poNo) {
            setPoPrefix((prev) => {
              return {
                prefix: prev.prefix,
                month: dataSplit[1],
                year: dataSplit[0],
                number: res.payload?.[0]?.poNo.number + 1,
              };
            });
          } else {
            setPoPrefix((prev) => {
              return {
                prefix: prev.prefix,
                month: dataSplit[1],
                year: dataSplit[0],
                number: 1,
              };
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getPoMonth]);
  const deleteFromOption = (option, type) => {
    let data = {};
    if(type === 'company-type'){
      data = formOptionsList1.find(
        (item) => item.value === option.value && item.type === type
      );
    }
    else{

      data = formOptionsList.find(
        (item) => item.value === option.value && item.type === type
      );
    
    }
    dispatch(fetchAsyncDeleteFormOptions(data._id));
  };

  const editFromOption = (option,newValue, type) => {
    let data = {};
    if(type === 'company-type'){
      data = formOptionsList1.find(
        (item) => item.value === option.value && item.type === type
      );
    }
    else{

      data = formOptionsList.find(
        (item) => item.value === option.value && item.type === type
      );
    
    }
    
    dispatch(fetchAsyncEditFormOptions({
      id:data._id,
      newData:{
        optionValue:newValue,
        expenseType:'directTax'

      }
    })).then((res)=>{
      if (res.payload.value) {
        const setData = {
          label: res.payload.label,
          value: res.payload.value,
        };
       
        if (type === "company-type") {
          setCompanyTypeValue(setData);
        }

        if (type === "receiver") {
          setReceiverValue(setData);
        }
       
        dispatch(fetchAsyncGetFormOptions())
      }
    }).catch((err)=>{
      console.log(err)
    })
  };
  const handleInvoiceChange = (data) => {
    // setOpenInvoice(true);
    // if (multipleInvoiceData.length < 10) {
    //   setMultipleInvoiceData([
    //     ...multipleInvoiceData,
    //     { data: "", invNumber: data.data },
    //   ]);
    // }

    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === data?.id) {

        let invoice = []

        if(item?.invoice){
           if (item?.invoice?.length < 5) {
          invoice = [
            ...item?.invoice,
            { data: "", invNumber: data.data }
          ]
        }
        else{
          invoice = item?.invoice
        }
        }
        else{
          invoice = [
            { data: "", invNumber: data.data }
          ]
        }

        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  const handelDeleteInvoice = (index,id) => {
    // const updateList = multipleInvoiceData.filter((item, i) => i !== index);
    // setMultipleInvoiceData([...updateList]);
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === id) {

        let invoice = []
        if(item?.invoice){

          invoice = item?.invoice.filter((item, i) => i !== index)
        }
        
        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  const handleUpdateInvoiceData = (invoiceData, index) => {
    const updateList = multipleInvoiceData.map((item, i) => {
      if (i === index) {
        return invoiceData;
      } else return item;
    });
    setMultipleInvoiceData([...updateList]);
  };

  const addNewInvoiceOnEdit = (file, index,listIndex) => {
    Swal.fire({
      title: "Are you sure? Upload File Directly.",
      text: `Upload - ${file.name}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (editData) {
          const lastInvoiceKey =
            editData.invoice?.length > 0 &&
            editData.invoice[editData.invoice.length - 1].Key;
          let number = index + 1;
          if (lastInvoiceKey) {
            number = lastInvoiceKey.split("PAY_")[1]?.split(".pdf")[0];
            number = parseInt(number) + 1;
          }
          let formData = new FormData();
          formData.append("poNo", JSON.stringify(editData.poNo));
          formData.append("number", number);
          
          formData.append("file", file);
          formData.append("receiverName", editData?.receiverName);
          formData.append("listIndex", listIndex);
          formData.append("index", index);
          const listItem = multipleAddPaymentInfo[listIndex];
          formData.append("listItem", JSON.stringify(listItem));
          dispatch(
            fetchAsyncUploadDirectPdf({ id: editData._id, formData })
          ).then((res) => {
            setMultipleAddPaymentInfo(res?.payload?.directTaxExpense?.paymentInfo?.list)

            refreshEditData();
          });
        }
      }
    });
  };
  // payment info function
  const handleMultiplePayInfo = (data) => {
    setMultipleAddPaymentInfo([
      ...multipleAddPaymentInfo,
      { data: "", paydata: data.data,invoice:[] },
    ]);
  };
  const handleUpdateMultiplePayData = (payData, index) => {
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === index) {
        return {...item,...payData};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  // Changes on Edit
  const editFormPrefiledData = (formOptionsList, editData) => {
    const resourceData = getOptionDataOnEdit(
      formOptionsList,
      editData,
      "receiverName",
      "receiver"
    );
    if (resourceData) {
      setReceiverValue({
        label: resourceData.label,
        value: resourceData.value,
      });
    }
    const companyData = getOptionDataOnEdit(
      formOptionsList1,
      editData.gstApplicable,
      "companyName",
      "company-type"
    );
    if (companyData && !companyTypeValue) {
      setCompanyTypeValue({
        label: companyData.label,
        value: companyData.value,
      });
    }
  };
  const getOptionDataOnEdit = (formOptionsList, editData, value, type) => {
    const data = formOptionsList.find(
      (item) => item.value === editData?.[value] && item.type === type
    );
    return data;
  };

  useEffect(() => {
    // fill data in form on edit
    // console.log("is directtax edit data", editData);
    if (isEdit && editData) {
      setPoPrefix(editData.poNo);
      setTodayDate(new Date(editData.orderDate));
      setSelectedDate(new Date(editData.orderDate));

      setGetPoMonth(
        `${editData.poNo.year}-${
          editData.poNo.month <= 9
            ? "0" + editData.poNo.month
            : editData.poNo.month
        }`
      );
      if (formOptionsList?.length > 0 || formOptionsList1?.length > 0) {
        editFormPrefiledData(formOptionsList, editData);
      }
      if (editData.paymentInfo?.totalPayAmount) {
        setTotalPaymentAmount(editData.paymentInfo?.totalPayAmount);
      }
      if (editData.paymentInfo?.payAmountApproveIsRequest) {
        setSendPayAmountRequest(true)
      }
      
       if(editData?.paymentInfo?.totalPayAmountIsApproved){
        setTotalPayAmountIsApproved(true)
      }
      if (editData?.remarks) {
        setRemarksValue(editData?.remarks)
      }
      if (editData?.totalTax) {
        setTotalTax(editData?.totalTax)
      }
      if (editData?.approveRejectRemarks) {
        setApproveOrRejectRemarks(editData?.approveRejectRemarks)
      }
     
      if (editData.paymentInfo?.approvalAmount) {
        setApprovalAmount(editData.paymentInfo?.approvalAmount);
      }
      
      if (editData.paymentInfo?.list && editData.paymentInfo?.list.length > 0) {
        setMultipleAddPaymentInfo(editData.paymentInfo?.list);
      }
      if (editData.paymentInfo?.isSettle) {
        setIsSettled(editData.paymentInfo.isSettle);
        editData.paymentInfo.setteledDate &&
          setSettledSate(new Date(editData.paymentInfo.setteledDate));
      }

      if (editData?.gstApplicable && editData?.gstApplicable?.isGST) {
        setIsGst(editData?.gstApplicable?.isGST);
      }
     
      if (editData?.gstApplicable && editData?.gstApplicable?.gstAmount) {
        setGstAmount(editData?.gstApplicable?.gstAmount);
      }
     
      if (editData.invoice?.length > 0) {
        setMultipleInvoiceData([...editData.invoice]);
      } else {
        setMultipleInvoiceData([]);
      }
    }
  }, [isEdit, editData, formOptionsList]);



  const submitUpdateData = (formData, editData) => {
    dispatch(
      fetchAsyncUpdateDirectTaxExpense({
        id: editData._id,
        data: formData,
      })
    )
      .then((res) => {
        if (res.payload) {
          addToast(`Expense PoNo: ${generatedNum} updated successfully!`, { appearance: "success" });
          dispatch(fetchAsyncGetDirectTaxExpense());
        }
        closeModal();
        setComparingData(null);
        formik.resetForm();
      })
      .catch((error) => {
        console.log(error);
        addToast("Error", { appearance: "error" });
      });
  };

  // add Remark for Invoice
  const addRemarkForInvoice = (value, item,listIndex,index) => {
    dispatch(
      fetchAsyncAddDirectTaxInvoiceRemark({
        id: editData._id,
        remarkData: { key: item.Key, remark: value, listIndex, index },
      })
    ).then((res) => {
      setMultipleAddPaymentInfo(res?.payload?.directTaxExpense?.paymentInfo?.list)
      refreshEditData();
    });
  };

  const handleAmountApproveBtn = (values)=>{   
    if(!isEdit){
  
      if (!receiverValue || !totalTax) {
        setErrors({
          ...errors,
          receiverValue:'Receiver value is required!',
          totalTax:'Total tax is required!'
        })       
         return;
      }
    }
     
  

    const checkApproval = checkApprovalAmount(
      approvalAmount,
    );
    if (!checkApproval.status) {
      setErrors({
        ...errors,
        approvalAmount:checkApproval.msg
      }) 
      return;
    }
  
    if(approvalAmount <= totalPaymentAmount && remainingAmount >= 0){
  
      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        totalPaymentAmount,
        multipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })   
        return;
      }
    }
    if(remainingAmount < 0 && approvalAmount <= totalPaymentAmount){
      return;
    }
      let formData = new FormData();
      
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", selectedDate);
     
      formData.append("receiverName", receiverValue.value);
     
      formData.append("remarks", remarksValue);

      formData.append("totalPaymentAmount", totalPaymentAmount);
      formData.append("approvalAmount", approvalAmount);
      formData.append("isPaymentSettle", isSettled);
      formData.append("setteledDate", settledDate);
      formData.append("totalTax", totalTax);
      formData.append("isGST", isGst);
      formData.append("gstNumber", values.gstNumber);
      formData.append("gstAmount", JSON.stringify(gstAmount));
      formData.append("companyType", companyTypeValue.value);

      if(isEdit && editData){
        dispatch(fetchAsyncSendRequestUpdateApprovalDirectTaxTotalPayAmount
          ({ 
          id: editData._id,
          data:{newApprovalAmount:approvalAmount}
         })).then(
          (res) => {
            if (res.payload && res.payload.updatedData) {
              setIsEdit(true)
              setEditData(res.payload.updatedData)
              setSendPayAmountRequest(true)
              addToast(`Resend updated request for approval for expense PoNo : ${generatedNum} !`, { appearance: "success" });
              closeModal();
              dispatch(fetchAsyncGetDirectTaxExpense());
            }
            else{
              addToast("Error", { appearance: "error" });
  
            }
  
          
          }
        );
      }
      else{
  
        dispatch(fetchAsyncSendRequestApprovalDirectTaxTotalPayAmount(formData)).then((res) => {
            if (res.payload) {
              addToast(`Send request for approval for expense PoNo : ${generatedNum} !`, { appearance: "success" });
              dispatch(fetchAsyncGetDirectTaxExpense());
              setIsEdit(true)
              setEditData(res.payload)
              setSendPayAmountRequest(true)
              closeModal();
              dispatch(fetchAsyncGetDirectTaxExpense());
  
            
            }
            else{
              addToast("Error", { appearance: "error" });
  
            }
          });
      }
      
      
      }
  const handleTotalPayAmountIsApproved = ()=>{
    const _id = editData._id;
    if(!approveOrRejectRemarks){
      setErrors({
        ...errors,
        approveOrRejectRemarks:'Approve Or Reject Remarks Is Required!'
      })
    }
    else{
    if(!editData.paymentInfo?.totalPayAmountIsApproved){
      Swal.fire({
        title: `Are You Sure? You Want To Approve Amount <span style="color: orange"><span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)}</span> For PoNo: <span style="color:orange">${generatedNum}</span>.`,
        text: `Please confirm to proceed with the approval.`,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Approve",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncApproveDirectTaxTotalPayAmount({ 
            id: _id,
            data:{approvalAmount:approvalAmount,totalPayAmountIsApproved:true,approveRejectRemarks:approveOrRejectRemarks}
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsApproved) {
                addToast(`Requested Amount for PoNo: ${generatedNum} has been successfully approved.`, { appearance: "success" });
                dispatch(fetchAsyncGetDirectTaxExpense());
                closeModal();
              }
              
            }
          );
        }
      });
     
    }
  }
  }

  const handleTotalPayAmountIsRejected = ()=>{
    const _id = editData._id;
    if(!approveOrRejectRemarks){
      setErrors({
        ...errors,
        approveOrRejectRemarks:'Approve Or Reject Remarks Is Required!'
      })
    }
    else{
    if(!editData.paymentInfo?.totalPayAmountIsApproved &&  !editData.paymentInfo?.totalPayAmountIsRejected){
      Swal.fire({
        title: `Are You Sure? You Want To Reject Amount <span style='color:orange'> <span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)} </span> For PoNo: <span style="color:orange">${generatedNum}.</span>`,
        text: `Please confirm to reject. This action cannot be undone.`,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reject",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncRejectDirectTaxTotalPayAmount({ 
            id: _id,
            data:{totalPayAmountIsRejected:true,approveRejectRemarks:approveOrRejectRemarks}
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsRejected) {
                addToast(`Requested Amount for PoNo: ${generatedNum} has been successfully rejected.`, { appearance: "success" });
                dispatch(fetchAsyncGetDirectTaxExpense());
                closeModal();
              }
              
            }
          );
        }
      });
     
    }
  }
  }
  useEffect(()=>{
    if(multipleAddPaymentInfo) {
      const array = multipleAddPaymentInfo;
      if(array.length > 0){

        const sum = array.reduce((acc, current) => {
          if (current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setRemainingAmount(parseFloat(totalPaymentAmount) - parseFloat(sum))
      }



    }
  },[multipleAddPaymentInfo])

  useEffect(()=>{
    if(totalPaymentAmount && !remainingAmount){
      if(multipleAddPaymentInfo) {
        const array = multipleAddPaymentInfo;
        if(array.length > 0){
  
          const sum = array.reduce((acc, current) => {
            if (current?.amount && current?.amount !== '') {
              return acc + parseFloat(current?.amount);
            } else {
              return acc;
            }
          }, 0);
          const total_pay_amount = parseFloat(totalPaymentAmount)
          setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
        }
        else{
          const total_pay_amount = parseFloat(totalPaymentAmount)
          setRemainingAmount(parseFloat(total_pay_amount))
        }
      }

    }
  },[totalPaymentAmount])
  
  const closeModelForm = () => {
    Swal.fire({
      title: "Are you sure you want to close without saving?",
      text: `You will lost all current changes`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        closeModal();
      }
    });
  };


  const deleteMultipleListItem = (listIndex)=>{
    Swal.fire({
      title: "Are you sure? Delete Amount Details Permanently.",
      text: ``,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          listIndex:listIndex
        };
        dispatch(fetchAsyncDeleteDirectTaxPaymentListItem(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.directTaxExpense?.paymentInfo?.list)
          refreshEditData();
        });
      }
    });
}


const handleRemarksChange = (e)=>{
  const value = e?.target?.value;
  const encoder = new TextEncoder(); // Default is 'utf-8'
  const byteLength = encoder.encode(value).length;
  if(byteLength <=128){

    setRemarksValue(value)
    setErrors({
      ...errors,
      remarks:''
    })
  }
  else{
    setErrors({
      ...errors,
      remarks:'Remarks must be within the 128 byte limit!'
    })
  }
}
  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={openModal}
        onClose={closeModelForm}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent className="direct-Tax">
          <div className="fixed-header">
            <ModalHeader className="expense-modal-header">
              {isEdit && "Edit"} Direct Tax{" "}
            </ModalHeader>
            <ModalCloseButton />
          </div>
          <ModalBody>
            <Form className="addForm" onSubmit={formik.handleSubmit}>
              <div className="card1">
                <h5>Direct Tax Details</h5>
                <Row>
                  <Col>
                    <Form.Label>Order Number</Form.Label>
                    <span className="span-star">*</span>
                    <Form.Control
                      type="text"
                      placeholder="No"
                      value={generatedNum && generatedNum}
                      disabled
                    />
                  </Col>

                  <Col>
                    <Form.Label>Order - Month</Form.Label>
                    <span className="span-star">*</span>
                    <DatePicker
                      className="monthDate"
                      dateFormat="MM-yyyy"
                      showMonthYearPicker
                      name="orderMonth"
                      onChange={(e) => {
                        const dateTime = moment(e).format("yyyy-MM");
                        setGetPoMonth(dateTime);
                      }}
                      disabled={isEdit}
                      // defaultValue={new Date()}
                      selected={getPoMonth ? new Date(moment(getPoMonth)) : ""}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Label>Order Date</Form.Label>
                    <span className="span-star">*</span>
                    <DatePicker
                      name="orderDate"
                      className="monthDate"
                      dateFormat="dd-MMM-yyyy"
                      // disabled={isEdit}
                      onChange={(date) => { setSelectedDate(date)}}
                      selected={selectedDate}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </Col>
                  <Col className="custom-select"></Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Label>Receiver Name</Form.Label>
                    <span className="span-star">*</span>
                    <CustomSelect
                      options={receiverOption}
                      placeHolder={<div>Type or select...</div>}
                      onChange={(newValue) => setReceiverValue(newValue)}
                      isSearchable
                      onCreateOption={(e) => {
                        createNewFormOption(createOption(e), "receiver");
                      }}
                      onDeleteOption={(option) => {
                        deleteFromOption(option, "receiver");
                      }}
                      onEditOption={(option,newValue) => {
                        editFromOption(option,newValue, "receiver");
                      }}
                      defaultValue={receiverValue}
                      role = {role}
                    />
                    {errors?.receiverValue  && !receiverValue &&
                    <p className="form_error">{errors?.receiverValue}</p>
                    }
                  </Col>
                  <Col>
                    <Form.Label>Total Tax</Form.Label>
                    <span className="span-star">*</span>
                  <InputGroup>
                    <InputGroup.Text><span className="rupee-symbol">₹</span> </InputGroup.Text>               
                 
                 <CurrencyFormat
                     customInput={Form.Control}
                     type="text"
                    
                     name="totalTax"
                     placeholder="Total Tax"
                     value={totalTax || ''}
                     onValueChange={(e) => {
                      setTotalTax(e.value);
                     }}
                     isInvalid={errors?.totalTax  && !totalTax}

                     thousandSeparator={true}
                     // prefix={"$"}
                     decimalScale={2}
                     fixedDecimalScale={true}

                   />
                  </InputGroup>
                 
                    {errors?.totalTax  && !totalTax &&
                    <p className="form_error">{errors?.totalTax}</p>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Remarks </Form.Label>

                    <Form.Control
                      type="text"
                      name="remarks"
                      placeholder="Type Here..."
                      value={remarksValue}
                      // defaultValue={remarksValue}
                      onChange={(e)=>handleRemarksChange(e)}
                      isInvalid={!!errors?.remarks}
                    />
                  </Col>
                </Row>
              </div>
              <div className="card1">
                <h5>Payment Information</h5>
                <Row>
                  <Col>
                  <Form.Label>Requested Amount</Form.Label>
                  <span className="span-star">*</span>      
                  <InputGroup>
                  <InputGroup.Text><span className="rupee-symbol">₹</span> </InputGroup.Text>               
                 
                    <CurrencyFormat
                        customInput={Form.Control}
                        type="text"
                        disabled={
                          sendPayAmountRequest &&
                          !totalPayAmountIsApproved &&
                          (role === "Admin" || role === "Manager")
                          &&
                          currUser.name !== editData?.paymentInfo?.requestedBy
                        }
                        name="approvalAmount"
                        placeholder="Requested Amount"
                        value={approvalAmount || ''}
                        onValueChange={(e) => {
                          setApprovalAmount(e.value);
                        }}
                        isInvalid={
                          (remainingAmount < 0 &&
                            approvalAmount <= totalPaymentAmount) ||
                          (!approvalAmount && errors?.approvalAmount)
                        }
                        thousandSeparator={true}
                        // prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}

                      />
                    </InputGroup>
                    {((remainingAmount < 0 && approvalAmount <= totalPaymentAmount) || !approvalAmount)&&
                    <p className="form_error">{errors?.approvalAmount}</p>
                    }
                  </Col>
                  {totalPayAmountIsApproved ?
                  <Col>
                    <Form.Label>Approved Amount</Form.Label>
                    <InputGroup>
                    <InputGroup.Text><span className="rupee-symbol">₹</span> </InputGroup.Text>
                    {/* <Form.Control
                      type="number"
                      disabled
                      name="totalPayAmount"
                      placeholder="Total Amount"
                      defaultValue={totalPaymentAmount}
                    /> */}
                    <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          disabled
                          name="totalPayAmount"
                          placeholder="Total Amount"
                          value={totalPaymentAmount ? totalPaymentAmount : 0}
                          thousandSeparator={true}
                          // prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}

                        />
                    </InputGroup>
                  </Col>
                  :
                  <Col></Col>
                  }
                 
                </Row>
                {
                    totalPayAmountIsApproved  &&
                <Row>
                  <Col>
                  <Form.Label>Net Payable Amount</Form.Label>
                  <InputGroup>
                    <InputGroup.Text><span className="rupee-symbol">₹</span> </InputGroup.Text>
                    {/* <Form.Control
                      type="number"
                      disabled
                      name="remainingAmount"
                      placeholder={totalPaymentAmount ? totalPaymentAmount : 0}
                      defaultValue={remainingAmount}
                      isInvalid={remainingAmount < 0 && approvalAmount <= totalPaymentAmount}

                    /> */}
                     <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          disabled
                          name="Net Payable Amount"
                          // placeholder={
                          //   totalPaymentAmount ? totalPaymentAmount : 0
                          // }
                          // defaultValue={remainingAmount}
                          value={remainingAmount ? remainingAmount : 0}
                          thousandSeparator={true}
                          // prefix={"$"}

                          decimalScale={2}
                          isInvalid={
                            remainingAmount < 0 &&
                            approvalAmount <= totalPaymentAmount
                          }
                          fixedDecimalScale={true}

                        />
                    </InputGroup>
                    {(remainingAmount < 0 && approvalAmount <= totalPaymentAmount)&&
                    <p className="form_error">Net payable amount cannot be negative. Please revise the request with an increased requested amount and resubmit for approval.</p>
                    }
                  </Col>
                  <Col>
                    <Form.Label></Form.Label>
                    <div className="horizental-heading">
                      <Button
                        variant="brand"
                        disabled={totalPaymentAmount === null ? true : false}
                        onClick={() => {
                          handleMultiplePayInfo(
                            multipleAddPaymentInfo.length + 1
                          );
                        }}
                        className="add-button mt-1 px-4"
                        size="sm"
                      >
                        Add
                      </Button>
                    </div>
                  </Col>
                </Row>
               }

                {totalPayAmountIsApproved && multipleAddPaymentInfo.map((item, index) => {
                  return (
                    <>
                      <PaymentInfoComponent
                        getData={(data) => {
                          handleUpdateMultiplePayData(data, index);
                        }}
                        autoCount={index}
                        paymentData={item}
                        editData = {editData}
                        refreshEditData = { refreshEditData }
                        isEdit = {isEdit}
                        handleInvoiceChange = {(item)=>handleInvoiceChange(item)}
                        multipleInvoiceData = {item?.invoice ? item?.invoice :[]}
                        handleUpdateInvoiceData = {(item, index)=>handleUpdateInvoiceData(item,index)}
                        handelDeleteInvoice = {(index,id)=>handelDeleteInvoice(index,id)}
                        handleDeleteInvoiceOnEdit = {(key,listIndex)=>handleDeleteInvoiceOnEdit(key,listIndex)}
                        addNewInvoiceOnEdit = {(file,index,listIndex)=>addNewInvoiceOnEdit(file,index,listIndex)}
                        addRemarkForInvoice = {(value,item,listIndex,index)=>addRemarkForInvoice(value,item,listIndex,index)}
                        expenseType='directTax'
                        deleteMultipleListItem = {()=>deleteMultipleListItem(index)}
                        role = {role}

                      />
                    </>
                  );
                })}
               
              </div>
              {totalPayAmountIsApproved &&
              <>
              <div className="card1">
                <h5>GST Applicable</h5>
                <Row>
                  <Col>
                    <Form.Label className="switch-btn">
                      Is GST applicable?
                    </Form.Label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={isGst}
                        onChange={() => setIsGst(!isGst)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </Col>
                </Row>
                {isGst && 
                <>
                <Row>
                <Col className="custom-select">
                    <Form.Label>Company Name</Form.Label>
                    <CustomSelect
                      options={companyTypeOption}
                      placeHolder={<div>Type or select...</div>}
                      onChange={(newValue) => setCompanyTypeValue(newValue)}
                      isSearchable
                      onCreateOption={(e) => {
                        createNewFormOption(
                          createOption(e),
                          "company-type",
                          true
                        );
                      }}
                      onDeleteOption={(option) => {
                        deleteFromOption(option, "company-type");
                      }}
                      onEditOption = {(option,newValue)=>{
                        editFromOption(option,newValue,"company-type")
                      }}
                      defaultValue={companyTypeValue}
                      role = {role}
                    />
                    
                  </Col>
                  <Col>
                    <Form.Label>GST Number</Form.Label>
                    
                    <div className="input_with-edit">
                      <Form.Control
                        type="text"
                        name="gstNumber"
                        placeholder="Company GST Number"
                        disabled={editGst}
                        {...formik.getFieldProps("gstNumber")}
                        isInvalid={formik.errors.gstNumber}
                      />
                      {editGst ? (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                          alt="edit"
                          className="edit-button panEdit"
                          onClick={handleEditGST}
                        />
                      ) : (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                          alt="right"
                          className="edit-button panEdit"
                          onClick={() => {
                            setEditGst(false);
                            handleAddCompanyGst();
                          }}
                        />
                      )}
                    </div>
                    {formik.errors.gstNumber &&
                    <p className="form_error">{formik.errors.gstNumber}</p>
                    }
                  </Col>
                  
                </Row>
                <Row>
                <Col>
                            <Form.Label>IGST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                type="text"
                                onValueChange={(e) => {
                                  setGstAmount({
                                    ...gstAmount,
                                    igst: e?.value,
                                  });
                                }}
                                name="igst"
                                value={gstAmount?.igst || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </InputGroup>
                          </Col>
                          <Col>
                            <Form.Label>SGST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                name="sgst"
                                type="text"
                                onValueChange={(e) => {
                                  setGstAmount({
                                    ...gstAmount,
                                    sgst: e?.value,
                                  });
                                }}
                                value={gstAmount?.sgst || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </InputGroup>
                          </Col>
                          
                          
                        </Row>
                        <Row>
                        <Col>
                            <Form.Label>CGST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                type="text"
                                onValueChange={(e) => {
                                  setGstAmount({
                                    ...gstAmount,
                                    cgst: e?.value,
                                  });
                                }}
                                name="cgst"
                                value={gstAmount?.cgst || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </InputGroup>
                          </Col>
                        <Col>
                            <Form.Label>Total GST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                type="text"
                               
                                name="total_gst"
                                value={(Number(gstAmount?.cgst) + Number(gstAmount?.sgst) + Number(gstAmount?.igst)) || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                </>
                }
                </div>
              </>
              }
               {((role === "Manager" || role === "Admin") && activeList === 'pending' && isEdit  && !totalPayAmountIsApproved && sendPayAmountRequest ) &&
              <>
              <div className="card1">
              <h5>Approve OR Reject Remark</h5>

              <Row>
              <Col>
              <Form.Label>Remarks</Form.Label>
              <span className="span-star">*</span>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Type Here..."
                      value={approveOrRejectRemarks}
                      onChange={(e) => setApproveOrRejectRemarks(e.target.value)}
                      isInvalid={ !approveOrRejectRemarks && errors?.approveOrRejectRemarks}

                      />
                   
                      { !approveOrRejectRemarks &&
                      <p className="form_error">{errors?.approveOrRejectRemarks}</p>
                      }
                  </Col>
              </Row>  

              </div>
              </>
              }
              <Row>
              {((role === "Manager" || role === "Admin") && activeList === 'pending' && isEdit  && !totalPayAmountIsApproved && sendPayAmountRequest ) ?
                <ModalFooter style={{alignItems:'center',justifyContent:'center'}}>
                  
                {
                  (!totalPayAmountIsApproved) &&
                  <div className="d-flex"> 
                    <Button
                      variant="brand"
                      disabled={totalPaymentAmount === null ? true : false}
                      onClick={() => {
                        handleTotalPayAmountIsApproved();
                      }}
                      className="submit-btn me-2"
                    >
                      Approve
                    </Button>
                    
                    <Button
                  className="submit-btn"
                  variant="brand"
                  disabled={totalPaymentAmount === null ? true : false}
  
                  onClick={() => handleTotalPayAmountIsRejected()}
                  style={{ background: "#191063" }}
                >
                  Reject
                </Button>
                  </div>
              
                }
              </ModalFooter>
              :
                <ModalFooter>
                  {
                    ((!totalPayAmountIsApproved && !sendPayAmountRequest) || remainingAmount < 0) ?
                
                      <Button
                        variant="brand"
                        disabled={totalPaymentAmount === null ? true : false}
                        onClick={() => {
                          handleAmountApproveBtn(formik.values);
                        }}
                        className="submit-btn"
                      >
                        Send Request
                      </Button>
                      :
                  <Button
                    type="submit"
                    className="submit-btn"
                    variant="brand"
                    style={{ background: "#191063" }}
                    onClick={() => setCompareDataModal(true)}
                  >
                    Submit
                  </Button>
                }

                </ModalFooter>
              }
              </Row>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
      {compareDataModal && comparingData && (
        <DataChangeModalOnEdit
          openModal={compareDataModal}
          closeModal={closeComparedDataModal}
          editData={editData}
          currentData={comparingData}
          isConfirmed={() => {
            submitUpdateData(comparingData, editData);
          }}
          type={"direct-tax"}
        />
      )}
      ;
    </>
  );
};

export default DirectTax;
