/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import { Box, Button, Tooltip } from "@chakra-ui/react";

// import noDataFound from "../../assets/img/no_data_found.png";
import ColumnTable from "./embrillTable/ColumnTable";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchAsyncDeleteUser } from "redux/userSlice";
import { fetchAsyncGetUser } from "redux/userSlice";
import moment from "moment";
import UserForm from "components/allForm/UserForm";

const Users = () => {
  const [addNewModal, setAddNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [userEditData, setUserEditData] = useState(null);

  const [userListData, setUserListData] = useState([]);
  const currUser = useSelector((state) => state.auth.currUser);
  const [role,setRole] = useState('')  

  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Users');
      if(roleObj){

        setRole(roleObj?.access)
      }
    }
   
  },[currUser])

  const columnsDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "Full Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    // {
    //   Header: "Role",
    //   accessor: "role",
    // },
    {
      Header: "Last Modified",
      accessor: "updatedAt",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const userList = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    setAddNewModal(true);
  };
  const handleModalClose = () => {
    setAddNewModal(false);
    setOpenEditModal(false);
    setUserEditData(null);
  };

  useEffect(() => {
    dispatch(fetchAsyncGetUser());
  }, []);

  useEffect(() => {
    if (userList && userList.length > 0) {
      const tableData = userList.map((data, index) => {
        return {
          no: index + 1,
          name: data.name,
          email: data.email,
          role: data.role,
          updatedAt: moment(data.updatedAt).format("DD-MM-YYYY hh:mm a"),
          action: (
            <div className="d-flex">
              <AiFillEdit
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditModal(true);
                  setUserEditData(data);
                  handleModalOpen();
                }}
              />
              {role === "Admin" && (
                <AiFillDelete
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure? you want to delete user <span style="color: #d33">Name : ${data?.name && data?.name}</span>.`,
                      text:'This action cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteUserData(data);
                      }
                    });
                  }}
                />
              )}
            </div>
          ),
        };
      });
      setUserListData(tableData);
    } else {
      setUserListData([]);
    }
  }, [userList]);

  const deleteUserData = (data) => {
    dispatch(fetchAsyncDeleteUser(data._id)).then(() => {
      handlerRefreshData();
    });
  };

  const handlerRefreshData = () => {
    dispatch(fetchAsyncGetUser());
  };

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <div className="button-div">
        <Tooltip label='Add New' fontSize='md' borderRadius='2xl'>

          <Button className="btn add-btn" onClick={handleModalOpen}>
            
            <img
              src="https://cdn-icons-png.flaticon.com/128/148/148764.png"
              alt="add"
              style={{ width: "30px", height: "30px", margin: "9px" }}
            />
          </Button>
        </Tooltip>
        </div>
        {/* <div className="notFound">
          <img src={noDataFound} alt="no-data-found" /> 
        </div> */}
      </Box>
      <Box pt={{ base: "180px", md: "120px", xl: "120px" }}>
        <ColumnTable
          columnsData={columnsDataColumns}
          tableData={userListData}
          type={"User List"}
          role={role}
        />
      </Box>
      {addNewModal && (
        <UserForm
          openModel={addNewModal}
          closeModel={handleModalClose}
          isEdit={openEditModal}
          userData={userEditData}
          refreshList={handlerRefreshData}
          role={role}

        />
      )}
    </>
  );
};

export default Users;
