import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { authSlice } from "./slices/Auth";
import { dateRangeSlice } from "./slices/dateRangeSlice";
import storage from "redux-persist/lib/storage";
import externalSlice from "./externalSlice/index";
import internalSlice from "./internalSlice/index";
import resourceSlice from "./resourceSlice/index";
import directTaxSlice from "./directTaxSlice/index";
import tdsSlice from "./tdsSlice/index";
import formOptionsSlice from "./formOptions/index";
import userSlice from "./userSlice/index";

import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import subCategorySlice from "./subCategorySlice";
import gstSlice from "./slices/gstSlice";

const persistConfig = {
  key: "internal",
  storage,
};

const reducers = combineReducers({
  auth: authSlice.reducer,
  dateRange: dateRangeSlice.reducer,
  externalData: externalSlice,
  internalData: internalSlice,
  resourceData: resourceSlice,
  directTaxData: directTaxSlice,
  tdsData: tdsSlice,
  formOptions: formOptionsSlice,
  subCategory: subCategorySlice,
  userData: userSlice,
  gstData: gstSlice
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false,
    }),
});
