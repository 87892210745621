import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
 
  Tooltip
} from "@chakra-ui/react";
import TdsAddNew from "components/allForm/tdsAddNew";
import ColumnTable from "./embrillTable/ColumnTable";
import { columnsDataColumns,rejectedDataColumns } from "./embrillTable/variables/tdsColData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncGetTDSExpense } from "redux/tdsSlice";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import Swal from "sweetalert2";
import { fetchAsyncDeleteTdsExpense } from "redux/tdsSlice";
import PdfListModel from "components/models/PdfListModel";
import { fetchAsyncDeleteTdsPdf } from "redux/tdsSlice";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { currencyFormate } from "jsFunctions/helperFunctions";
import ExportXLSFile from "components/ExportXLSFile";
import moment from "moment";
import TdsInfoModel from "components/models/TdsInfoModel";
import ExpenseReports from "./expenseGraph";
import { TbReceiptTax } from "react-icons/tb";
import { CiViewTable } from "react-icons/ci";
import { PiGraph } from "react-icons/pi";
import { MdOutlinePendingActions } from "react-icons/md";
import { FcApproval } from "react-icons/fc";
import { RxCrossCircled } from "react-icons/rx";
import ExportDataModel from "components/exportData/ExportDataModel";

const EmbrillTds = () => {
  const [openTdsModal, setOpneTdsModal] = useState(false);
  const [tdsData, setTdsData] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [tdsEditData, setTdsEditData] = useState(null);

  const [tdsListData, setTdsListData] = useState([]);

  const [tdsPdfList, setTDSPdfList] = useState(null);
  const [showPdfList, setShowPdfList] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [activeDataList,setActiveDataList] = useState('approved')
  const [invoiceData,setInvoiceData] = useState([])
  const [activeGraph,setActiveGraph] = useState(false)
  const [openTdsInfoModel, setOpenTdsInfoModel] = useState(false);
  const dispatch = useDispatch();
  const tdsList = useSelector((state) => state.tdsData.tdsExpense);
  const currUser = useSelector((state) => state.auth.currUser);

  const [role,setRole] = useState('')  

  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Tax Deducted at Source (TDS)');
      if(roleObj){

        setRole(roleObj?.access)
      }
    }
   
  },[currUser])

  const deleteTdsData = (data) => {
    dispatch(fetchAsyncDeleteTdsExpense(data._id)).then(() => {
      handlerRefreshData();
    });
  };
  const handlerRefreshData = () => {
    dispatch(fetchAsyncGetTDSExpense());
  };

  const handleCloseTdsModal = () => {
    setOpneTdsModal(false);
    setOpenEditModal(false);
    setTdsEditData(null);
  };
  useEffect(() => {
    dispatch(fetchAsyncGetTDSExpense());
  }, []);
  const handleOpenTdsModal = () => {
    setOpneTdsModal(true);
  };
  
  const getAllApprovedData = ()=>{
    if (tdsList && tdsList.length > 0) {
      let listData = [];
      if(role !== "Accountant")
        {
          listData = tdsList.filter((item)=> item.paymentInfo.totalPayAmountIsApproved === true)
  
        } else {
  
          listData = tdsList.filter((item)=> item.paymentInfo.totalPayAmountIsApproved === true && currUser.name === item?.paymentInfo?.requestedBy)
        }
      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item && item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        return {
          _id:data._id,
          no: index + 1,
          poNo: data.poNo && poNumberFormate(data.poNo),
          orderdate: dateTimeFormat(data.orderDate),
          resourcename: data.resourceName,
          totalAmount: data?.totalBillingAmount ? currencyFormate(data?.totalBillingAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />,
          approvalAmount: (
            <span
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ), 
          invoice:
          foundInvoiceWithLength ? (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowPdfList(true);
                  setTDSPdfList(data);
                }}
              />
            ) : (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "not-allowed" }}
                color="gray"
              />
            ),
          action: (
            <div className="d-flex">
              <AiFillEdit
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditModal(true);
                  setTdsEditData(data);
                  handleOpenTdsModal();
                }}
              />
              {role === "Admin" && (
                <AiFillDelete
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                      text:'This action cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteTdsData(data);
                      }
                    });
                  }}
                />
              )}
            </div>
          ),
        };
      });
      setTdsListData(tableData);
      if (tdsPdfList) {
        const dataGet = tdsList.filter((item) => item._id === tdsPdfList._id);
        setTDSPdfList(dataGet[0]);
      }
      if (tdsEditData) {
        const dataGet = tdsList.filter((item) => item._id === tdsEditData._id);
        setTdsEditData(dataGet[0]);
      }
    } else {
      setTdsListData([]);
    }
  }

  const getPendingApproveData = ()=>{
    if (tdsList && tdsList.length > 0) {
      let listData = []
      if(role !== "Accountant")
      {
        listData = tdsList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === false))


      } else {
        listData = tdsList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === false && currUser.name === item?.paymentInfo?.requestedBy))
       
      }

      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item && item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        return {
          _id:data._id,
          no: index + 1,
          poNo: data.poNo && poNumberFormate(data.poNo),
          orderdate: dateTimeFormat(data.orderDate),
          resourcename: data.resourceName,
          totalAmount: data?.totalBillingAmount ? currencyFormate(data?.totalBillingAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />,

          approvalAmount: (
            <span
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ), 
          invoice:
          foundInvoiceWithLength ? (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowPdfList(true);
                  setTDSPdfList(data);
                }}
              />
            ) : (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "not-allowed" }}
                color="gray"
              />
            ),
          action: (
            <div className="d-flex">
              <AiFillEdit
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditModal(true);
                  setTdsEditData(data);
                  handleOpenTdsModal();
                }}
              />
              {role === "Admin" && (
                <AiFillDelete
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                      text:'This action cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteTdsData(data);
                      }
                    });
                  }}
                />
              )}
            </div>
          ),
        };
      });
      setTdsListData(tableData);
      if (tdsPdfList) {
        const dataGet = tdsList.filter((item) => item._id === tdsPdfList._id);
        setTDSPdfList(dataGet[0]);
      }
      if (tdsEditData) {
        const dataGet = tdsList.filter((item) => item._id === tdsEditData._id);
        setTdsEditData(dataGet[0]);
      }
    } else {
      setTdsListData([]);
    }
  }
  const getRejectedData = ()=>{
    if (tdsList && tdsList.length > 0) {
      let listData = []
      if(role !== "Accountant")
      {
        listData = tdsList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === true))

      } else {

        listData = tdsList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === true && currUser?.name === item?.paymentInfo?.requestedBy))
      }

      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item && item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        console.log(foundInvoiceWithLength)
        return {
          _id:data._id,
          no: index + 1,
          poNo: data.poNo && poNumberFormate(data.poNo),
          orderdate: dateTimeFormat(data.orderDate),
          resourcename: data.resourceName,
          totalAmount: data?.totalBillingAmount ? currencyFormate(data?.totalBillingAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />,

          approvalAmount: (
            <span
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ), 
          status:'Rejected',
          action: (
            <div className="d-flex">
            
              {
              role === "Admin" ? (
                <AiFillDelete
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  Swal.fire({
                    title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                    text:'This action cannot be undone.',
                    icon: "warning",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Delete",
                    showCancelButton: true,
                    customClass: {
                      container: "sweet_containerImportant",
                      confirmButton: "sweet_confirmbuttonImportant",
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteTdsData(data);
                    }
                  });
                }}
              />
              )
              :
              'Not Access'
            }
            </div>
          ),                               

         
        };
      });
      setTdsListData(tableData);
      if (tdsPdfList) {
        const dataGet = tdsList.filter((item) => item._id === tdsPdfList._id);
        setTDSPdfList(dataGet[0]);
      }
      if (tdsEditData) {
        const dataGet = tdsList.filter((item) => item._id === tdsEditData._id);
        setTdsEditData(dataGet[0]);
      }
    } else {
      setTdsListData([]);
    }
  }
  useEffect(() => {
    if(activeDataList === 'approved'){
      getAllApprovedData();
     }
     else if(activeDataList === 'pending'){

      getPendingApproveData();
    }
    else{
     getRejectedData();
    }
  }, [tdsList]);

  useEffect(()=>{
    handlerRefreshData();

  },[activeDataList])

  const handleGraph = ()=>{
    setActiveGraph(true)
  }
  const handleTable = ()=>{
    setActiveGraph(false)
  }

  const handleRowClick = (e,_id)=>{
    e.stopPropagation();
    const data = tdsList.filter((item)=> item?._id === _id)
    setSelectedItem(data[0]);
    setOpenTdsInfoModel(true);
  }

  const closeTdsInfoModel = () => {
    setOpenTdsInfoModel(false);
    setSelectedItem({});
  };

  useEffect(()=>{
    if (tdsPdfList) {
      const dataGet = tdsList.filter(
        (item) => item._id === tdsPdfList._id
      );
      const data = dataGet[0];
      const paymentList = data?.paymentInfo?.list
      let invoices = []
      if(paymentList?.length > 0){
        invoices = paymentList?.flatMap((item, index) => {
          if (item?.invoice) {
            return item.invoice.map(invoice => ({...invoice, listIndex: index }));
          }
          return []; // or return an empty array to avoid undefined values
        });
      }

      setInvoiceData(invoices)
      setTDSPdfList(dataGet[0]);
    }
  },[tdsPdfList])

  const deleteFileFromList = (sendData) => {
    dispatch(fetchAsyncDeleteTdsPdf(sendData)).then((response) => {
      handlerRefreshData();
    });
  };
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <div className="button-div">
        <Tooltip label='Rejected' fontSize='md' borderRadius='2xl'>
          <Button className="add-btn" onClick={()=>setActiveDataList('rejected')}>
            <RxCrossCircled size={30} color="red" />
          </Button>
          </Tooltip>
          {tdsList  && (
       
         <ExportDataModel
         filename={"TDS_"}
         type = "tds"
         role = {role}
        />
          )}

          <Tooltip label='Add New' fontSize='md' borderRadius='2xl'>
          <Button className="btn add-btn" onClick={handleOpenTdsModal}>
          
            <img
              src="https://cdn-icons-png.flaticon.com/128/148/148764.png"
              alt="add"
              style={{ width: "30px", height: "30px", margin: "9px" }}
            />
          </Button>
          </Tooltip>  
          
          
        </div>
        {
        <div className={`button-div list-btn ${activeGraph ? 'list-btn-disabled' : ''}`}>
          {/* {activeDataList === 'pending' ? */}
          <Tooltip label='Approved Data' fontSize='md' borderRadius='2xl'> 
          <Button className={`btn ${activeDataList !== 'pending' ? 'list-btn-active':''}`} onClick={()=>setActiveDataList('approved')}  disabled={activeGraph}>
             <FcApproval size={30}/>
            
            
          </Button>
          </Tooltip>
            
          {/* : */}

          <Tooltip label='Pending List' fontSize='md' borderRadius='2xl'>

          <Button className={`btn ${activeDataList === 'pending' ? 'list-btn-active':''}`} onClick={()=>setActiveDataList('pending')} disabled={activeGraph}>
             <MdOutlinePendingActions size={30}  />          
          </Button>
          </Tooltip>
{/* } */}
        </div>
        }
      </Box>
      <div 
        className="graph_button button-div tds-graph-btn"        
       >
        <div  className={`btn graph_icon_button ${!activeGraph ? 'active_graph' : ''}`}  onClick={()=>handleTable()}><CiViewTable  /></div>
        {role !== 'Accountant' ?
        <div  className={`btn graph_icon_button ${activeGraph ? 'active_graph' : ''}`} onClick={()=>handleGraph()}><PiGraph/></div>
        :
        <div  className={`btn graph_icon_button ${activeGraph ? 'active_graph' : ''}`} style={{opacity:'0.3',cursor:'not-allowed'}}><PiGraph/></div>
        }
        
       </div>
      <Box pt={{ base: "180px", md: "120px", xl: "120px" }}>
      {activeGraph ?
      <Box className={`graph-card ${activeGraph ? 'graph_active' : ''}`}>
          
      <ExpenseReports
        type='tds'
        name = 'TDS Total Expense'
        heading = "Tax Deducted at Source (TDS)"
        icon = {TbReceiptTax}
        role={role}
      /> 
      </Box>
      :
        <ColumnTable
        columnsData = {activeDataList === 'rejected' ?rejectedDataColumns :columnsDataColumns}           
        tableData={tdsListData}
          type={"Tax Deducted at Source (TDS)"}
          expenseType = 'tds'

          getAllData={(data) => {
            setTdsData(data);
            handleOpenTdsModal();
          }}
          openInfoModal = {handleRowClick}
          activeDataList = {activeDataList}
          role={role}
        />
      }
      </Box>
      {openTdsModal && (
        <TdsAddNew
          tdsOpen={openTdsModal}
          tdsClose={handleCloseTdsModal}
          tdsExpenseData={tdsData}
          isEditForm={openEditModal}
          editItem={tdsEditData}
          refreshEditData={handlerRefreshData}
          activeList = {activeDataList}
          role={role}

        />
      )}
      <PdfListModel
          pdfData={tdsPdfList ? invoiceData : []}
          currentId={tdsPdfList ? tdsPdfList._id : ""}
        openModal={showPdfList}
        closeModal={() => {
          setShowPdfList(false);
          setTDSPdfList(null);
        }}
        deleteFileFromList={deleteFileFromList}
        role={role}
      />
      {openTdsInfoModel && (
        <TdsInfoModel
          openInfoModal={openTdsInfoModel}
          closeInfoModal={closeTdsInfoModel}
          selectedData={selectedItem} 
          role={role}       
        />
      )}
    </>
  );
};

export default EmbrillTds;
