import React from "react";
import ReactApexChart from "react-apexcharts";


// Utility function to format numbers with commas
const formatNumber = (value) => {
  return value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

class PieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
      chartOptions: {
        tooltip: {
          y: {
            formatter: function (value) {
              return `₹ ${formatNumber((value))}`; // Format the tooltip with INR symbol and commas
            }
          }
        },
      },
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions,
    });
  }
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.chartData !== this.state.chartData) {
      this.setState({ chartData: nextProps.chartData });
    }
    if (nextProps.chartOptions !== this.state.chartOptions) {
      this.setState({ chartOptions: nextProps.chartOptions });
    }
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="pie"
        width="100%"
        height="70%"
      />
    );
  }
}

export default PieChart;
