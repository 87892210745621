import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddTDSExpense = createAsyncThunk(
  "/fetchAsyncAddTDSExpense",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/tds/create`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncGetPONumTDSExpense = createAsyncThunk(
  "/fetchAsyncGetPONumTDSExpense",
  async (data) => {
    try {
      let url = `${API_URL}/tds/getPoNum`;
      const response = await axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteTdsPdf = createAsyncThunk(
  "/fetchAsyncDeleteTdsPdf",
  async (data) => {
    try {
      let url = `${API_URL}/tds/delete_pdf/${data.id}`;
      const response = await axiosInstance.post(url,{ key: data.key,listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncUploadTdsPdf = createAsyncThunk(
  "/fetchAsyncUploadTdsPdf",
  async (data) => {
    try {
      let url = `${API_URL}/tds/upload_pdf/${data.id}`;
      const response = await axiosInstance.post(url, data.formData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);
export const fetchAsyncUpdateTdsExpense = createAsyncThunk(
  "/fetchAsyncUpdateTdsExpense",
  async (data) => {
    try {
      const response = await axiosInstance.put(`${API_URL}/tds/update/${data.id}`, data.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncResourcePONum = createAsyncThunk(
  "/fetchAsyncResourcePONum",
  async (data) => {
    try {
      let url = `${API_URL}/tds/resourcePOnum`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteTdsExpense = createAsyncThunk(
  "/fetchAsyncDeleteTdsExpense",
  async (id) => {
    try {
      let url = `${API_URL}/tds/delete/${id}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncGetTDSExpense = createAsyncThunk(
  "/fetchAsyncGetTDSExpense",
  async () => {
    try {
      let url = `${API_URL}/tds/get`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetTDSExpenseByRefrencePO = createAsyncThunk(
  "/fetchAsyncGetTDSExpenseByRefrencePO",
  async (resourcePoId) => {
    try {
      let url = `${API_URL}/tds/get-by-refrence-po/${resourcePoId}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetByReferencePoDetails = createAsyncThunk(
  "/fetchAsyncGetByReferencePoDetails",
  async (resourcePoId) => {
    try {
      let url = `${API_URL}/tds/get-by-reference-po-details/${resourcePoId}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncAddTDSInvoiceRemark = createAsyncThunk(
  "/fetchAsyncAddTDSInvoiceRemark",
  async (data) => {
    try {
      let url = `${API_URL}/tds/invoice-remark/${data.id}`;
      const response = await axiosInstance.put(url, data.remarkData);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncSearchTdsData = createAsyncThunk(
  "/fetchAsyncSearchTdsData",
  async (data) => {
    try {
      let url = `${API_URL}/tds/search`;
      if (data.value) {
        url = url + `?search=${data.value}`;
      }
      if (data.startDate && data.endDate && data.value) {
        url = url + `&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      if (data.startDate && data.endDate && !data.value) {
        url = url + `?startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

// Dashboard Api
export const fetchAsyncGetTDSTotalPaymentAmount = createAsyncThunk(
  "/fetchAsyncGetTDSTotalPaymentAmount",
  async () => {
    try {
      let url = `${API_URL}/tds/graph-totalAmount`;
      const response = await axiosInstance.get(url);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncApproveTdsTotalPayAmount = createAsyncThunk(
  "/fetchAsyncApproveTdsTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/tds/approve-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncSendRequestApprovalTdsTotalPayAmount = createAsyncThunk(
  "/fetchAsyncSendRequestApprovalTdsTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/tds/send-request-approve-total-pay-amount`;
      const response = await axiosInstance.post(url, data);
     
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncSendRequestUpdateApprovalTdsTotalPayAmount = createAsyncThunk(
  "/fetchAsyncSendRequestUpdateApprovalTdsTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/tds/send-request-update-approve-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncRejectTdsTotalPayAmount = createAsyncThunk(
  "/fetchAsyncRejectTdsTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/tds/reject-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncGetTDSExpenseBarChart = createAsyncThunk(
  "/fetchAsyncGetTDSExpenseBarChart",
  async (data) => {
    try {
      let url = `${API_URL}/tds/tdsFinencialYearData`;
      const response = await axiosInstance.post(url,data);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error("Token Expire Please Login again!");
      } else {
        throw new Error(error.response.data.message);
      }
    }
  }
);

export const fetchAsyncDeleteTdsPaymentListItem = createAsyncThunk(
  "/fetchAsyncDeleteTdsPaymentListItem",
  async (data) => {
    try {
      let url = `${API_URL}/tds/delete-tds-payment-list-item/${data.id}`;
      const response = await axiosInstance.post( url,{listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncTdsXlsxData = createAsyncThunk(
  "/fetchAsyncTdsXlsxData",
  async (data) => {
    try {
      let url = `${API_URL}/tds/tds-xlsx`;
     
      
      if (data?.role && data.startDate && data.endDate) {
        url = url + `?role=${data.role}&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

const AllTDSSlice = createSlice({
  name: "tdsData",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncGetTDSExpense.pending]: (state) => {},
    [fetchAsyncGetTDSExpense.fulfilled]: (state, { payload }) => {
      return payload;
    },
    [fetchAsyncSearchTdsData.pending]: (state) => {},
    [fetchAsyncSearchTdsData.fulfilled]: (state, { payload }) => {
      return payload;
    },
  },
});

export const getAllTDSExpense = (state) => state.tdsData;

export default AllTDSSlice.reducer;
