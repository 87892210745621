import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: false,
    token: "",
    currUser: {},
  },
  reducers: {
    setToken: (state, { payload }) => {
      return {
        isAuth: true,
        // token: payload.token,
        currUser: payload.currUser,
      };
    },
    tokenLogin: (state) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        return {
          isAuth: true,
          token: token,
        };
      } else {
        return {
          isAuth: false,
          token: "",
          currUser: {},
        };
      }
    },
    clearToken: (state) => {
      localStorage.removeItem("persist:authToken");
      return {
        isAuth: false,
        token: "",
        currUser: {},
      };
    },
  },
});

export const { setToken, tokenLogin, clearToken } = authSlice.actions;
