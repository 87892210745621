import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";

export const getGstExcelData = (tdsList) => {
    let list = JSON.parse(JSON.stringify(tdsList));
    const exportData = list.map((data, index) => {
      let content = {
        no: index + 1,
        "Order PO Number": data.poNo && poNumberFormate(data.poNo),

        "Current Order Date":
          data.orderDate && moment(data.orderDate).format("DD-MMM-YYYY"),
          "Current Order Month": data.orderMonth && data.orderMonth,

        "Category": data.category,
        "Company Name":  data?.gstFields?.companyName,
        "Company GST Number":  data?.gstFields?.gstNumber,

        "IGST Amount": `₹ ${data?.gstFields?.gstAmount?.igst  ? formatAmountIndianRupees(data?.gstFields?.gstAmount?.igst) : Number(0).toFixed(2)}`,
        "SGTS Amount": `₹ ${data?.gstFields?.gstAmount?.sgst  ? formatAmountIndianRupees(data?.gstFields?.gstAmount?.sgst) : Number(0).toFixed(2)}`,
        "CGST Amount": `₹ ${data?.gstFields?.gstAmount?.cgst  ? formatAmountIndianRupees(data?.gstFields?.gstAmount?.cgst) : Number(0).toFixed(2)}`,
        "Total GST Amount": `₹ ${data?.gstFields?.totalGst  ? formatAmountIndianRupees(data?.gstFields?.totalGst) : Number(0).toFixed(2)}`,

      };

      return content;
    });
    return exportData;
  };