import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddValueToFormOptions = createAsyncThunk(
  "/fetchAsyncAddDirectTaxExpense",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/formOption/create`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetFormOptions = createAsyncThunk(
  "/fetchAsyncGetFormOptions",
  async () => {
    try {
      let url = `${API_URL}/formOption/get`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteFormOptions = createAsyncThunk(
  "/fetchAsyncDeleteFormOptions",
  async (id) => {
    try {
      let url = `${API_URL}/formOption/delete/${id}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncEditFormOptions = createAsyncThunk(
  "/fetchAsyncEditFormOptions",
  async (data) => {
    try {
      const response = await axiosInstance.put(`${API_URL}/formOption/edit/${data?.id}`, data?.newData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncCreateSubCategory = createAsyncThunk(
  "/fetchAsyncCreateSubCategory",
  async ({ id, category,expenseType }) => {
    try {
      let url = `${API_URL}/formOption/create-sub-category/${id}`;
      const response = await axiosInstance.post(url,{ category,expenseType });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetSubCategory = createAsyncThunk(
  "/fetchAsyncGetSubCategory",
  async (value) => {
    try {
      let url = `${API_URL}/formOption/sub-category/${value}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteSubCategory = createAsyncThunk(
  "/fetchAsyncDeleteSubCategory",
  async (id) => {
    try {
      let url = `${API_URL}/formOption/delete-sub-category/${id}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncEditSubCategory = createAsyncThunk(
  "/fetchAsyncEditSubCategory",
  async (data) => {
    try {
      const response = await axiosInstance.put(`${API_URL}/formOption/edit-sub-category/${data?.id}`, data?.newData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncCreateMinorSubCategory = createAsyncThunk(
  "/fetchAsyncCreateMinorSubCategory",
  async ({ id, category,expenseType }) => {
    try {
      let url = `${API_URL}/formOption/create-minor-sub-category/${id}`;
      const response = await axiosInstance.post( url,{ category,expenseType });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetMinorSubCategory = createAsyncThunk(
  "/fetchAsyncGetMinorSubCategory",
  async (value) => {
    try {
      let url = `${API_URL}/formOption/minor-sub-category/${value}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteMinorSubCategory = createAsyncThunk(
  "/fetchAsyncDeleteMinorSubCategory",
  async (id) => {
    try {
      let url = `${API_URL}/formOption/delete-minor-sub-category/${id}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncEditMinorSubCategory = createAsyncThunk(
  "/fetchAsyncEditMinorSubCategory",
  async (data) => {
    try {
      const response = await axiosInstance.put(`${API_URL}/formOption/edit-minor-sub-category/${data?.id}`, data?.newData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
const formOptionsSlice = createSlice({
  name: "formOptions",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncGetFormOptions.pending]: (state) => {},
    [fetchAsyncGetFormOptions.fulfilled]: (state, { payload }) => {
      return payload;
    },
    [fetchAsyncAddValueToFormOptions.pending]: (state) => {},
    [fetchAsyncAddValueToFormOptions.fulfilled]: (state, { payload }) => {
      state = [...state, payload];
      return state;
    },
    [fetchAsyncDeleteFormOptions.pending]: (state) => {},
    [fetchAsyncDeleteFormOptions.fulfilled]: (state, { payload }) => {
      state = state.filter((item) => item._id !== payload._id);
      return state;
    },
  },
});

export const getAllFormOptions = (state) => state.formOptions;

export default formOptionsSlice.reducer;

