import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   value: [{ startDate: new Date(), endDate: new Date() }],
// };
export const dateRangeSlice = createSlice({
  name: "dateRange",
  initialState: {
    startDate: "",
    endDate: "",
  },
  reducers: {
    setDateRangeGlobal: (state, { payload }) => {
      return {
        startDate: payload.startDate,
        endDate: payload.endDate,
      };
    },
  },
});
export const { setDateRangeGlobal } = dateRangeSlice.actions;
