import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncGetAllGst = createAsyncThunk(
  "/fetchAsyncGetAllGst",
  async () => {
    try {
      let url = `${API_URL}/gst/get-all-gst`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGstXlsxData = createAsyncThunk(
  "/fetchAsyncGstXlsxData",
  async (data) => {
    try {
      let url = `${API_URL}/gst/gst-xlsx`;
     
      
      if (data.startDate && data.endDate) {
        url = url + `?startDate=${data.startDate}&endDate=${data.endDate}`;
      }
    
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncSearchGstData = createAsyncThunk(
  "/fetchAsyncSearchGstData",
  async (data) => {
    try {
      let url = `${API_URL}/gst/search`;
      if (data.value) {
        url = url + `?search=${data.value}`;
      }
      if (data.startDate && data.endDate && data.value) {
        url = url + `&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      if (data.startDate && data.endDate && !data.value) {
        url = url + `?startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);
const AllGstSlice = createSlice({
  name: "gstData",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncGetAllGst.pending]: (state) => {},
    [fetchAsyncGetAllGst.fulfilled]: (state, { payload }) => {
      return payload?.gstData;
    },
    [fetchAsyncSearchGstData.pending]: (state) => {},
    [fetchAsyncSearchGstData.fulfilled]: (state, { payload }) => {
      return payload?.gstData;
    },
  },
});

export const getAllGst = (state) => state.gstData;

export default AllGstSlice.reducer;
