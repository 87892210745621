export const formatAmountInINR = (amount) => {
  // Ensure the input is a number
  if (typeof amount !== "number") {
    // throw new Error("Input must be a number");
    return amount;
  }

  // Use toLocaleString to format the number with commas
  const formattedAmount = amount.toLocaleString("en-IN", {
    style: "decimal",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const html = `<span style="font-family: sans-serif" ">₹ </span> ${formattedAmount}`;

  return <div dangerouslySetInnerHTML={{ __html: html }} />;

};

export const formatAmountIndianRupees = (amount) => {
  // Ensure the input is a number
  if (typeof amount !== "number") {
    // throw new Error("Input must be a number");
    return amount;
  }
  if (!amount) return;
  // Use toLocaleString to format the number with commas
  const formattedAmount = amount.toLocaleString("en-IN", {
    style: "decimal",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedAmount;

};
