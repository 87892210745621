/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {  AiOutlineDashboard } from "react-icons/ai";

import { Box, Heading } from "@chakra-ui/react";
import CategoryCard from "components/card/CategoryCard";
import { BsDoorOpen, BsFillHouseHeartFill } from "react-icons/bs";
import { GrResources } from "react-icons/gr";
import { TbReceiptTax } from "react-icons/tb";
import { HiReceiptTax } from "react-icons/hi";
import {  useSelector } from "react-redux";
import { TfiReceipt } from "react-icons/tfi";

const AllCategory = () => {
  const { currUser } = useSelector((state) => state.auth);

  const auth = currUser?.auth;
  // verifies if routeName is the one active (in browser input)
  const moduleAccess = name => auth?.some(a=> a.module === name)
  
  const roleAccess = (name)=>{
    const  access = ["Admin", "Manager", "Accountant"];
    const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === name);
    if(!roleObj){
      return false
    } else {
      return access.includes(roleObj?.access)
    }
  }

  const categoryArray = [
    {
      name : "External-Client/Production",
      icon : <BsDoorOpen/> ,
      path : '/external'
      
    },
    {
      name : "Internal-Local expense",
      icon : <BsFillHouseHeartFill/> ,
      path : '/internal'
    },
    {
      name : "Resource/Service Expense",
      icon : <GrResources/> ,
      path : '/resource-expanse'
    },
    {
      name : "Tax Deducted at Source (TDS)",
      icon : <TbReceiptTax/> ,
      path : '/tds'
    },
    {
      name : "Direct Tax",
      icon : <HiReceiptTax/> ,
      path : '/direct-tax'
    },
    {
      name : "Gst",
      icon : <TfiReceipt/> ,
      path : '/gst'
    },
  ]

  return (
    <>
      <Box
        className="all_category"
        pt={{ base: "180px", md: "120px", xl: "120px" }}
      >
        <Heading className="heading" as="h5" size="sm">
          All Category
        </Heading>
      </Box>
      {

      }
      { moduleAccess('Main Dashboard') && roleAccess('Main Dashboard') &&
      <Box className="all_category category-main-card">
      
      <CategoryCard  name='Main Dashboard' icon={<AiOutlineDashboard/> } path={'/dashboard'} />
      </Box>
      }
      <Box className="all_category category-main-card">
        {
          categoryArray.map((item,index)=>{
            return (
              moduleAccess(item?.name) && roleAccess(item?.name) ?
                <CategoryCard key={index} name={item.name} icon={item.icon} path={item.path} />
                :
                ''
              
            )
          })
        }
       
      </Box>
    </>
  );
};

export default AllCategory;
