import {
    Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Table,
    Td,
    Tr,
    Th,
    Tbody,
    Thead,
    Tfoot,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { useDispatch } from "react-redux";
  
  import Form from "react-bootstrap/Form";
  import Col from "react-bootstrap/Col";
  import Row from "react-bootstrap/Row";
  import moment from "moment";
  import dateTimeFormat from "jsFunctions/dateTimeFormat";
  import { poNumberFormate } from "jsFunctions/helperFunctions";
  import { MdOutlineFileDownload } from "react-icons/md";
  import { InputGroup, Tab, Tabs } from "react-bootstrap";
import { fetchAsyncResourcePONum } from "redux/tdsSlice";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
  
  const DataRow = ({ label, value, type }) => {
    return (
      <>
          <Form.Label>{label}</Form.Label>
      {type === 'Amount' 
      ?
      <InputGroup>
        <InputGroup.Text style={{height:'2rem', border:0, background:'lightGray'}}><span className="rupee-symbol">₹</span> </InputGroup.Text>
        <Form.Control
        type="text"
        value={value !== "undefined" && value !== "" ? formatAmountIndianRupees(value) : " "}
        disabled
      />
      </InputGroup>
      :
      <Form.Control
        type="text"
        value={value !== "undefined" && value !== "" ? value : " "}
        disabled
      />
      }
      </>
    );
  };
  
  const GstInfoModel = ({ openInfoModal, closeInfoModal, selectedData, role }) => {
    const [getPoMonth, setGetPoMonth] = useState(moment().format("yyyy-MM"));
    const [selectedItem, setSelectedItem] = useState(selectedData);
    const dispatch = useDispatch();
  
    const [activeTab, setActiveTab] = useState("information");
  
    useEffect(() => {
      setSelectedItem(selectedData);
    }, [selectedData]);
  
    useEffect(() => {
      if (selectedItem.orderMonth) {
        const dateString = selectedItem.orderMonth;
        const date = moment(dateString);
  
        const month = date.format("MMM");
        const year = date.format("YYYY");
        setGetPoMonth(`${year}-${month}`);
      } else {
        setGetPoMonth("");
      }
      
      if(selectedItem?.referenceResourcePO){
         
        dispatch(fetchAsyncResourcePONum()).then((res) => {
          if (res.payload) {
            const resourcePoNo = [];
            res.payload.forEach((item, index) => {
              resourcePoNo.push({
                _id: item._id,
                poNo: poNumberFormate(item.poNo),
              });
            });
          }
        });
    
    }
  })
      
  
    
    return (
      <div>
        <Modal
          isOpen={openInfoModal}
          onClose={closeInfoModal}
          size="xl"
          className="modal-main"
        >
          <ModalOverlay />
          <ModalContent className="info-model">
            <div className="fixed-header">
              <ModalHeader className="expense-modal-header">
                <span>Gst Information</span>
              </ModalHeader>
              <ModalCloseButton className="expense-modal-header" />
            </div>
            <ModalBody>
              <Form className="addForm">
                <Tabs
                  id="justify-tab-example1"
                  activeKey={activeTab}
                  onSelect={(k) => setActiveTab(k)}
                  className=""
                  style={{ marginTop: "4rem" }}
                  justify
                >
                  <Tab
                    eventKey="information"
                    title="Information"
                    className="main-tab-item"
                  >
                    <div className="card">
                      <h5 className="heading-h5"> Purchase Order Details</h5>
  
                      <Row className="info-row">
                        <Col>
                          <DataRow
                            label="Order Number"
                            value={poNumberFormate(selectedItem.poNo)}
                          />
                        </Col>
                        <Col>
                          <DataRow label="Order Month" value={getPoMonth} />
                        </Col>
                      </Row>
                      <Row className="info-row">
                        <Col>
                          <DataRow
                            label="Order Date"
                            value={
                              selectedItem.orderDate
                                ? moment(selectedItem.orderDate).format(
                                    "DD-MMM-yyyy"
                                  )
                                : ""
                            }
                          />
                        </Col>
                        <Col></Col>
                      </Row>
                      
                      <Divider
                      className="info-divider mt-3"
                      orientation="horizontal"
                    />
                    <h5 className="heading-h5">Expense</h5>
                    
                     <Divider
                      className="info-divider mt-3"
                      orientation="horizontal"
                    />
                    <Row className="info-row">
                      <Col>
                          <DataRow
                            label="Remarks"
                            value={selectedItem?.remarks}
                          />
                        </Col>
            
                      </Row>
                    <Row className="info-row">
                      <Col>
                          <DataRow
                            label="Category"
                            value={selectedItem?.category}
                          />
                        </Col>
                        <Col>
                          
                        </Col>
                       
                      </Row>
                       
                      <Divider
                      className="info-divider mt-3"
                      orientation="horizontal"
                    />
                    <h5 className="heading-h5">GST Applicable</h5>
                    <Row className="info-row">
                      <Col className="check-cross-mark mt-0">
                        <Form.Label>
                          <span
                            className="checkmark"
                          ></span>{" "}
                          Is GST applicable?
                        </Form.Label>
                      </Col>
                    
                    </Row>
                    
                      <Row className="info-row">
                      <Col>
                          <DataRow
                            label="Company Name"
                            value={selectedItem?.gstFields?.companyName}
                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="GST Number"
                            value={selectedItem?.gstFields?.gstNumber}
                          />
                        </Col>
                       
                      </Row>
                      <Row className="info-row">
                      <Col>
                          <DataRow
                            label="IGST"
                            value={selectedItem.gstFields.gstAmount?.igst}
                            type='Amount'

                          />
                        </Col>
                      <Col>
                          <DataRow
                            label="SGST"
                            value={selectedItem.gstFields.gstAmount?.sgst}
                            type='Amount'

                          />
                        </Col>
                        
                    </Row>
                    <Row className="info-row">
                    <Col>
                          <DataRow
                            label="CGST"
                            value={selectedItem.gstFields.gstAmount?.cgst}
                            type='Amount'

                          />
                        </Col>
                   
                      <Col>
                          <DataRow
                            label="Total GST"
                            value={Number(selectedItem.gstFields.gstAmount?.cgst) + Number(selectedItem.gstFields.gstAmount?.sgst) + Number(selectedItem.gstFields.gstAmount?.igst)}
                            type='Amount'

                          />
                        </Col>
                       
                    </Row>
                     
                    </div>
                  </Tab>
                 <Tab
                  eventKey=""
                  title=""
                  disabled
                  className="main-tab-item"
                 >

                 </Tab>
                </Tabs>
              </Form>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    );
  };
  
  export default GstInfoModel;
  