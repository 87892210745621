import axios from 'axios';
import { useToasts } from "react-toast-notifications";

const API_URL = process.env.REACT_APP_BASE_URL;

// Create an instance of Axios
const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      // Redirect to sign-in page or handle unauthorized access
      // For example:
      window.location.href = '/sign-in';
      const { addToast } = useToasts();

      addToast('Unauthorized access. Please sign in.', { appearance: "error" });

    }
    return Promise.reject(error);
  }
);

export default axiosInstance;