import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";

export const getDirectTaxExcelData = (directTaxList) => {
    let list = JSON.parse(JSON.stringify(directTaxList));
    const exportData = list.map((data, index) => {
      let content = {
        no: index + 1,
        'Order PO Number': data.poNo && poNumberFormate(data.poNo),
        'Current Order Date':
          data.orderDate && moment(data.orderDate).format('DD-MMM-YYYY'),
        'Receiver Name': data.receiverName,
        'Total Tax': data.totalTax,
        Remark: data.remarks,
        // 'Payment Total Amount': data.paymentInfo?.totalPayAmount,
        "Requested Amount":`₹ ${data.paymentInfo?.approvalAmount  ? formatAmountIndianRupees(data.paymentInfo?.approvalAmount) : Number(0).toFixed(2)}`,
      "Approved Amount":`₹ ${data.paymentInfo?.totalPayAmount  ? formatAmountIndianRupees(data.paymentInfo?.totalPayAmount) : Number(0).toFixed(2)}`,
      };
      if (data?.paymentInfo?.list?.length > 0) {
        data.paymentInfo.list.forEach((element, index) => {
          content[`Payment ${index + 1} Spent Amount`] = `₹ ${element?.amount  ? formatAmountIndianRupees(element?.amount) : Number(0).toFixed(2)}`;
          content[`Payment ${index + 1} Bank`] = element?.bank;
          content[`Payment ${index + 1} Payee`] = element?.payee;
          content[`Payment ${index + 1} Date`] = element?.paymentDate &&
            moment(element?.paymentDate).format("DD-MMM-YYYY");
  
          // // Adding invoice details for each payment entry
          // if (element?.invoice?.length > 0) {
          //   element.invoice.forEach((inv, invIndex) => {
          //     content[`Payment ${index + 1} Invoice ${invIndex + 1} Key`] = inv.Key;
          //     content[`Payment ${index + 1} Invoice ${invIndex + 1} Location`] = inv.Location;
          //   });
          // } else {
          //   content[`Payment ${index + 1} Invoices`] = "None";
          // }
        });
      }
     
      content['Space Added'] = '--------------';
      content['Order month'] = data.orderMonth;

      content['Created AT'] = data.createdAt;

      return content;
    });
    return exportData;
  };