
import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";


export const getExternalExcelData = (externalList) => {
    let list = JSON.parse(JSON.stringify(externalList));
    const exportData = list.map((data, index) => {
      let content = {
        no: index + 1,
        "Order PO Number": data.poNo && poNumberFormate(data.poNo),
        "Current Order Date":
          data.orderDate && moment(data.orderDate).format("DD-MMM-YYYY"),
         
          "Major Type": data.expense?.majorType,
          "Minor Type": data?.minorType,
          "Minor Sub Category": data?.minorSubCategory,
        "Client Name": data.clientName,
        "Project Name": data.projectName,
        "Merchant Name": data.merchantName,
        Remark: data.remarks,
      
        "Freight Expense": data.expense?.isExpense ? "true" : "false",
        "Freight Expense Agency": data.expense?.agency,
        "Freight Expense Tracking": data.expense?.trackingNum,
        
          "Requested Amount":`₹ ${data.paymentInfo?.totalPayAmount  ? formatAmountIndianRupees(data.paymentInfo?.approvalAmount) : Number(0).toFixed(2)}`,
        "Approved Amount":`₹ ${data.paymentInfo?.totalPayAmount  ? formatAmountIndianRupees(data.paymentInfo?.totalPayAmount) : Number(0).toFixed(2)}`,
        "Reimbursement Invoice Number": data?.reimbursementInfo?.invoiceNum,
        "Reimbursement Invoice Date":
          data?.reimbursementInfo?.invoiceDate &&
          moment(data?.reimbursementInfo?.invoiceDate).format("DD-MMM-YYYY"),
        "Payment Reimbursed?": data?.reimbursementInfo?.isReimbursement
          ?.isReimburs
          ? "true"
          : "false",
        "Payment Amount": `₹ ${data?.reimbursementInfo?.isReimbursement?.amount  ? formatAmountIndianRupees(data?.reimbursementInfo?.isReimbursement?.amount) : Number(0).toFixed(2)}`,
        "Payment Currency": data?.reimbursementInfo?.isReimbursement?.currency,
        "Payment Conversion Rate":
          data?.reimbursementInfo?.isReimbursement?.conversionRate,
        "Payment INR Amount":`₹ ${data?.reimbursementInfo?.isReimbursement?.amountInINR  ? formatAmountIndianRupees(data?.reimbursementInfo?.isReimbursement?.amountInINR) : Number(0).toFixed(2)}`,
      };
      if (data?.paymentInfo?.list?.length > 0) {
        data.paymentInfo.list.forEach((element, index) => {
          content[`Payment ${index + 1} Spent Amount`] = `₹ ${element?.amount  ? formatAmountIndianRupees(element?.amount) : Number(0).toFixed(2)}`;
          content[`Payment ${index + 1} Bank`] = element?.bank;
          content[`Payment ${index + 1} Payee`] = element?.payee;
          content[`Payment ${index + 1} Date`] = element?.paymentDate &&
            moment(element?.paymentDate).format("DD-MMM-YYYY");
  
          // // Adding invoice details for each payment entry
          // if (element?.invoice?.length > 0) {
          //   element.invoice.forEach((inv, invIndex) => {
          //     content[`Payment ${index + 1} Invoice ${invIndex + 1} Key`] = inv.Key;
          //     content[`Payment ${index + 1} Invoice ${invIndex + 1} Location`] = inv.Location;
          //   });
          // } else {
          //   content[`Payment ${index + 1} Invoices`] = "None";
          // }
        });
      }
      content["Space Added"] = "--------------";
      content["Order month"] = data.orderMonth;

      content["Created AT"] = data.createdAt;
      return content;
    });
    return exportData;
  };