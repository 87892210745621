import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";

export const getTdsExcelData = (tdsList) => {
    let list = JSON.parse(JSON.stringify(tdsList));
    const exportData = list.map((data, index) => {
      let content = {
        no: index + 1,
        "Order PO Number": data.poNo && poNumberFormate(data.poNo),
        "Current Order Date":
          data.orderDate && moment(data.orderDate).format("DD-MMM-YYYY"),
        "Resource Name": data.resourceName,
        "Resource Pan Number": data.panNumber,
        Remark: data.remarks,
        "Reference Total Amount": `₹ ${data.paymentInfo?.totalBillingAmount  ? formatAmountIndianRupees(data.paymentInfo?.totalBillingAmount) : Number(0).toFixed(2)}`,
        "Reference TDS Percent": data.paymentInfo?.tdsPercent,
        "Reference TDS Amount": `₹ ${data.paymentInfo?.tdsAmount  ? formatAmountIndianRupees(data.paymentInfo?.tdsAmount) : Number(0).toFixed(2)}`,
        "Requested Amount":`₹ ${data.paymentInfo?.approvalAmount  ? formatAmountIndianRupees(data.paymentInfo?.approvalAmount) : Number(0).toFixed(2)}`,
      "Approved Amount":`₹ ${data.paymentInfo?.totalPayAmount  ? formatAmountIndianRupees(data.paymentInfo?.totalPayAmount) : Number(0).toFixed(2)}`,
       
      };
      if (data?.paymentInfo?.list?.length > 0) {
        data.paymentInfo.list.forEach((element, index) => {
          content[`Payment ${index + 1} Spent Amount`] = `₹ ${element?.amount  ? formatAmountIndianRupees(element?.amount) : Number(0).toFixed(2)}`;
          content[`Payment ${index + 1} Bank`] = element?.bank;
          content[`Payment ${index + 1} Payee`] = element?.payee;
          content[`Payment ${index + 1} Date`] = element?.paymentDate &&
            moment(element?.paymentDate).format("DD-MMM-YYYY");
  
          // // Adding invoice details for each payment entry
          // if (element?.invoice?.length > 0) {
          //   element.invoice.forEach((inv, invIndex) => {
          //     content[`Payment ${index + 1} Invoice ${invIndex + 1} Key`] = inv.Key;
          //     content[`Payment ${index + 1} Invoice ${invIndex + 1} Location`] = inv.Location;
          //   });
          // } else {
          //   content[`Payment ${index + 1} Invoices`] = "None";
          // }
        });
      }
      content["Space Added"] = "--------------";
      content["Order month"] = data.orderMonth;
      content["Reference Resource Po"] = data.referenceResourcePO;

      content["Created AT"] = data.createdAt;

      return content;
    });
    return exportData;
  };