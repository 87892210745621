import React, { useEffect, useRef, useState } from "react";
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableContainer,
} from "@chakra-ui/react";

import "react-datepicker/dist/react-datepicker.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "rsuite/dist/rsuite.min.css";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { fetchAsyncAddUser } from "redux/userSlice";
import { fetchAsyncUpdateUser } from "redux/userSlice";

import { FaCheckCircle, FaEdit } from "react-icons/fa";

// modal style

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const xl = defineStyle({
  px: "6",
  py: "2",
  fontSize: "xl",
});

const sm = defineStyle({
  fontSize: "sm",
  py: "6",
});

const sizes = {
  xl: definePartsStyle({ header: sm, dialog: xl }),
};

export const modalTheme = defineMultiStyleConfig({
  sizes,
});

// const options = [
//   { value: "Admin", label: "Admin" },
//   { value: "Manager", label: "Manager" },
//   { value: "Accountant", label: "Accountant" },
// ];

export let accessOptions = [
  { label: "Select Access", value: "" },
  { label: "No Access", value: "No Access" },
  { label: "Admin", value: "Admin" },
  { label: "Manager", value: "Manager" },
  { label: "Accountant", value: "Accountant" },
];
 
export let moduleAccessOptions = [
  { label: "Select Module", value: "" },
  { label: "Main Dashboard", value: "Main Dashboard" },
  { label: "External-Client/Production", value: "External-Client/Production" },
  { label: "Internal-Local expense", value: "Internal-Local expense" },
  { label: "Resource/Service Expense", value: "Resource/Service Expense" },
  { label: "Tax Deducted at Source (TDS)", value: "Tax Deducted at Source (TDS)" },
  { label: "Direct Tax", value: "Direct Tax" },
  { label: "Gst", value: "Gst" },
  { label: "Users", value: "Users" },


];

const default_access = 
[
  {
    "module": "Main Dashboard",
    "access": "No Access"
  },
  {
    "module": "External-Client/Production",
    "access": "No Access"
  },
  {
    "module": "Internal-Local expense",
    "access": "No Access"
  },
  {
    "module": "Resource/Service Expense",
    "access": "No Access"
  },
  {
    "module": "Tax Deducted at Source (TDS)",
    "access": "No Access"
  },
  {
    "module": "Direct Tax",
    "access": "No Access"
  },
  {
    "module": "Gst",
    "access": "No Access"
  },
  {
    "module": "Users",
    "access": "No Access"
  },
  
]


const UserForm = ({ openModel, closeModel, userData, isEdit, refreshList }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [selectedModule, setSelectedModule] = useState('');

  const [editActive,setEditActive] = useState(false)
  const [editValue,setEditValue] = useState({
    module:'',
    access:''
  });

  const [formData, setFormData] = useState({
    name:'',
    email:'',
    auth:default_access
  })

 

  useEffect(()=>{
    if(userData) {
      const {name,email,auth} = userData;
      const combinedAccess = Array.from(
        new Map([...default_access, ...auth].map(item => [item.module, item])).values()
      );
      setFormData({
        ...formData,
        name:name,
        email:email,
        auth:combinedAccess
      })
    }
  },[isEdit])

  const handleOnChange = (e)=>{
    const name = e?.target?.name;
    const value = e?.target?.value;

    setFormData({
      ...formData,
      [name]:value
    })
  }

  const faEditClick = (option)=>{
    setSelectedModule(option?.module)
    setEditValue(option);
    setEditActive(true)
  }
  const faCheckMarkClick = (option)=>{
    setSelectedModule('')
    const auth_data = formData?.auth?.map(item => {
      if (item.module === editValue?.module) {
        const obj = {
          module:item?.module,
          access:editValue?.access
        }
        return obj;
      }
      else{
        return item
      }
    });

    if(auth_data){
      setFormData({
        ...formData,
        auth:auth_data
      })
    }
    setEditValue({
      module:'',
      access:''
    });
    setEditActive(false)
  }
  const [errors, setErrors] = useState({
    name: '',
    email: '',
  });

  const handleEditModuleAccess = (e)=>{
    const value = e?.target?.value;
    setEditValue({
      ...editValue,
      access:value
    })
  }

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddUser = ()=>{

    dispatch(fetchAsyncAddUser(formData))
      .then((res) => {
        if (res?.payload) {
          refreshList();
          closeModel();
          addToast(`New user account has been created successfully!`, { appearance: "success" });
        }
      })
      .catch((err) => {
        addToast("Successfully Error", { appearance: "error" });
      });
  }

  const handleEditUser = ()=>{
    dispatch(fetchAsyncUpdateUser({ id: userData._id, userData: formData }))
            .then((res) => {
              if (res?.payload) {
                const {auth, name, email} = res?.payload
                setFormData({
                  name:name,
                  email:email,
                  auth:auth
                })
               
                  refreshList();
                  closeModel();
                  addToast(`User details have been updated successfully!`, { appearance: "success" });
                
              }
            })
            .catch((err) => {
              addToast("Error", { appearance: "error" });
            });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {

        if(!isEdit){
          handleAddUser()
        }
        else{
          handleEditUser();
        }
    }
      
  };



  useEffect(() => {}, [isEdit]);

  const finalRef = useRef(null);

  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={openModel}
        onClose={() => {
          closeModel();
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <div className="fixed-header">
            <ModalHeader className="modal-heading">
              {isEdit ? "Edit" : "Add"} User
            </ModalHeader>
            {/* <p>Tax Deducted at Source (TDS)</p> */}
            <ModalCloseButton />
          </div>
          <ModalBody>
            <Form className="addForm">
              {/* <div className="card1"> */}
                <div className="card1">

              <h5>User Details</h5>

                <Row>
                  <Col>
                    <Form.Label>Full Name</Form.Label>
                    <span className="span-star">*</span>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      value={formData?.name}
                      name= 'name'
                      isInvalid={!!errors.name}
                      onChange={(e)=>handleOnChange(e)}
                    />
                    <p className="form_error">{errors.name}</p>
                  </Col>
                  <Col>
                    <Form.Label>Email</Form.Label>
                    <span className="span-star">*</span>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      value={formData?.email}
                      name= 'email'
                      isInvalid={!!errors.email}
                      onChange={(e)=>handleOnChange(e)}
                    />
                    <p className="form_error">{errors.email}</p>
                  </Col>
                </Row>
                </div>
                <div className="card1">
                <h5>Module Access Details</h5>
                  <TableContainer className="user-role" mt={5}>
                    <Table size="md" variant="bubble" className="user-role-table">
                      <Thead>
                        <Tr>
                          <Th>Module</Th>
                          <Th width={250}>Access</Th>
                          <Th>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {formData?.auth &&
                          formData?.auth?.length > 0 &&
                          formData?.auth?.map((item, index) => {
                            return (
                              <Tr>
                                <Td>{item?.module}</Td>
                                <Td>
                                  {editActive &&
                                  selectedModule === item?.module ? (
                                    <div className="dropdown-item selected">
                                      <Form.Select
                                        name="auth"
                                        value={editValue?.access}
                                        onChange={(e) => handleEditModuleAccess(e)}
                                        isInvalid={!!errors.access}
                                      >
                                        {accessOptions?.map((option) => {
                                          return (
                                            <option value={option.value}>
                                              {option.label}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </div>
                                  ) : (
                                    item?.access
                                  )}
                                </Td>
                                <Td>
                                  {!editActive && !selectedModule ? (
                                    <FaEdit
                                      style={{
                                        color: "#db8a2f",
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                      }}
                                      onClick={() => {
                                        faEditClick(item);
                                      }}
                                    />
                                  ) : editActive &&
                                    selectedModule === item?.module ? (
                                    <FaCheckCircle
                                      style={{
                                        color: "green",
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                      }}
                                      onClick={() => {
                                        faCheckMarkClick(item);
                                      }}
                                    />
                                  ) : (
                                    <FaEdit
                                      style={{
                                        color: "#db8a2f",
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                      }}
                                      onClick={() => {
                                        faEditClick(item);
                                      }}
                                    />
                                  )}
                                </Td>
                               
                              </Tr>
                            );
                          })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </div>
                <Row className="role_add_btn">
                    <Button
                      variant="brand"
                      style={{ background: "#191063" }}
                      onClick={(e) => handleSubmit(e)}
                      className=" submit-btn mt-1 px-4"
                      size="sm"
                    >
                     {isEdit ? 'Edit User' : 'Add User'}
                    </Button>
                  </Row>
             
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserForm;
