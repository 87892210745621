// handling option could be editable
export const createOption = (label) => ({
  label,
  // value: label.toLowerCase().replace(/\W/g, ""),
  value : label
});

export const createListForSingleOption = (optionsList, type) => {
  if (optionsList.length === 0) {
    return [];
  }
  let data = optionsList
    .filter((item) => item.type === type)
    .map((data) => createOption(data.label ? data.label : data.value));
  return data;
};
