/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "rsuite/dist/rsuite.min.css";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

import CurrencyFormat from 'react-currency-format';
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";

import { createListForSingleOption } from "./createableSelect";
import { createOption } from "./createableSelect";
import calculateTds from "jsFunctions/calculateTds";
import PaymentInfoComponent from "./paymentInfoComponent";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { fetchAsyncGetPONumTDSExpense } from "redux/tdsSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncResourcePONum } from "redux/tdsSlice";
import CustomSelect from "components/CustomSelect";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { fetchAsyncAddValueToFormOptions } from "redux/formOptions";
import { fetchAsyncDeleteFormOptions } from "redux/formOptions";
import { fetchAsyncAddPanWithResourceExpense } from "redux/resourceSlice";
import { fetchAsyncAddTDSExpense } from "redux/tdsSlice";
// import { fetchAsyncGetResourceExpense } from "redux/resourceSlice";
import { fetchAsyncGetTDSExpense } from "redux/tdsSlice";
import moment from "moment";
import { fetchAsyncGetPanResourceExpense } from "redux/resourceSlice";
import { fetchAsyncDeleteTdsPdf } from "redux/tdsSlice";
import { fetchAsyncUploadTdsPdf } from "redux/tdsSlice";
import { fetchAsyncUpdateTdsExpense } from "redux/tdsSlice";
import { tdsFormValidation } from "validation";
import { fetchAsyncAddTDSInvoiceRemark } from "redux/tdsSlice";
import { fetchAsyncGetFormOptions } from "redux/formOptions";
import { fetchAsyncEditFormOptions } from "redux/formOptions";
import { checkApprovalAmount } from "jsFunctions/helperFunctions";
import { checkTotalApprovedAmountWithSpentAmount } from "jsFunctions/helperFunctions";
import { fetchAsyncRejectTdsTotalPayAmount } from "redux/tdsSlice";
import { fetchAsyncApproveTdsTotalPayAmount } from "redux/tdsSlice";
import { fetchAsyncSendRequestUpdateApprovalTdsTotalPayAmount } from "redux/tdsSlice";
import { fetchAsyncSendRequestApprovalTdsTotalPayAmount } from "redux/tdsSlice";
import { checkSpentAmountisEmpty } from "jsFunctions/helperFunctions";
import { fetchAsyncDeleteTdsPaymentListItem } from "redux/tdsSlice";
import DataChangeModalOnEdit from "components/models/DataChangeModalOnEdit";
import { InputGroup } from "react-bootstrap";
import { fetchAsyncGetByReferencePoDetails } from "redux/tdsSlice";
import { fetchAsyncGetGstCompanyExpense } from "redux/resourceSlice";
import { fetchAsyncAddGstWithCompanyExpense } from "redux/resourceSlice";
// import { Option } from "antd/es/mentions";

// modal style

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const xl = defineStyle({
  px: "6",
  py: "2",
  fontSize: "xl",
});

const sm = defineStyle({
  fontSize: "sm",
  py: "6",
});

const sizes = {
  xl: definePartsStyle({ header: sm, dialog: xl }),
};

export const modalTheme = defineMultiStyleConfig({
  sizes,
});

const TdsAddNew = ({
  tdsOpen,
  tdsClose,
  tdsExpenseData,
  isEditForm = false,
  editItem, 
  refreshEditData,
  activeList,
  role
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const formOptionsList1 = useSelector((state) => state.formOptions);
  const [isEdit, setIsEdit] = useState(isEditForm)
  const [editData, setEditData] = useState(editItem)
  const [poPrefix, setPoPrefix] = useState({
    prefix: "PO-T-",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    number: 1,
  });
  const [getPoMonth, setGetPoMonth] = useState(moment().format("yyyy-MM"));

  const [generatedNum, setGeneratedNum] = useState("PO-T-");
  const [isTds, setIsTds] = useState(false);
  const [isGst, setIsGst] = useState(false);
  const [gstAmount , setGstAmount] = useState({
    cgst:'',
    sgst:'',
    igst:''
  })
  const [formOptionsList, setFormOptionsList] = useState([])

  const [amountTotal, setAmountTotal] = useState("");
  const [tdsAmount, setTdsAmount] = useState("");
  const [resourceOption, setResourceOption] = useState([]);
  const [editTDSAmount, setEditTDSAmount] = useState(true);
  const [tdsPercent, setTdsPercent] = useState(10);
  const [editPan, setEditPan] = useState(true);
  const [editGst, setEditGst] = useState(true);

  const [editPercentNum, setEditPercentNum] = useState(true);
  const [setResourcePoNo, setSetResourcePoNo] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [multipleInvoiceData, setMultipleInvoiceData] = useState([]);
  const [multipleAddPaymentInfo, setMultipleAddPaymentInfo] = useState([]);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [approvalAmount, setApprovalAmount] = useState(null);
  const [remarksValue, setRemarksValue] = useState('');
  const [errors, setErrors] = useState({})
  const [companyTypeValue, setCompanyTypeValue] = useState("");
  const [companyTypeOption, setCompanyTypeOption] = useState([]);

  const [comparingData, setComparingData] = useState(null);
  const [approveOrRejectRemarks, setApproveOrRejectRemarks] = useState('')

  const [sendPayAmountRequest, setSendPayAmountRequest] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState()
  const [totalSpentAmount, setTotalSpentAmount] = useState('');
  const [poNoResource, setPoNoResource] = useState([]);
  const [isSettled, setIsSettled] = useState(false);
  const [resourceValue, setResourceValue] = useState("");
  const [settledDate, setSettledDate] = useState(new Date());
  const [compareDataModal, setCompareDataModal] = useState(false);

  const [totalPayAmountIsApproved, setTotalPayAmountIsApproved] = useState(false);
  const currUser = useSelector((state) => state.auth.currUser);

  const resetAllStateOnCloseModal = () => {
    setEditPan(true);
    setTdsPercent(10);

    setIsTds(false);
    setIsSettled(false);
    setEditPan(true);
    setEditTDSAmount(true);
    setEditPercentNum(true);
  };
  useEffect(
    () => {
      if( amountTotal && tdsPercent ){

        setTdsAmount(calculateTds(amountTotal, tdsPercent));
      }
      else{
        setTdsAmount(0)
      }
    },
    [amountTotal],
    [tdsPercent]
  );

  useEffect(()=>{
    if(formOptionsList1 && formOptionsList1?.length > 0){
      const internalFormOptionList = formOptionsList1.filter((item)=> item.expenseType === 'tds')
      setFormOptionsList(internalFormOptionList)
    }
  },[formOptionsList1])

  useEffect(() => {
    dispatch(fetchAsyncResourcePONum()).then((res) => {
      if (res.payload) {
        const resourcePoNo = [];
        res.payload.forEach((item, index) => {
          resourcePoNo.push({
            _id: item._id,
            poNo: poNumberFormate(item.poNo),
          });
        });
        setPoNoResource(resourcePoNo);
      }
    });
  }, []);

  const closeComparedDataModal = () => {
    setCompareDataModal(false);

    setComparingData(null);
  };
  const submitUpdateData = (formData, editData) => {
    dispatch(
      fetchAsyncUpdateTdsExpense({
        id: editData._id,
        data: formData,
      })
    )
      .then((res) => {
        if (res.payload) {
          addToast(`Expense PoNo: ${generatedNum} updated successfully!`, { appearance: "success" });
          dispatch(fetchAsyncGetTDSExpense());
      }
        tdsClose();
        setComparingData(null);
        formik.resetForm();
      })
      .catch((error) => {
        console.log(error);
        addToast("Error", { appearance: "error" });
      });
  };
  const initialValues = {
    poNo: "",
    orderMonth: "",
    orderDate: "",
    resourceName: "",
    panNumber: "",
    gstNumber:"",
    remarks: "",

    payee: 0,
    paymentAmount: 0,
    bank: "",
    referance: "",
    date: null,
    isSettle: false,
    setteledDate: null,
    approvalAmount: 0,
    isTDS: false,
    totalAmount: 0,
    tdsPercent: 0,
    totalTDS: 0,

    isTDSPO: false,
    TDSPONum: "",
    gstNumber:'',
    source: "",
    invoiceNum: "",
    invoiceDate: null,
    reimbursmentAmount: 0,

    invoice: "",
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: tdsFormValidation,
    onSubmit: (values, { resetForm }) => {
      if (!resourceValue) {
        setErrors({
          ...errors,
          resourceValue:'Resource name is required!'
        })
        return;
      }
     
      const checkSpent = checkSpentAmountisEmpty(
        multipleAddPaymentInfo,
      );
      if (!checkSpent.status) {
        addToast(checkSpent.msg, { appearance: "error" });
        return;
      }

      const checkApproval = checkApprovalAmount(
        approvalAmount,
      );
      if (!checkApproval.status) {
        setErrors({
          ...errors,
          approvalAmount:checkApproval.msg
        }) 
        return;
      }
      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        totalPaymentAmount,
        multipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })
        return;
      }
      let formData = new FormData();
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", selectedDate);
      formData.append("resourceName", resourceValue.value);
      formData.append("panNumber", values.panNumber);
      formData.append("remarks", remarksValue);
      formData.append("refResourcePONum", setResourcePoNo);
      formData.append("totalPaymentAmount", totalPaymentAmount);
      formData.append("approvalAmount", approvalAmount);
      formData.append("companyType", companyTypeValue.value);

      const filteredMultipleAddPaymentInfoPay = multipleAddPaymentInfo.map((item)=>{
        if(item?.invoice){
          return {
            ...item,
            invoice: item?.invoice.filter(entry => 'Key' in entry)
          }
        }
        else{
          return item
        }
      });
      formData.append("payInfo", JSON.stringify(filteredMultipleAddPaymentInfoPay));
      formData.append("isPaymentSettle", isSettled);
      formData.append("setteledDate", settledDate);
      formData.append("isTDS", isTds);
      formData.append("isGST", isGst);
      formData.append("gstNumber", values.gstNumber);
      formData.append("gstAmount", JSON.stringify(gstAmount));

      formData.append("totalAmount", amountTotal);
      formData.append("tdsPercent", tdsPercent);
      formData.append("tdsAmount", tdsAmount);
      formData.append("invoiceNum", values.invoiceNum);

      if (isEdit) {
        setComparingData(formData);
      } else {
        multipleInvoiceData.forEach((element, i) => {
          formData.append(`file${i}`, element);
        });
        dispatch(fetchAsyncAddTDSExpense(formData))
          .then((res) => {
            if (res?.payload) {
              tdsClose();
              resetForm();
              addToast("Successfully added", { appearance: "success" });
              dispatch(fetchAsyncGetTDSExpense())
                .then((res) => {})
                .catch((err) => {
                  console.log(err);
                });
            } else {
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
  });
  const handleEditTDSPercentange = () => {
    setEditPercentNum(false);
  };
  const handleEditSubmit = () => {
    setEditTDSAmount(true);
  };
  const handleEditTDS = () => {
    setEditTDSAmount(false);
  };
  const handleEditPAN = () => {
    setEditPan(false);
  };
  const handleEditGST = () => {
    setEditGst(false);
  };

  // SET PO NUMBER

  useEffect(() => {
    setGeneratedNum(poNumberFormate(poPrefix));
  }, [poPrefix]);

  const handelDeleteInvoice = (index,id) => {
    // const updateList = multipleInvoiceData.filter((item, i) => i !== index);
    // setMultipleInvoiceData([...updateList]);

    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === id) {

        let invoice = []
        if(item?.invoice){

          invoice = item?.invoice.filter((item, i) => i !== index)
        }
        
        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  useEffect(() => {
    if (!isEdit && getPoMonth) {
      const dataSplit = getPoMonth.split("-");
      dispatch(
        fetchAsyncGetPONumTDSExpense({
          month: dataSplit[1],
          year: dataSplit[0],
        })
      )
        .then((res) => {
          if (res.payload?.[0]?.poNo) {
            setPoPrefix((prev) => {
              return {
                prefix: prev.prefix,
                month: dataSplit[1],
                year: dataSplit[0],
                number: res.payload?.[0]?.poNo.number + 1,
              };
            });
          } else {
            setPoPrefix((prev) => {
              return {
                prefix: prev.prefix,
                month: dataSplit[1],
                year: dataSplit[0],
                number: 1,
              };
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getPoMonth]);

  const finalRef = useRef(null);

  // create option
  const handleInvoiceChange = (data) => {
    // setOpenInvoice(true);
    // if (multipleInvoiceData.length < 10) {
    //   setMultipleInvoiceData([
    //     ...multipleInvoiceData,
    //     { data: "", invNumber: data.data },
    //   ]);
    // }

    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === data?.id) {

        let invoice = []

        if(item?.invoice){
           if (item?.invoice?.length < 5) {
          invoice = [
            ...item?.invoice,
            { data: "", invNumber: data.data }
          ]
        }
        else{
          invoice = item?.invoice
        }
        }
        else{
          invoice = [
            { data: "", invNumber: data.data }
          ]
        }

        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  useEffect(() => {
    setTdsAmount(calculateTds(amountTotal, tdsPercent));
  }, [amountTotal, tdsPercent]);

  const handleUpdateInvoiceData = (invoiceData, index) => {
    const updateList = multipleInvoiceData.map((item, i) => {
      if (i === index) {
        return invoiceData;
      } else return item;
    });
    setMultipleInvoiceData([...updateList]);
  };

  // payment info function
  const handleMultiplePayInfo = (data) => {
    setMultipleAddPaymentInfo([
      ...multipleAddPaymentInfo,
      { data: "", paydata: data.data,invoice:[] },
    ]);
  };
  const handleUpdateMultiplePayData = (payData, index) => {
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === index) {
        return {...item,...payData};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };

  
  const handleDeleteInvoiceOnEdit = (key,listIndex) => {
    Swal.fire({
      title: "Are you sure? Delete File Permanently.",
      text: `Delete File ${key}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          key: key,
          listIndex:listIndex

        };
        dispatch(fetchAsyncDeleteTdsPdf(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.tdsExpense?.paymentInfo?.list)

          refreshEditData();
        });
      }
    });
  };
  // form list option
  useEffect(() => {
    let resourceList = createListForSingleOption(formOptionsList1, "resource");
    let companyTypeList = createListForSingleOption(
      formOptionsList1,
      "company-type"
    );
    setResourceOption(resourceList);
    setCompanyTypeOption(companyTypeList)

  }, [formOptionsList]);
  // create new function
  const createNewFormOption = (data, type) => {
    dispatch(
      fetchAsyncAddValueToFormOptions({
        label: data.label,
        value: data.value,
        type,
        expenseType:'tds'
      })
    )
      .then((res) => {
        if (res.payload.value) {
          const setData = {
            label: res.payload.label,
            value: res.payload.value,
          };
          if (type === "resource") {
            setResourceValue(setData);
          }
          if (type === "company-type") {
            setCompanyTypeValue(setData);
          }
          Swal.fire({
            title: "Successfully Added",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            customClass: {
              container: "sweet_containerImportant",
              confirmButton: "sweet_confirmbuttonImportant",
            },
          }).then((result) => {});
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Something went wrong",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
          customClass: {
            container: "sweet_containerImportant",
            confirmButton: "sweet_confirmbuttonImportant",
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      });
  };
  const deleteFromOption = (option, type) => {
    let data = {};
    if(type === 'resource' || type === 'company-type'){
      data = formOptionsList1.find(
        (item) => item.value === option.value && item.type === type
      );
    }
    else{

      data = formOptionsList.find(
        (item) => item.value === option.value && item.type === type
      );
    
    }
    
    dispatch(fetchAsyncDeleteFormOptions(data._id));
  };

  const editFromOption = (option,newValue, type) => {
    let data = {};
    if(type === 'resource' || type === 'company-type'){
      data = formOptionsList1.find(
        (item) => item.value === option.value && item.type === type
      );
    }
    else{

      data = formOptionsList.find(
        (item) => item.value === option.value && item.type === type
      );
    
    }
   
    
    dispatch(fetchAsyncEditFormOptions({
      id:data._id,
      newData:{
        optionValue:newValue,
        expenseType:'tds'

      }
    })).then((res)=>{
      if (res.payload.value) {
        const setData = {
          label: res.payload.label,
          value: res.payload.value,
        };
       
    
        if (type === "company-type") {
          setCompanyTypeValue(setData);
        }
        if (type === "resource") {
          setResourceValue(setData);
        }
       
        dispatch(fetchAsyncGetFormOptions())
      }
    }).catch((err)=>{
      console.log(err)
    })
  };
  // add resource with pan
  const handleAddResourcePan = () => {
    if (!resourceValue?.value) {
      addToast("Please select a resource", { appearance: "error" });
      setEditPan(true);
      return;
    }
    dispatch(
      fetchAsyncAddPanWithResourceExpense({
        resourceName: resourceValue?.value,
        resourcePan: formik.values.panNumber,
      })
    ).then((res) => {
      if (res.payload) {
        // setResourcePan(res.payload.resourcePan.resourcePan);
        setEditPan(true);
      }
    });
  };

   // add resource with pan
   const handleAddCompanyGst = () => {
    if (!companyTypeValue?.value) {
      addToast("Please select a company", { appearance: "error" });
      setEditGst(true);
      return;
    }
    dispatch(
      fetchAsyncAddGstWithCompanyExpense({
        companyName: companyTypeValue?.value,
        companyGst: formik.values.gstNumber,
      })
    ).then((res) => {
      if (res.payload) {
        // setResourcePan(res.payload.resourcePan.resourcePan);
        setEditGst(true);
      }
    });
  };
  useEffect(() => {
    if (resourceValue) {
      dispatch(fetchAsyncGetPanResourceExpense(resourceValue.value)).then(
        (res) => {
          if (res?.payload?.length > 0) {
            // setResourcePan(res?.payload?.[0]?.resourcePan?.resourcePan);
            formik.setFieldValue("panNumber", res?.payload?.[0]?.resourcePan);
          } else {
            formik.setFieldValue("panNumber", "");
          }
        }
      );
    }
  }, [resourceValue]);

  useEffect(() => {
    if (companyTypeValue) {
      dispatch(fetchAsyncGetGstCompanyExpense(companyTypeValue.value)).then(
        (res) => {
          if (res?.payload?.length > 0) {
            formik.setFieldValue("gstNumber", res?.payload?.[0]?.companyGst);
          } else {
            formik.setFieldValue("gstNumber", "");
          }
        }
      );
    }
  }, [companyTypeValue]);

  const addNewInvoiceOnEdit = (file, index,listIndex) => {
    Swal.fire({
      title: "Are you sure? Upload File Directly.",
      text: `Upload - ${file.name}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (editData) {
          const lastInvoiceKey =
            editData.invoice?.length > 0 &&
            editData.invoice[editData.invoice.length - 1].Key;
          let number = index + 1;
          if (lastInvoiceKey) {
            number = lastInvoiceKey.split("PAY_")[1]?.split(".pdf")[0];
            number = parseInt(number) + 1;
          }
          let formData = new FormData();
          const getPoNoById = (id) => {
            const result = poNoResource.find(item => item._id === id);
            return result ? result.poNo : undefined;
          };

          const referenceResourcePO = getPoNoById('');
  
          formData.append("poNo", JSON.stringify(editData.poNo));
          formData.append("number", number);
          formData.append("file", file);
          formData.append("listIndex", listIndex);
          formData.append("index", index);
          formData.append("referenceResourcePO", JSON.stringify(referenceResourcePO || ''));

          const listItem = multipleAddPaymentInfo[listIndex];
          formData.append("listItem", JSON.stringify(listItem));
          dispatch(fetchAsyncUploadTdsPdf({ id: editData._id, formData })).then(
            (res) => {
              setMultipleAddPaymentInfo(res?.payload?.tdsExpense?.paymentInfo?.list)

              refreshEditData();
            }
          );
        }
      }
    });
  };


  const getReferencePoDetails = async(id)=>{
    dispatch(fetchAsyncGetByReferencePoDetails(id)).then((res)=>{
      if(res?.payload){
        const referenceExpense = res?.payload?.referenceExpense;
        if (res?.payload && referenceExpense) {
          if(referenceExpense?.resourceName){
            setResourceValue({
              label:referenceExpense?.resourceName,
              value:referenceExpense?.resourceName
            })
          }
          if(referenceExpense?.tdsApplicable && referenceExpense?.tdsApplicable?.isTDS){
            const tdsData = referenceExpense?.tdsApplicable;
            setAmountTotal(tdsData?.totalAmount)
            setTdsPercent(tdsData?.tdsPercent)
            setTdsAmount(tdsData?.totalTDS)
            if(!editData?.paymentInfo?.approvalAmount){
              setApprovalAmount(tdsData?.totalAmount)
            }
          }
          else if(referenceExpense?.paymentInfo?.approvalAmount){
            setAmountTotal(referenceExpense?.paymentInfo?.approvalAmount)
            if(!editData?.paymentInfo?.approvalAmount){
              setApprovalAmount(referenceExpense?.paymentInfo?.approvalAmount)
            }
          }
        }
        }
      }
    ).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    if(setResourcePoNo){
      getReferencePoDetails(setResourcePoNo);
    }
  },[setResourcePoNo])
  // Changes on Edit
  const editFormPrefiledData = (formOptionsList, editData) => {
    const resourceData = getOptionDataOnEdit(
      formOptionsList,
      editData,
      "resourceName",
      "resource"
    );
    if (resourceData) {
      setResourceValue({
        label: resourceData.label,
        value: resourceData.value,
      });
    }
    const companyData = getOptionDataOnEdit(
      formOptionsList1,
      editData.gstApplicable,
      "companyName",
      "company-type"
    );
    if (companyData && !companyTypeValue) {
      setCompanyTypeValue({
        label: companyData.label,
        value: companyData.value,
      });
    }
  };

  const getOptionDataOnEdit = (formOptionsList, editData, value, type) => {
    const data = formOptionsList.find(
      (item) => item.value === editData?.[value] && item.type === type
    );
    return data;
  };

  useEffect(() => {
    // fill data in form on edit
    if (isEdit && editData) {
      setPoPrefix(editData.poNo);
      setSelectedDate(new Date(editData.orderDate));

      setGetPoMonth(
        `${editData.poNo.year}-${
          editData.poNo.month <= 9
            ? "0" + editData.poNo.month
            : editData.poNo.month
        }`
      );
      if (formOptionsList?.length > 0 || formOptionsList1?.length > 0) {
        editFormPrefiledData(formOptionsList, editData);
      }
      if (editData.paymentInfo?.totalPayAmount) {
        setTotalPaymentAmount(editData.paymentInfo?.totalPayAmount);
      }
       if (editData.paymentInfo?.payAmountApproveIsRequest) {
        setSendPayAmountRequest(true)
      }
      
       if(editData?.paymentInfo?.totalPayAmountIsApproved){
        setTotalPayAmountIsApproved(true)
      }
      if (editData?.gstApplicable && editData?.gstApplicable?.isGST) {
        setIsGst(editData?.gstApplicable?.isGST);
      }
      if (editData?.gstApplicable && editData?.gstApplicable?.gstAmount) {
        setGstAmount(editData?.gstApplicable?.gstAmount);
      }
     
      if (editData.paymentInfo?.approvalAmount) {
        setApprovalAmount(editData.paymentInfo?.approvalAmount);
      }
      if (editData.paymentInfo?.list && editData.paymentInfo?.list.length > 0) {
        setMultipleAddPaymentInfo(editData.paymentInfo?.list);
      }
      if (editData?.approveRejectRemarks) {
        setApproveOrRejectRemarks(editData?.approveRejectRemarks)
      }
      if (editData.paymentInfo?.isSettle) {
        setIsSettled(editData.paymentInfo.isSettle);
        editData.paymentInfo.setteledDate &&
          setSettledDate(new Date(editData.paymentInfo.setteledDate));
      }
      if(editData?.referenceResourcePO){
        setSetResourcePoNo(editData?.referenceResourcePO)
      }
      
      if (editData?.totalBillingAmount) {
        setAmountTotal(editData?.totalBillingAmount);
      }
      if (editData?.remarks) {
        setRemarksValue(editData?.remarks)
      }
      if (editData?.tdsPercent) {
        setTdsPercent(editData?.tdsPercent);
      }
      if (editData?.tdsAmount) {
        setTdsAmount(editData?.tdsAmount);
      }
      if (editData.invoice?.length > 0) {
        setMultipleInvoiceData([...editData.invoice]);
      } else {
        setMultipleInvoiceData([]);
      }
    }
  }, [isEdit, editData, formOptionsList]);

  // add Remark for Invoice
  const addRemarkForInvoice = (value, item,listIndex,index) => {
    dispatch(
      fetchAsyncAddTDSInvoiceRemark({
        id: editData._id,
        remarkData: { key: item.Key, remark: value, listIndex, index },
      })
    ).then((res) => {
      setMultipleAddPaymentInfo(res?.payload?.tdsExpense?.paymentInfo?.list)

      refreshEditData();
    });
  };

  const handleAmountApproveBtn = (values)=>{   
  
      if (!resourceValue) {
        setErrors({
          ...errors,
          resourceValue:'Resource name is required!'
        })        
        return;
      }
     

    const checkApproval = checkApprovalAmount(
      approvalAmount,
    );
    if (!checkApproval.status) {
      setErrors({
        ...errors,
        approvalAmount:checkApproval.msg
      }) 
      return;
    }
  
    if(approvalAmount <= totalPaymentAmount && remainingAmount >= 0){
  
      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        totalPaymentAmount,
        multipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })
        return;
      }
    }
    if(remainingAmount < 0 && approvalAmount <= totalPaymentAmount){
      return;
    }
      let formData = new FormData();
      
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", selectedDate);
     
      formData.append("resourceName", resourceValue.value);
      formData.append("panNumber", values.panNumber);
      formData.append("remarks", remarksValue);
      formData.append("refResourcePONum", setResourcePoNo);
      formData.append("companyType", companyTypeValue.value);

      formData.append("totalPaymentAmount", totalPaymentAmount);
      formData.append("approvalAmount", approvalAmount);
      formData.append("isPaymentSettle", isSettled);
      formData.append("setteledDate", settledDate);
      formData.append("isTDS", isTds);
      formData.append("isGST", isGst);
      formData.append("gstNumber", values.gstNumber);
      formData.append("gstAmount", JSON.stringify(gstAmount));

      amountTotal && formData.append("totalAmount", amountTotal);
      formData.append("tdsPercent", tdsPercent);
      !isNaN(tdsAmount) && formData.append("totalTDS", tdsAmount);
     
      formData.append("invoiceNum", values.invoiceNum);


      if(isEdit && editData){
        const data = {
          resourceName : resourceValue.value,
          panNumber : values.panNumber,
          remarks:values.remarks,
          refResourcePONum:setResourcePoNo,
          isPaymentSettle:isSettled ? isSettled : false,
          setteledDate:settledDate,
          totalAmount:amountTotal,
          tdsPercent:tdsPercent,
          totalTDS:tdsAmount
        }
        dispatch(fetchAsyncSendRequestUpdateApprovalTdsTotalPayAmount
          ({ 
          id: editData._id,
          data:{formData:data,newApprovalAmount:approvalAmount}
         })).then(
          (res) => {
            if (res.payload && res.payload.updatedData) {
              setIsEdit(true)
              setEditData(res.payload.updatedData)
              setSendPayAmountRequest(true)
              addToast(`Resend updated request for approval for expense PoNo : ${generatedNum} !`, { appearance: "success" });
              tdsClose();
              dispatch(fetchAsyncGetTDSExpense());
            }
            else{
              addToast("Error", { appearance: "error" });
  
            }
  
          
          }
        );
      }
      else{
  
        dispatch(fetchAsyncSendRequestApprovalTdsTotalPayAmount(formData)).then((res) => {
            if (res.payload) {
              addToast(`Send request for approval for expense PoNo : ${generatedNum} !`, { appearance: "success" });
              dispatch(fetchAsyncGetTDSExpense());
              setIsEdit(true)
              setEditData(res.payload)
              setSendPayAmountRequest(true)
              tdsClose();
              dispatch(fetchAsyncGetTDSExpense());
  
            
            }
            else{
              addToast("Error", { appearance: "error" });
  
            }
          });
      }
      
      
      }
  const handleTotalPayAmountIsApproved = ()=>{
    const _id = editData._id;
    if(!approveOrRejectRemarks){
      setErrors({
        ...errors,
        approveOrRejectRemarks:'Approve Or Reject Remarks Is Required!'
      })
    }
    else{
    if(!editData.paymentInfo?.totalPayAmountIsApproved){
      Swal.fire({
        title: `Are You Sure? You Want To Approve Amount <span style="color: orange"><span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)}</span> For PoNo: <span style="color:orange">${generatedNum}</span>.`,
        text: `Please confirm to proceed with the approval.`,        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Approve",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncApproveTdsTotalPayAmount({ 
            id: _id,
            data:{approvalAmount:approvalAmount,totalPayAmountIsApproved:true,approveRejectRemarks:approveOrRejectRemarks}
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsApproved) {
                addToast(`Requested Amount for PoNo: ${generatedNum} has been successfully approved.`, { appearance: "success" });
                dispatch(fetchAsyncGetTDSExpense());
                tdsClose();
              }
              
            }
          );
        }
      });
     
    }
    }
  }

  const handleTotalPayAmountIsRejected = ()=>{
    const _id = editData._id;
    if(!approveOrRejectRemarks){
      setErrors({
        ...errors,
        approveOrRejectRemarks:'Approve Or Reject Remarks Is Required!'
      })
    }
    else{
    if(!editData.paymentInfo?.totalPayAmountIsApproved &&  !editData.paymentInfo?.totalPayAmountIsRejected){
      Swal.fire({
        title: `Are You Sure? You Want To Reject Amount <span style='color:orange'> <span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)} </span> For PoNo: <span style="color:orange">${generatedNum}.</span>`,
        text: `Please confirm to reject. This action cannot be undone.`,        
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reject",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncRejectTdsTotalPayAmount({ 
            id: _id,
            data:{totalPayAmountIsRejected:true,approveRejectRemarks:approveOrRejectRemarks}
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsRejected) {
                addToast(`Requested Amount for PoNo: ${generatedNum} has been successfully rejected.`, { appearance: "success" });
                dispatch(fetchAsyncGetTDSExpense());
                tdsClose();
              }
              
            }
          );
        }
      });
     
    }
  }
  }
  useEffect(()=>{
    if(multipleAddPaymentInfo) {
      const array = multipleAddPaymentInfo;
      if(array.length > 0){

        const sum = array.reduce((acc, current) => {
          if (current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setTotalSpentAmount(parseFloat(sum))
        const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)

        setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
      }



    }
  },[multipleAddPaymentInfo])


  useEffect(()=>{
    if(totalPaymentAmount){
      if(multipleAddPaymentInfo) {
        const array = multipleAddPaymentInfo;
        if(array.length > 0){
  
          const sum = array.reduce((acc, current) => {
            if (current?.amount && current?.amount !== '') {
              return acc + parseFloat(current?.amount);
            } else {
              return acc;
            }
          }, 0);
          const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
          setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
        }
        else{
          const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
          setRemainingAmount(parseFloat(total_pay_amount))
        }
      }

    }
  },[tdsAmount || totalPaymentAmount])

  useEffect(()=>{
    if(totalPaymentAmount){
      if(totalPaymentAmount && !editData?.tdsApplicable?.totalTDS && isTds){
        setAmountTotal(totalPaymentAmount)
      }
    }
  },[totalPaymentAmount])
  const closeModelForm = () => {
    Swal.fire({
      title: "Are you sure you want to close without saving?",
      text: `You will lost all current changes`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        tdsClose();
        resetAllStateOnCloseModal();
      }
    });
  };

  const handleRemarksChange = (e)=>{
    const value = e?.target?.value;
    const encoder = new TextEncoder(); // Default is 'utf-8'
    const byteLength = encoder.encode(value).length;
    if(byteLength <=128){

      setRemarksValue(value)
      setErrors({
        ...errors,
        remarks:''
      })
    }
    else{
      setErrors({
        ...errors,
        remarks:'Remarks must be within the 128 byte limit!'
      })
    }
  }

 
  const deleteMultipleListItem = (listIndex)=>{
    Swal.fire({
      title: "Are you sure? Delete Amount Details Permanently.",
      text: ``,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          listIndex:listIndex
        };
        dispatch(fetchAsyncDeleteTdsPaymentListItem(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.tdsExpense?.paymentInfo?.list)
          refreshEditData();
        });
      }
    });
}


const handleResourceNumber = (e)=>{
  const value = e?.target?.value;
  setSetResourcePoNo(value)
  
}

  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={tdsOpen}
        onClose={closeModelForm}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <div className="fixed-header">
            <ModalHeader className="modal-heading expense-modal-header">
              {tdsExpenseData && "Edit"} Tax Deducted at Source (TDS)
            </ModalHeader>
            {/* <p>Tax Deducted at Source (TDS)</p> */}
            <ModalCloseButton />
          </div>
          <ModalBody>
            <Form className="addForm" onSubmit={formik.handleSubmit}>
              <div className="card1">
                <h5>Purchase Order Details</h5>
                <Row>
                  <Col>
                    <Form.Label>Order Number</Form.Label>
                    <span className="span-star">*</span>
                    <Form.Control
                      type="text"
                      placeholder="No"
                      value={generatedNum && generatedNum}
                      disabled
                    />
                  </Col>

                  <Col>
                    <Form.Label>Order - Month</Form.Label>
                    <span className="span-star">*</span>

                    <DatePicker
                      className="monthDate"
                      dateFormat="MM-yyyy"
                      showMonthYearPicker
                      name="orderMonth"
                      onChange={(e) => {
                        const dateTime = moment(e).format("yyyy-MM");
                        setGetPoMonth(dateTime);
                      }}
                      disabled={isEdit}
                      // defaultValue={new Date()}
                      selected={getPoMonth ? new Date(moment(getPoMonth)) : ""}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Order Date</Form.Label>
                    <span className="span-star">*</span>
                    <DatePicker
                      name="orderDate"
                      className="monthDate"
                      dateFormat="dd-MMM-yyyy"
                      // disabled={isEdit}
                      onChange={(date) => { setSelectedDate(date)}}
                      selected={selectedDate}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </Col>
                  <Col className="mb-4">
                    <Form.Label>Reference Resource PO</Form.Label>

                    <Form.Control
                      as="select"
                      value={setResourcePoNo}
                      onChange={(e) => handleResourceNumber(e)}
                    >
                      <option value="" key={"res"}>
                        Select Resource
                      </option>
                      {poNoResource &&
                        poNoResource.map((item, index) => {
                          return (
                            <option value={item._id} key={index}>
                              {item.poNo}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Label>Resource Name </Form.Label>
                    <span className="span-star">*</span>
                    <CustomSelect
                      options={resourceOption}
                      placeHolder={<div>Type or select...</div>}
                      onChange={(newValue) => setResourceValue(newValue)}
                      isSearchable
                      onCreateOption={(e) => {
                        createNewFormOption(createOption(e), "resource");
                      }}
                      onDeleteOption={(option) => {
                        deleteFromOption(option, "resource");
                      }}
                      onEditOption={(option,newValue) => {
                        editFromOption(option,newValue, "resource");
                      }}
                      defaultValue={resourceValue}
                      role = {role}
                      isInvalid={errors?.resourceValue  && !resourceValue }

                    />
                    {errors?.resourceValue  && !resourceValue &&
                    <p className="form_error">{errors?.resourceValue}</p>
                    }
                  </Col>
                  <Col>
                    <Form.Label>PAN Number</Form.Label>
                    <span className="span-star">*</span>{" "}
                    {/* <span
                      className="ms-2"
                      style={{ fontSize: "12px", color: "red" }}
                    >
                      {formik.errors.panNumber}
                    </span> */}
                    <div className="input_with-edit">
                      <Form.Control
                        type="text"
                        name="panNumber"
                        placeholder="Resource PAN Number"
                        disabled={editPan}
                        {...formik.getFieldProps("panNumber")}
                        isInvalid={formik.errors.panNumber}
                      />
                      {editPan ? (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                          alt="edit"
                          className="edit-button panEdit"
                          onClick={handleEditPAN}
                        />
                      ) : (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                          alt="right"
                          className="edit-button panEdit"
                          onClick={() => {
                            setEditPan(false);
                            handleAddResourcePan();
                          }}
                        />
                      )}
                    </div>
                    {formik.errors.panNumber &&
                    <p className="form_error">{formik.errors.panNumber}</p>
                    }
                  </Col>
                </Row>
                <Row >
                  <Col className="mb-3">
                    <Form.Label>Remarks </Form.Label>

                    <Form.Control
                      type="text"
                      name="remarks"
                      placeholder="Type Here..."
                      value={remarksValue}
                      // defaultValue={remarksValue}
                      onChange={(e)=>handleRemarksChange(e)}
                      isInvalid={!!errors?.remarks}
                    />
                    <p className="form_error">{errors?.remarks}</p>
                  </Col>

                </Row>
               
                <Row>
                  <Col className="switch-col">
                    <Form.Label>Total Amount</Form.Label>
                    <InputGroup>
                    <InputGroup.Text><span className="rupee-symbol">₹</span> </InputGroup.Text>
                    {/* <Form.Control
                      type="number"
                      placeholder="Total Amount "
                      defaultValue={amountTotal}
                      onChange={(e) => setAmountTotal(e.target.value)}
                    /> */}
                     <CurrencyFormat
                                customInput={Form.Control}
                                type="text"
                                placeholder="Total Amount "
                                onValueChange={(e) => {
                                  setAmountTotal(e.value);
                                }}
                                name="Total Amount "

                               
                                value={amountTotal || ''}
                                thousandSeparator={true}
                                // prefix={"$"}
                                                                                                                                                                                                   
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                    </InputGroup>
                  </Col>
                  <Col className="switch-col">
                    <Form.Label>TDS Percent</Form.Label>
                    <div className="input_with-edit">
                      <Form.Control
                        type="number"
                        placeholder="TDS Percent"
                        defaultValue={tdsPercent}
                        disabled={editPercentNum}
                        onChange={(e) => setTdsPercent(e.target.value)}
                      />
                      {editPercentNum ? (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                          alt="edit"
                          className="edit-button"
                          onClick={handleEditTDSPercentange}
                        />
                      ) : (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                          alt="edit"
                          className="edit-button"
                          onClick={() => setEditPercentNum(true)}
                        />
                      )}
                    </div>
                  </Col>
                  <Col className="switch-col">
                    <Form.Label>TDS Amount</Form.Label>
                    <div className="input_with-edit">
                    <InputGroup>
                    <InputGroup.Text><span className="rupee-symbol">₹</span> </InputGroup.Text>
                      {/* <Form.Control
                        type="number"
                        placeholder="0"
                        value={tdsAmount ? Number(tdsAmount).toFixed(2):Number(0).toFixed(2)}
                        disabled={editTDSAmount}
                        onChange={(e) => setTdsAmount(e.target.value)}
                      /> */}
                       <CurrencyFormat
                                customInput={Form.Control}
                                type="text"
                                onValueChange={(e) => {
                                  setTdsAmount(e.value);
                                }}
                                name="TDS Amount "
                                
                                value={
                                  tdsAmount
                                    ? Number(tdsAmount).toFixed(2)
                                    : Number(0).toFixed(2)
                                }                                
                                thousandSeparator={true}
                                decimalScale={2}
                                disabled={editTDSAmount}
                                fixedDecimalScale={true}
                              />
                      </InputGroup>
                      {editTDSAmount ? (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                          alt="edit"
                          className="edit-button"
                          onClick={handleEditTDS}
                        />
                      ) : (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                          alt="edit"
                          className="edit-button"
                          onClick={handleEditSubmit}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="card1">
                <h5>Payment Information</h5>
                <Row>
                  <Col>
                  <Form.Label>Requested Amount</Form.Label>
                  <span className="span-star">*</span>   
                  <InputGroup>
                  <InputGroup.Text><span className="rupee-symbol">₹</span> </InputGroup.Text>                  
                  {/* <Form.Control
                      type="number"
                      disabled={sendPayAmountRequest && !totalPayAmountIsApproved && (role === 'Admin' || role === 'Manager')}
                      name="approvalAmount"
                      placeholder="Requested Amount"
                      defaultValue={approvalAmount}
                      onChange={(e) => setApprovalAmount(e.target.value)}
                      isInvalid={(remainingAmount < 0 && approvalAmount <= totalPaymentAmount) || !approvalAmount && errors?.approvalAmount}

                    /> */}
                     <CurrencyFormat
                        customInput={Form.Control}
                        type="text"
                        disabled={
                          sendPayAmountRequest &&
                          !totalPayAmountIsApproved &&
                          (role === "Admin" || role === "Manager")
                          &&
                          currUser.name !== editData?.paymentInfo?.requestedBy
                        }
                        name="approvalAmount"
                        placeholder="Requested Amount"
                        value={approvalAmount || ''}
                        onValueChange={(e) => {
                          setApprovalAmount(e.value);
                        }}
                        isInvalid={
                          (remainingAmount < 0 &&
                            approvalAmount <= totalPaymentAmount) ||
                          (!approvalAmount && errors?.approvalAmount)
                        }
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        // prefix={"$"}
                        decimalScale={2}
                      />
                    </InputGroup>
                    {((remainingAmount < 0 && approvalAmount <= totalPaymentAmount) || !approvalAmount)&&
                    <p className="form_error">{errors?.approvalAmount}</p>
                    }
                  </Col>
                  {totalPayAmountIsApproved ?
                  <Col>
                    <Form.Label>Approved Amount</Form.Label>
                    <InputGroup>
                    <InputGroup.Text><span className="rupee-symbol">₹</span> </InputGroup.Text>
                    {/* <Form.Control
                      type="number"
                      disabled
                      name="totalPayAmount"
                      placeholder="Total Amount"
                      defaultValue={totalPaymentAmount}
                    /> */}
                     <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          disabled
                          name="totalPayAmount"
                          placeholder="Total Amount"
                          value={totalPaymentAmount ? totalPaymentAmount : 0}
                          thousandSeparator={true}
                          // prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                    </InputGroup>
                  </Col>
                  :
                  <Col></Col>
                  }
                 
                </Row>

                {
                    totalPayAmountIsApproved  &&
                <Row>
                  <Col>
                  <Form.Label>Net Payable Amount</Form.Label>
                    <InputGroup>
                    <InputGroup.Text><span className="rupee-symbol">₹</span> </InputGroup.Text>
                    {/* <Form.Control
                      type="number"
                      disabled
                      name="remainingAmount"
                      placeholder={totalPaymentAmount ? totalPaymentAmount : 0}
                      defaultValue={remainingAmount}
                      isInvalid={remainingAmount < 0 && approvalAmount <= totalPaymentAmount}

                    /> */}
                    <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          disabled
                          name="Net Payable Amount"
                          // placeholder={
                          //   totalPaymentAmount ? totalPaymentAmount : 0
                          // }
                          // defaultValue={remainingAmount}
                          value={remainingAmount ? remainingAmount : 0}
                          thousandSeparator={true}
                          // prefix={"$"}

                          decimalScale={2}
                          isInvalid={
                            remainingAmount < 0 &&
                            approvalAmount <= totalPaymentAmount
                          }
                          fixedDecimalScale={true}
                        />
                    </InputGroup>
                    {(remainingAmount < 0 && approvalAmount <= totalPaymentAmount)&&
                    <p className="form_error">Net payable amount cannot be negative. Please revise the request with an increased requested amount and resubmit for approval.</p>
                    }
                  </Col>
                <Col>
                    <Form.Label></Form.Label>
                    <div className="horizental-heading">
                      <Button
                        variant="brand"
                        disabled={totalPaymentAmount === null ? true : false}
                        onClick={() => {
                          handleMultiplePayInfo(
                            multipleAddPaymentInfo.length + 1
                          );
                        }}
                        className="add-button mt-1 px-4"
                        size="sm"
                      >
                        Add
                      </Button>
                    </div>
                  </Col>
                </Row>
               }

                {totalPayAmountIsApproved && multipleAddPaymentInfo.map((item, index) => {
                  return (
                    <>
                      <PaymentInfoComponent
                        key={`payment-info-${index}`}
                        getData={(data) => {
                          handleUpdateMultiplePayData(data, index);
                        }}
                        autoCount={index}
                        paymentData={item}
                        editData = {editData}
                      refreshEditData = { refreshEditData }
                      isEdit = {isEdit}
                      handleInvoiceChange = {(item)=>handleInvoiceChange(item)}
                      multipleInvoiceData = {item?.invoice ? item?.invoice :[]}
                      handleUpdateInvoiceData = {(item, index)=>handleUpdateInvoiceData(item,index)}
                      handelDeleteInvoice = {(index,id)=>handelDeleteInvoice(index,id)}
                      handleDeleteInvoiceOnEdit = {(key,listIndex)=>handleDeleteInvoiceOnEdit(key,listIndex)}
                      addNewInvoiceOnEdit = {(file,index,listIndex)=>addNewInvoiceOnEdit(file,index,listIndex)}
                      addRemarkForInvoice = {(value,item,listIndex,index)=>addRemarkForInvoice(value,item,listIndex,index)}
                      expenseType='tds'
                      deleteMultipleListItem = {()=>deleteMultipleListItem(index)}
                      role = {role}

                      />
                    </>
                  );
                })}
                
              </div>
              {totalPayAmountIsApproved &&
              <>
              <div className="card1">
                <h5>GST Applicable</h5>
                <Row>
                  <Col>
                    <Form.Label className="switch-btn">
                      Is GST applicable?
                    </Form.Label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={isGst}
                        onChange={() => setIsGst(!isGst)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </Col>
                </Row>
                {isGst && 
                <>
                <Row>
                <Col className="custom-select">
                    <Form.Label>Company Name</Form.Label>
                    <CustomSelect
                      options={companyTypeOption}
                      placeHolder={<div>Type or select...</div>}
                      onChange={(newValue) => setCompanyTypeValue(newValue)}
                      isSearchable
                      onCreateOption={(e) => {
                        createNewFormOption(
                          createOption(e),
                          "company-type",
                          true
                        );
                      }}
                      onDeleteOption={(option) => {
                        deleteFromOption(option, "company-type");
                      }}
                      onEditOption = {(option,newValue)=>{
                        editFromOption(option,newValue,"company-type")
                      }}
                      defaultValue={companyTypeValue}
                      role = {role}
                    />
                    
                  </Col>
                   <Col>
                    <Form.Label>GST Number</Form.Label>
                    
                    <div className="input_with-edit">
                      <Form.Control
                        type="text"
                        name="gstNumber"
                        placeholder="Company GST Number"
                        disabled={editGst}
                        {...formik.getFieldProps("gstNumber")}
                        isInvalid={formik.errors.gstNumber}
                      />
                      {editGst ? (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                          alt="edit"
                          className="edit-button panEdit"
                          onClick={handleEditGST}
                        />
                      ) : (
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                          alt="right"
                          className="edit-button panEdit"
                          onClick={() => {
                            setEditGst(false);
                            handleAddCompanyGst();
                          }}
                        />
                      )}
                    </div>
                    {formik.errors.gstNumber &&
                    <p className="form_error">{formik.errors.gstNumber}</p>
                    }
                  </Col>
                 
                </Row>
                <Row>
                <Col>
                            <Form.Label>IGST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                type="text"
                                onValueChange={(e) => {
                                  setGstAmount({
                                    ...gstAmount,
                                    igst: e?.value,
                                  });
                                }}
                                name="igst"
                                value={gstAmount?.igst || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </InputGroup>
                          </Col>
                          <Col>
                            <Form.Label>SGST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                name="sgst"
                                type="text"
                                onValueChange={(e) => {
                                  setGstAmount({
                                    ...gstAmount,
                                    sgst: e?.value,
                                  });
                                }}
                                value={gstAmount?.sgst || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </InputGroup>
                          </Col>
                          
                        </Row>
                        <Row>
                        <Col>
                            <Form.Label>CGST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                type="text"
                                onValueChange={(e) => {
                                  setGstAmount({
                                    ...gstAmount,
                                    cgst: e?.value,
                                  });
                                }}
                                name="cgst"
                                value={gstAmount?.cgst || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </InputGroup>
                          </Col>
                          
                        <Col>
                            <Form.Label>Total GST</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <span className="rupee-symbol">₹</span>{" "}
                              </InputGroup.Text>
                              <CurrencyFormat
                                customInput={Form.Control}
                                type="text"
                               
                                name="total_gst"
                                value={(Number(gstAmount?.cgst) + Number(gstAmount?.sgst) + Number(gstAmount?.igst)) || ""}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                </>
                }
             
                </div>
              </>
              }
               {((role === "Manager" || role === "Admin") && activeList === 'pending' && isEdit  && !totalPayAmountIsApproved && sendPayAmountRequest ) &&
              <>
              <div className="card1">
              <h5>Approve OR Reject Remark</h5>

              <Row>
              <Col>
              <Form.Label>Remarks</Form.Label>
              <span className="span-star">*</span>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Type Here..."
                      value={approveOrRejectRemarks}
                      onChange={(e) => setApproveOrRejectRemarks(e.target.value)}
                      isInvalid={ !approveOrRejectRemarks && errors?.approveOrRejectRemarks}

                      />
                   
                      { !approveOrRejectRemarks &&
                      <p className="form_error">{errors?.approveOrRejectRemarks}</p>
                      }
                  </Col>
              </Row>  

              </div>
              </>
              }
              <Row>
              {((role === "Manager" || role === "Admin") && activeList === 'pending' && isEdit  && !totalPayAmountIsApproved && sendPayAmountRequest ) ?
                <ModalFooter style={{alignItems:'center',justifyContent:'center'}}>
                  
                {
                  (!totalPayAmountIsApproved) &&
                  <div className="d-flex"> 
                    <Button
                      variant="brand"
                      disabled={totalPaymentAmount === null ? true : false}
                      onClick={() => {
                        handleTotalPayAmountIsApproved();
                      }}
                      className="submit-btn me-2"
                    >
                      Approve
                    </Button>
                    
                    <Button
                  className="submit-btn"
                  variant="brand"
                  disabled={totalPaymentAmount === null ? true : false}
  
                  onClick={() => handleTotalPayAmountIsRejected()}
                  style={{ background: "#191063" }}
                >
                  Reject
                </Button>
                  </div>
              
                }
              </ModalFooter>
              :
                <ModalFooter>
                   {
                    ((!totalPayAmountIsApproved && !sendPayAmountRequest) || (totalSpentAmount > totalPaymentAmount)) ?
                
                      <Button
                        variant="brand"
                        disabled={totalPaymentAmount === null ? true : false}
                        onClick={() => {
                          handleAmountApproveBtn(formik.values);
                        }}
                        className="submit-btn"
                      >
                        Send Request
                      </Button>
                      :
                  <Button
                    type="submit"
                    className="submit-btn"
                    variant="brand"
                    style={{ background: "#191063" }}
                    onClick={() => setCompareDataModal(true)}
                  >
                    Submit
                  </Button>
                 }

                </ModalFooter>
             }

              </Row>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
      {compareDataModal && comparingData && (
        <DataChangeModalOnEdit
          openModal={compareDataModal}
          closeModal={closeComparedDataModal}
          editData={editData}
          currentData={comparingData}
          isConfirmed={() => {
            submitUpdateData(comparingData, editData);
          }}
          type={"tds"}
        />
      )}

    </>
  );
};

export default TdsAddNew;
