import {
  Button,
  Flex,
  Input,
  Select,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import AddNew from "components/allForm/addNew";
import InternalAddNew from "components/allForm/internalAddNew";
import { Col, Row } from "react-bootstrap";

const ColumnTable = (props) => {
  const { columnsData, tableData, type,expenseType } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  initialState.pageSize = 10;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleRowClick =(e,_id)=>{
    ['internal','external','resource','tds','directTax','gst'].includes(expenseType) && props?.openInfoModal(e,_id)
    
  }

  return (
    <>
      <Flex px="25px" justify="space-between" mb="20px" >
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {type}
        </Text>
      </Flex>
      <Card
        direction="column"
        w="100%"
        px="0px"
        h="70vh"
        justifyContent="space-between"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
            {['internal','external','resource','tds','directTax'].includes(expenseType) &&
            <div className="main-table-heading">
              {props?.activeDataList === 'pending' ? 'Pending for Approval' : props?.activeDataList === 'rejected' ? 'Rejected Data' : 'Approved Data'}
            </div>
            }
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px" mt="20px">

          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                      className="flex-header"
                    >
                      {column.render("Header")}
                      <span className="ms-2" style={{ width: "20px" }}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "🔽"
                            : "🔼"
                          : ""}
                      </span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";

                    data = (
                      <Flex align="center">
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                    return (
                      <Td
                        {...cell.getCellProps()}
                        cursor="pointer"
                        key={index}
                        onClick={(e)=> (cell.column?.Header !== 'Action' && cell.column?.Header !== 'Invoice') && handleRowClick(e,row?.original?._id)}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                        // textTransform='uppercase'
                        // onClick={() => {
                        //   getAllData(cell.row.original);
                        // }}
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Row>
          <Flex px="25px" mb="20px" align="center" justify="end">
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </Button>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>{" "}
            <span className="w-auto me-2">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span className="w-auto  me-2">
              | Go to:{" "}
              <Input
                type="number"
                defaultValue={pageIndex}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "60px" }}
              />
            </span>
            <div className="w-25">
              <Select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 30].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            </div>
          </Flex>
        </Row>
      </Card>
    </>
  );
};

export default ColumnTable;
