import React, { useEffect, useState } from "react";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import {
  Box,
  Button,
  Tooltip,
} from "@chakra-ui/react";

import ResourceAddNew from "components/allForm/resourceAddNew";
import ColumnTable from "./embrillTable/ColumnTable";
import { columnsDataColumns ,rejectedDataColumns} from "./embrillTable/variables/resourceColData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncGetResourceExpense } from "redux/resourceSlice";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import PdfListModel from "components/models/PdfListModel";
import { fetchAsyncDeleteResourceExpense } from "redux/resourceSlice";
import { fetchAsyncDeleteResourcePdf } from "redux/resourceSlice";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { currencyFormate } from "jsFunctions/helperFunctions";
import ExportXLSFile from "components/ExportXLSFile";
import moment from "moment";
import Swal from "sweetalert2";
import { FcApproval } from "react-icons/fc";
import { MdOutlinePendingActions } from "react-icons/md";
import { CiViewTable } from "react-icons/ci";
import { PiGraph } from "react-icons/pi";
import { GrResources } from "react-icons/gr";
import ExpenseReports from "./expenseGraph";
import ResourceInfoModel from "components/models/ResourceInfoModel";
import { RxCrossCircled } from "react-icons/rx";
import ExportDataModel from "components/exportData/ExportDataModel";

const EmbrillResourceExpense = () => {
  const [addNewModal, setAddNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [resourceEditData, setResourceEditData] = useState(null);
  const [resourceListData, setResourceListData] = useState([]);
  const dispatch = useDispatch();
  const resourceList = useSelector((state) => state.resourceData);
  const currUser = useSelector((state) => state.auth.currUser);
  const [resourcePdfList, setResourcePdfList] = useState(null);
  const [showPdfList, setShowPdfList] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [activeDataList,setActiveDataList] = useState('approved')
  const [invoiceData,setInvoiceData] = useState([])
  const [activeGraph,setActiveGraph] = useState(false)
  const [openResourceInfoModel, setOpenResourceInfoModel] = useState(false);


  const [role,setRole] = useState('')  

  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Resource/Service Expense');
      if(roleObj){

        setRole(roleObj?.access)
      }
    }
   
  },[currUser])

  const handleModalOpen = () => {
    setAddNewModal(true);
  };
  const handleModalClose = () => {
    setAddNewModal(false);
    setOpenEditModal(false);
    setResourceEditData(null);
  };
  useEffect(() => {
    dispatch(fetchAsyncGetResourceExpense());
  }, []);

  const handlerRefreshData = () => {
    dispatch(fetchAsyncGetResourceExpense());
  };

  const deleteResourceData = (data) => {
    dispatch(fetchAsyncDeleteResourceExpense(data._id)).then(() => {
      handlerRefreshData();
    });
  };
  const deleteFileFromList = (sendData) => {
    dispatch(fetchAsyncDeleteResourcePdf(sendData)).then((response) => {
      handlerRefreshData();
    });
  };

  const getAllApprovedData = ()=>{
    if (resourceList && resourceList.length > 0) {
      let listData = [];
      if(role !== "Accountant")
        {
          listData = resourceList.filter((item)=> item.paymentInfo.totalPayAmountIsApproved === true)
  
        } else {
  
          listData = resourceList.filter((item)=> item.paymentInfo.totalPayAmountIsApproved === true && currUser.name === item?.paymentInfo?.requestedBy)
        }
        
      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item && item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        return {
          _id:data._id,
          no: index + 1,
          poNo: data.poNo && poNumberFormate(data.poNo),
          purchasedate: dateTimeFormat(data.orderDate),
          majorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.expense?.majorType}
            </span>
          ),
          minorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.minorType}
            </span>
          ),
          approvalAmount: (
            <span
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ), 
          invoice:
          foundInvoiceWithLength ? (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowPdfList(true);
                  setResourcePdfList(data);
                }}
              />
            ) : (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "not-allowed" }}
                color="gray"
              />
            ),
          action: (
            <div className="d-flex">
              <AiFillEdit
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setResourceEditData(data);
                  setOpenEditModal(true);
                  handleModalOpen();
                }}
              />
              {role === "Admin" && (
                <AiFillDelete
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                      text:'This action cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteResourceData(data);
                      }
                    });
                  }}
                />
              )}
            </div>
          ),
        };
      });

      setResourceListData(tableData);
      if (resourcePdfList) {
        const dataGet = resourceList.filter(
          (item) => item._id === resourcePdfList._id
        );
        setResourcePdfList(dataGet[0]);
      }
      if (resourceEditData) {
        const dataGet = resourceList.filter(
          (item) => item._id === resourceEditData._id
        );
        setResourceEditData(dataGet[0]);
      }
    } else {
      setResourceListData([]);
    }
  }

  const getPendingApproveData = ()=>{
    if (resourceList && resourceList.length > 0) {
      let listData = []
      if(role !== "Accountant")
      {
        listData = resourceList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === false))

      } else {
        listData = resourceList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === false && currUser.name === item?.paymentInfo?.requestedBy))
       
      }

      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item && item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        return {
          _id:data._id,
          no: index + 1,
          poNo: data.poNo && poNumberFormate(data.poNo),
          purchasedate: dateTimeFormat(data.orderDate),
          majorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.expense?.majorType}
            </span>
          ),      
          minorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.minorType}
            </span>
          ),
          approvalAmount: (
            <span 
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),     
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
          </span>
            
          ),           invoice:
          foundInvoiceWithLength ? (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowPdfList(true);
                  setResourcePdfList(data);
                }}
              />
            ) : (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "not-allowed" }}
                color="gray"
              />
            ),
          action: (
            <div className="d-flex">
              <AiFillEdit
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setResourceEditData(data);
                  setOpenEditModal(true);
                  handleModalOpen();
                }}
              />
              {role === "Admin" && (
                <AiFillDelete
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                      text:'This action cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteResourceData(data);
                      }
                    });
                  }}
                />
              )}
            </div>
          ),
        };
      });

      setResourceListData(tableData);
      if (resourcePdfList) {
        const dataGet = resourceList.filter(
          (item) => item._id === resourcePdfList._id
        );
        setResourcePdfList(dataGet[0]);
      }
      if (resourceEditData) {
        const dataGet = resourceList.filter(
          (item) => item._id === resourceEditData._id
        );
        setResourceEditData(dataGet[0]);
      }
    } else {
      setResourceListData([]);
    }
  }
  const getRejectedData = ()=>{
    if (resourceList && resourceList.length > 0) {
      let listData = []
      if(role !== "Accountant")
      {
        listData = resourceList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === true))

      } else {

        listData = resourceList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === true && currUser?.name === item?.paymentInfo?.requestedBy))
      }

      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item && item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        console.log(foundInvoiceWithLength)

        return {
          _id:data._id,
          no: index + 1,
          poNo: data.poNo && poNumberFormate(data.poNo),
          purchasedate: dateTimeFormat(data.orderDate),
          majorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.expense?.majorType}
            </span>
          ),      
          minorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.minorType}
            </span>
          ),
          approvalAmount: (
            <span 
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),     
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
          </span>
            
          ),
          status: 'Rejected',
          action: (
            <div className="d-flex">
            
              {
              role === "Admin" ? (
                <AiFillDelete
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                      text:'This action cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteResourceData(data);
                      }
                    });
                  }}
                />
              )
              :
              'Not Access'
            }
            </div>
          ),           
        };
      });

      setResourceListData(tableData);
      if (resourcePdfList) {
        const dataGet = resourceList.filter(
          (item) => item._id === resourcePdfList._id
        );
        setResourcePdfList(dataGet[0]);
      }
      if (resourceEditData) {
        const dataGet = resourceList.filter(
          (item) => item._id === resourceEditData._id
        );
        setResourceEditData(dataGet[0]);
      }
    } else {
      setResourceListData([]);
    }
  }
  useEffect(() => {
    if(activeDataList === 'approved'){
      getAllApprovedData();
     }
     else if(activeDataList === 'pending'){

      getPendingApproveData();
    }
    else{
     getRejectedData();
    }
  }, [resourceList]);

  useEffect(()=>{
    handlerRefreshData();

  },[activeDataList])

  const handleGraph = ()=>{
    setActiveGraph(true)
  }
  const handleTable = ()=>{
    setActiveGraph(false)
  }

  const handleRowClick = (e,_id)=>{
    e.stopPropagation();
    const data = resourceList.filter((item)=> item?._id === _id)
    setSelectedItem(data[0]);
    setOpenResourceInfoModel(true);
  }

  const closeResourceInfoModel = () => {
    setOpenResourceInfoModel(false);
    setSelectedItem({});
  };

  useEffect(()=>{
    if (resourcePdfList) {
      const dataGet = resourceList.filter(
        (item) => item._id === resourcePdfList._id
      );
      const data = dataGet[0];
      const paymentList = data?.paymentInfo?.list
      let invoices = []
      if(paymentList?.length > 0){
        invoices = paymentList?.flatMap((item, index) => {
          if (item?.invoice) {
            return item.invoice.map(invoice => ({...invoice, listIndex: index }));
          }
          return []; // or return an empty array to avoid undefined values
        });
      }

      setInvoiceData(invoices)
      setResourcePdfList(dataGet[0]);
    }
  },[resourcePdfList])

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <div className="button-div">
        <Tooltip label='Rejected' fontSize='md' borderRadius='2xl'>
          <Button className="add-btn" onClick={()=>setActiveDataList('rejected')}>
            <RxCrossCircled size={30} color="red" />
          </Button>
          </Tooltip>
          {resourceList && (
           <ExportDataModel
           filename={"Resource_"}
           type = "resource"
           role = {role}
          />
          )}

          <Tooltip label='Add New' fontSize='md' borderRadius='2xl'>
          <Button className="btn add-btn" onClick={handleModalOpen}>
          
          <img
            src="https://cdn-icons-png.flaticon.com/128/148/148764.png"
            alt="add"
            style={{ width: "30px", height: "30px", margin: "9px" }}
          />
        </Button>
          </Tooltip>  
         
        
        </div>
        {
        <div className={`button-div list-btn ${activeGraph ? 'list-btn-disabled' : ''}`}>
          {/* {activeDataList === 'pending' ? */}
          <Tooltip label='Approved Data' fontSize='md' borderRadius='2xl'> 
          <Button className={`btn ${activeDataList !== 'pending' ? 'list-btn-active':''}`} onClick={()=>setActiveDataList('approved')}  disabled={activeGraph}>
             <FcApproval size={30}/>
            
            
          </Button>
          </Tooltip>
            
          {/* : */}

          <Tooltip label='Pending List' fontSize='md' borderRadius='2xl'>

          <Button className={`btn ${activeDataList === 'pending' ? 'list-btn-active':''}`} onClick={()=>setActiveDataList('pending')} disabled={activeGraph}>
             <MdOutlinePendingActions size={30}  />          
          </Button>
          </Tooltip>
{/* } */}
        </div>
        }
      </Box>
      <div 
        className="graph_button button-div resource-graph-btn"        
       >
        <div  className={`btn graph_icon_button ${!activeGraph ? 'active_graph' : ''}`}  onClick={()=>handleTable()}><CiViewTable  /></div>
        {role !== 'Accountant' ?
        <div  className={`btn graph_icon_button ${activeGraph ? 'active_graph' : ''}`} onClick={()=>handleGraph()}><PiGraph/></div>
        :
        <div  className={`btn graph_icon_button ${activeGraph ? 'active_graph' : ''}`} style={{opacity:'0.3',cursor:'not-allowed'}}><PiGraph/></div>
        }
        
       </div>
      <Box pt={{ base: "180px", md: "120px", xl: "120px" }}>
      {activeGraph ?
      <Box className={`graph-card ${activeGraph ? 'graph_active' : ''}`}>
          
      <ExpenseReports 
        type='resource'
        name = 'Resource Total Expense'
        heading = "Resource/Service Expense"
        icon = {GrResources}
        role={role}
      /> 
      </Box>
      :
        <ColumnTable
        columnsData = {activeDataList === 'rejected' ?rejectedDataColumns :columnsDataColumns}  
          tableData={resourceListData}
          type={"Resource/Service Expense"}
          openInfoModal = {handleRowClick}
          expenseType = 'resource'

          activeDataList = {activeDataList}
          role={role}

        />
      }
      </Box>
      {addNewModal && (
        <ResourceAddNew
          resourceOpenModal={addNewModal}
          resourceCloseModal={handleModalClose}
          isEditForm={openEditModal}
          editItem={resourceEditData}
          refreshEditData={handlerRefreshData}
          activeList = {activeDataList}
          role={role}

        />
      )}
      <PdfListModel
          pdfData={resourcePdfList ? invoiceData : []}
          currentId={resourcePdfList ? resourcePdfList._id : ""}
        openModal={showPdfList}
        closeModal={() => {
          setShowPdfList(false);
          setResourcePdfList(null);
        }}
        deleteFileFromList={deleteFileFromList}
        role={role}
      />
      {openResourceInfoModel && (
        <ResourceInfoModel
          openInfoModal={openResourceInfoModel}
          closeInfoModal={closeResourceInfoModel}
          selectedData={selectedItem}        
          role={role}
        />
      )}
    </>
  );
};

export default EmbrillResourceExpense;
