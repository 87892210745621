import { saveAs } from 'file-saver';

/**
 * Fetches a ZIP file from the backend API and triggers a download.
 *
 * @param {string[]} keys - An array of S3 object keys to be included in the ZIP file.
 */
const apiURL = process.env.REACT_APP_BASE_URL;

export const downloadAndCreateZip = async (keys,filename) => {
    if (!keys || keys.length === 0) {
        console.error('No keys provided for download.');
        return;
    }

    try {
        const response = await fetch(`${apiURL}/download-invoice?keys=${encodeURIComponent(keys.join(','))}`, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Get the response as a Blob
        const blob = await response.blob();

        // Generate a URL for the Blob and trigger a download
        const url = window.URL.createObjectURL(blob);
        saveAs(blob, `${filename}.zip`); // Use file-saver to save the file

        // Clean up
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error fetching or downloading ZIP file:', error);
    }
};
