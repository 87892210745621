import React, { useState } from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FaTrash } from "react-icons/fa6";
import { useToasts } from "react-toast-notifications";

const InvoiceComponent = ({
  getInvoice,
  handleDelete,
  selectedFile,
  handleDeleteOnEdit,
  isEditing,
  addNewInvoiceOnEdit,
  submitInvRemark,
  role
}) => {
  const [editPan, setEditPan] = useState(false);
  const [remark, setRemark] = useState(selectedFile.remark || "");

  const { addToast } = useToasts();
  const handleFileChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size < 2 * 1024 * 1024) {
        if (isEditing) {
          addNewInvoiceOnEdit(e.target.files[0]);
        } else {
          getInvoice(e.target.files[0]);
        }
      } else {
        addToast("Please attach file less then 2Mb", { appearance: "warning" });
      }
    }
  };
  return (
    <>
      <div>
        <Row>
          {selectedFile.Key ? (
            <>
              <Col md={5}>
                <Form.Label>Invoice Attached</Form.Label>

                <Form.Control
                  type="input"
                  name="invoice"
                  placeholder="PDF"
                  disabled
                  accept="application/pdf"
                  value={selectedFile.Key?.split("/").at(-1)}
                />
              </Col>
              {isEditing && (
                <Col className="align-items-center" md={5}>
                  <Form.Label>Invoice Remark</Form.Label>
                  <div className="input_with-edit">
                    <Form.Control
                      type="text"
                      name="panNumber"
                      placeholder="Remark"
                      disabled={!editPan}
                      onChange={(e) => setRemark(e.target.value)}
                      value={remark}
                    />
                    {!editPan ? (
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                        alt="edit"
                        className="edit-button panEdit"
                        onClick={() => {
                          setEditPan(true);
                        }}
                      />
                    ) : (
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                        alt="right"
                        className="edit-button panEdit"
                        onClick={() => {
                          submitInvRemark(remark);
                          setEditPan(false);
                        }}
                      />
                    )}
                  </div>
                </Col>
              )}
              <Col className="d-flex align-items-center">
                {(role === "Admin" || role === "SuperAdmin") && (
                  <div className="mt-2">
                    <FaTrash
                      style={{
                        fontSize: "16px",
                        color: "red",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteOnEdit(selectedFile.Key)}
                    />
                  </div>
                )}
              </Col>
            </>
          ) : (
            <>
              <Col>
                <Form.Label>
                  Invoice (<span>*</span>.pdf)
                </Form.Label>

                <Form.Control
                  type="file"
                  name="invoice"
                  placeholder="PDF"
                  onChange={(e) => handleFileChange(e)}
                  accept="application/pdf"
                />
              </Col>
              <Col className="d-flex align-items-center">
                <div className="mt-2">
                  <FaTrash
                    style={{
                      fontSize: "16px",
                      color: "red",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete()}
                  />
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default InvoiceComponent;
