import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  
  Tooltip,
} from "@chakra-ui/react";

import { FcApproval } from "react-icons/fc";
import {  MdOutlinePendingActions } from "react-icons/md";
import { CiViewTable } from "react-icons/ci";

import InternalAddNew from "components/allForm/internalAddNew";
import ColumnTable from "./embrillTable/ColumnTable";
import { columnsDataColumns, rejectedDataColumns } from "./embrillTable/variables/internalColData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncGetInternalExpense } from "redux/internalSlice";
import { BsFillFileEarmarkPdfFill, BsFillHouseHeartFill } from "react-icons/bs";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import PdfListModel from "components/models/PdfListModel";
import { fetchAsyncDeleteInternalPdf } from "redux/internalSlice";
import Swal from "sweetalert2";
import { fetchAsyncDeleteInternalExpense } from "redux/internalSlice";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { currencyFormate } from "jsFunctions/helperFunctions";
import ExportXLSFile from "components/ExportXLSFile";
import moment from "moment";
import InternalInfoModel from "components/models/InternalInfoModel";
import ExpenseReports from "./expenseGraph";
import { PiGraph } from "react-icons/pi";
import { RxCrossCircled } from "react-icons/rx";
import ExportDataModel from "components/exportData/ExportDataModel";

const EmbrillInternal = () => {
  const [addNewModal, setAddNewModal] = useState(false);
  const [internalListData, setInternalListData] = useState([]);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [internalEditData, setInternalEditData] = useState(null);
  const [internalPdfList, setInternalPdfList] = useState(null);
  const [showPdfList, setShowPdfList] = useState(false);
  const [openInternalInfoModel, setOpenInternalInfoModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [activeDataList,setActiveDataList] = useState('approved')
  const [invoiceData,setInvoiceData] = useState([])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncGetInternalExpense()).then((res) => {
      // console.log("rcfgvhjbk", res);
    });
  }, []);

  
  const handleModalOpen = () => {
    setAddNewModal(true);
  };
  const handleModalClose = () => {
    setAddNewModal(false);
    setOpenEditModal(false);
    setInternalEditData(null);
  };
  const internalList = useSelector((state) => state.internalData);
  const currUser = useSelector((state) => state.auth.currUser);
  const [role,setRole] = useState('')  

  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Internal-Local expense');
      if(roleObj){

        setRole(roleObj?.access)
      }
    }
   
  },[currUser])



  
  const handleRowClick = (e,_id)=>{
    e.stopPropagation();
    const data = internalList.filter((item)=> item?._id === _id)
    setSelectedItem(data[0]);
    setOpenInternalInfoModel(true);
  }

  const closenternalInfoModel = () => {
    setOpenInternalInfoModel(false);
    setSelectedItem({});
  };

  const getAllApprovedData = ()=>{
    if (internalList && internalList.length > 0) {
      let listData = [];
      
      if(role !== "Accountant")
        {
          listData = internalList.filter((item)=> item.paymentInfo.totalPayAmountIsApproved === true)
  
        } else {
  
          listData = internalList.filter((item)=> item.paymentInfo.totalPayAmountIsApproved === true && currUser.name === item?.paymentInfo?.requestedBy)
        }

      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item && item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        return {
          _id:data._id,
          no: index + 1,
          poNo: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.poNo && poNumberFormate(data.poNo)}
            </span>
          ),
          purchasedate: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {dateTimeFormat(data.orderDate)}
            </span>
          ),
          majorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.expense?.majorType}
            </span>
          ),
          minorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.minorType}
            </span>
          ),
          approvalAmount: (
            <span
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
          invoice:
          foundInvoiceWithLength  ? (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowPdfList(true);
                  setInternalPdfList(data);
                }}
              />
            ) : (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "not-allowed" }}
                color="gray"
              />
            ),
          action: (
            <div className="d-flex">
              <AiFillEdit
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditModal(true);
                  setInternalEditData(data);
                  handleModalOpen();
                }}
              />
              {role === "Admin" && (
                <AiFillDelete
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                      text:'This action cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteInternalData(data);
                      }
                    });
                  }}
                />
              )}
            </div>
          ),
        };
      });
      setInternalListData(tableData);
      if (internalPdfList) {
        const dataGet = internalList.filter(
          (item) => item._id === internalPdfList._id
        );
        setInternalPdfList(dataGet[0]);
      }
      if (internalEditData) {
        const dataGet = internalList.filter(
          (item) => item._id === internalEditData._id
        );
        setInternalEditData(dataGet[0]);
      }
    } else {
      setInternalListData([]);
    }
  }


  useEffect(()=>{
    if (internalPdfList) {
      const dataGet = internalList.filter(
        (item) => item._id === internalPdfList._id
      );
      const data = dataGet[0];
      const paymentList = data?.paymentInfo?.list
      let invoices = []
      if(paymentList?.length > 0){
        invoices = paymentList?.flatMap((item, index) => {
          if (item?.invoice) {
            return item.invoice.map(invoice => ({...invoice, listIndex: index }));
          }
          return []; // or return an empty array to avoid undefined values
        });
      }

      setInvoiceData(invoices)
      setInternalPdfList(dataGet[0]);
    }
  },[internalPdfList])
  const getPendingApproveData = ()=>{
    if (internalList && internalList.length > 0) {
      let listData = []
      
      if(role !== "Accountant")
      {
        listData = internalList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === false))

      } else {

        listData = internalList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === false && currUser.name === item?.paymentInfo?.requestedBy))
      }
      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item && item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        return {
          _id:data._id,
          no: index + 1,
          poNo: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.poNo && poNumberFormate(data.poNo)}
            </span>
          ),
          purchasedate: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {dateTimeFormat(data.orderDate)}
            </span>
          ),
          majorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.expense?.majorType}
            </span>
          ),      
          minorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.minorType}
            </span>
          ),
          approvalAmount: (
            <span 
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),     
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} /> }
          </span>
            
          ),                      
                         
          invoice:
          foundInvoiceWithLength ? (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowPdfList(true);
                  setInternalPdfList(data);
                }}
              />
            ) : (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "not-allowed" }}
                color="gray"
              />
            ),
          action: (
            <div className="d-flex">
              <AiFillEdit
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditModal(true);
                  setInternalEditData(data);
                  handleModalOpen();
                }}
              />
              {role === "Admin" && (
                <AiFillDelete
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                      text:'This action cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteInternalData(data);
                      }
                    });
                  }}
                />
              )}
            </div>
          ),
        };
      });
      setInternalListData(tableData);
      if (internalPdfList) {
        const dataGet = internalList.filter(
          (item) => item._id === internalPdfList._id
        );
        setInternalPdfList(dataGet[0]);
      }
      if (internalEditData) {
        const dataGet = internalList.filter(
          (item) => item._id === internalEditData._id
        );
        setInternalEditData(dataGet[0]);
      }
    } else {
      setInternalListData([]);
    }
  }
  const getRejectedData = ()=>{
    if (internalList && internalList.length > 0) {
      let listData = [];
      if(role !== "Accountant")
      {

        listData = internalList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item?.paymentInfo?.totalPayAmountIsRejected === true))
      }
      else{

        listData = internalList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item?.paymentInfo?.totalPayAmountIsRejected === true && currUser?.name === item?.paymentInfo?.requestedBy))
      }
      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item && item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        console.log(foundInvoiceWithLength)

        return {
          _id:data._id,
          no: index + 1,
          poNo: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.poNo && poNumberFormate(data.poNo)}
            </span>
          ),
          purchasedate: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {dateTimeFormat(data.orderDate)}
            </span>
          ),
          majorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.expense?.majorType}
            </span>
          ),      
          minorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.minorType}
            </span>
          ),
          approvalAmount: (
            <span 
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),     
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
          </span>
            
          ),  
          status:'Rejected',
          action: (
            <div className="d-flex">
            
              {
              role === "Admin" ? (
                <AiFillDelete
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                      text:'This action cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteInternalData(data);
                      }
                    });
                  }}
                />
              )
              :
              'Not Access'
            }
            </div>
          ),                    
                         
        };
      });
      setInternalListData(tableData);
      if (internalPdfList) {
        const dataGet = internalList.filter(
          (item) => item._id === internalPdfList._id
        );
        setInternalPdfList(dataGet[0]);
      }
      if (internalEditData) {
        const dataGet = internalList.filter(
          (item) => item._id === internalEditData._id
        );
        setInternalEditData(dataGet[0]);
      }
    } else {
      setInternalListData([]);
    }
  }

  useEffect(() => {
   if(activeDataList === 'approved'){
    getAllApprovedData();
   }
   else if(activeDataList === 'pending'){

     getPendingApproveData();
   }
   else{
    getRejectedData();
   }
  }, [internalList]);

  useEffect(()=>{
    handlerRefreshData();

  },[activeDataList])

  const deleteInternalData = (data) => {
    dispatch(fetchAsyncDeleteInternalExpense(data._id)).then(() => {
      handlerRefreshData();
    });
  };

  const handlerRefreshData = () => {
    dispatch(fetchAsyncGetInternalExpense());
  };

  const deleteFileFromList = (sendData) => {
    dispatch(fetchAsyncDeleteInternalPdf(sendData)).then((response) => {
      handlerRefreshData();
    });
  };

  const [activeGraph,setActiveGraph] = useState(false)
  const handleGraph = ()=>{
    setActiveGraph(true)
  }
  const handleTable = ()=>{
    setActiveGraph(false)
  }
  return (
    <>
    
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      
        <div className="button-div">
        <Tooltip label='Rejected' fontSize='md' borderRadius='2xl'>
          <Button className="add-btn" onClick={()=>setActiveDataList('rejected')}>
            <RxCrossCircled size={30} color="red" />
          </Button>
          </Tooltip>
          {internalList && (
        // <Tooltip label='Export Data' fontSize='md' borderRadius='2xl'>
            
        //    <Button className="add-btn mt-2"><ExportXLSFile   excelData={getExcelData()} filename={"Internal_"} /></Button> 
        // </Tooltip>
            <ExportDataModel
             filename={"Internal_"}
             type = "internal"
             role = {role}
            />
          )}

          <Tooltip label='Add New' fontSize='md' borderRadius='2xl'>
          <Button className="add-btn" onClick={handleModalOpen}>
            
            <img
              src="https://cdn-icons-png.flaticon.com/128/148/148764.png"
              alt="add"
              style={{ width: "30px", height: "30px", margin: "9px" }}
            />
          </Button>
          </Tooltip>
        </div>
        {
        <div className={`button-div list-btn ${activeGraph ? 'list-btn-disabled' : ''}`}>
          {/* {activeDataList === 'pending' ? */}
          <Tooltip label='Approved Data' fontSize='md' borderRadius='2xl'> 
          <Button className={`btn ${activeDataList !== 'pending' ? 'list-btn-active':''}`} onClick={()=>setActiveDataList('approved')}  disabled={activeGraph}>
             <FcApproval size={30}/>
            
            
          </Button>
          </Tooltip>
            
          {/* : */}

          <Tooltip label='Pending List' fontSize='md' borderRadius='2xl'>

          <Button className={`btn ${activeDataList === 'pending' ? 'list-btn-active':''}`} onClick={()=>setActiveDataList('pending')} disabled={activeGraph}>
             <MdOutlinePendingActions size={30}  />          
          </Button>
          </Tooltip>
{/* } */}
        </div>
        }
       
      </Box>
      <div 
        className="graph_button button-div"        
       >
        <div  className={`btn graph_icon_button ${!activeGraph ? 'active_graph' : ''}`}  onClick={()=>handleTable()}><CiViewTable  /></div>
        {role !== 'Accountant' ?
        <div  className={`btn graph_icon_button ${activeGraph ? 'active_graph' : ''}`} onClick={()=>handleGraph()}><PiGraph/></div>
        :
        <div  className={`btn graph_icon_button ${activeGraph ? 'active_graph' : ''}`} style={{opacity:'0.3',cursor:'not-allowed'}}><PiGraph/></div>
        }
        
       </div>
       
      <Box pt={{ base: "180px", md: "120px", xl: "120px" }}>
        {activeGraph ?
        <Box className={`graph-card ${activeGraph ? 'graph_active' : ''}`}>
          
          <ExpenseReports 
            type='internal'
            name = 'Internal Total Expense'
            heading = "Internal-Local Expense"
            icon = {BsFillHouseHeartFill}
            role={role}
          /> 
          </Box>
        :
        <ColumnTable
          columnsData = {activeDataList === 'rejected' ?rejectedDataColumns :columnsDataColumns} 
          tableData={internalListData}
          openInfoModal = {handleRowClick}
          type={"Internal - Local expenses"}
          activeDataList = {activeDataList}
          expenseType = 'internal'
          role={role}


        />
        }
       
      </Box>
      {addNewModal && (
        <InternalAddNew
          openInternalModal={addNewModal}
          closeInternalModal={handleModalClose}
          isEditForm={openEditModal}
          editItem={internalEditData}
          refreshEditData={handlerRefreshData}
          activeList = {activeDataList}
          role={role}
        />
      )}
      {showPdfList && (
        <PdfListModel
          pdfData={internalPdfList ? invoiceData : []}
          currentId={internalPdfList ? internalPdfList._id : ""}
          openModal={showPdfList}
          closeModal={() => {
            setShowPdfList(false);
            setInternalPdfList(null);
          }}
          deleteFileFromList={deleteFileFromList}
          role={role}

        />
      )}
      {openInternalInfoModel && (
        <InternalInfoModel
          openInfoModal={openInternalInfoModel}
          closeInfoModal={closenternalInfoModel}
          selectedData={selectedItem}        
          role={role}

        />
      )}
    </>
  );
};

export default EmbrillInternal;
