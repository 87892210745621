/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { FaTrash } from "react-icons/fa6";
import { FaCheckCircle, FaEdit } from "react-icons/fa";

// Icon component
const Icon = ({ isOpen }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="18"
      height="18"
      stroke="#222"
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={isOpen ? "translate" : ""}
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
};

// CustomSelect component
const CustomSelect = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  align,
  onCreateOption,
  onDeleteOption,
  onEditOption,
  defaultValue,
  role,
  isInvalid
}) => {
  // State variables using React hooks
  const [showMenu, setShowMenu] = useState(false); // Controls the visibility of the dropdown menu
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null); // Stores the selected value(s)
  const [searchValue, setSearchValue] = useState(""); // Stores the value entered in the search input
  const searchRef = useRef(); // Reference to the search input element
  const inputRef = useRef(); // Reference to the custom select input element

  const [editValue,setEditValue] = useState("");
  const [editActive,setEditActive] = useState(false)
  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    if (defaultValue && defaultValue.value) {
      setSelectedValue(defaultValue);
    } else {
      setSelectedValue(null);
    }
  }, [defaultValue]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("mousedown", handler);
    return () => {
      window.removeEventListener("mousedown", handler);
    };
  }, [inputRef]);

  const handleInputClick = (e) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }
    return selectedValue.label;
  };

  const removeOption = (option) => {
    return selectedValue.filter((o) => o.value !== option.value);
  };

  const onItemClick = (option) => {
    let newValue;
    if (isMulti) {
      if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
    setShowMenu(false);
  };

  const isSelected = (option) => {
    if (isMulti) {
      return selectedValue.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === option.value;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  const deleteOption = (option) => {
    Swal.fire({
      title: "Are You Sure? Delete This Option",
      text: `Delete ${option.label}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteOption(option);
      }
    });
  };

  const faEditClick = (option)=>{
    setEditValue(option?.value);
    setEditActive(true)
  }
  const faCheckMarkClick = (option)=>{
    onEditOption(option,editValue);
    setEditValue('');
    setEditActive(false)
  }

  return (
    <div ref={inputRef} className="custom--dropdown-container">
      <div onClick={handleInputClick} className={`dropdown-input`}>
        <div className={`dropdown-selected-value`}>{getDisplay()}</div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <Icon isOpen={showMenu} />
          </div>
        </div>
      </div>

      {showMenu && (
        <div className={`dropdown-menu alignment--${align || "auto"}`}>
          {isSearchable && (
            <div className="search-box">
              <input
                className="form-control"
                onChange={onSearch}
                value={searchValue}
                ref={searchRef}
                isInvalid = {isInvalid}

              />
            </div>
          )}
          {getOptions().map((option) => (
            <div className="d-flex" key={option.value}>
              {
                editActive && isSelected(option) ?
                <div className="dropdown-item selected">
                  <input
                    className="form-control"
                    onChange={(e)=>setEditValue(e.target.value)}
                    value={editValue}
                    autoFocus
                  />
                </div> :

              <div
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick(option);
                }}
                className={`dropdown-item ${isSelected(option) && "selected"}`}
              >
                {option.label}
                
              </div>
              }
              {(role === "Admin" || role === "SuperAdmin") && (
                <div className="d-flex align-items-center">
                  {!isSelected(option) ? (
                    <FaTrash
                      className="mx-2"
                      onClick={() => {
                        deleteOption(option);
                      }}
                      style={{ color: "#d04b4b" }}
                    />
                  ):
                  !editActive && isSelected(option) ? (
                    <FaEdit
                      className="mx-2"
                      style={{ color: "#db8a2f" }}
                      onClick={()=>{
                        faEditClick(option)
                      }}
                    />
                  ):
                  editActive && isSelected(option) ? (
                    <FaCheckCircle
                      className="mx-2"
                      style={{ color: "green" }}
                      onClick={()=>{
                        faCheckMarkClick(option)
                      }}
                    />
                  ):
                  ''
                  }
                </div>
              )}
            </div>
          ))}
          {role !== "Accountant" &&
            searchValue &&
            !options.find((item) => item.label === searchValue) && (
              <div
                onClick={() => {
                  onCreateOption(searchValue);
                  setSearchValue("");
                }}
                className={`dropdown-item`}
              >
                Create: {searchValue}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
