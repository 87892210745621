import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
 
  Tooltip,
} from '@chakra-ui/react';
import DirectTax from 'components/allForm/directTax';
import ColumnTable from './embrillTable/ColumnTable';
import { columnsDataColumns,rejectedDataColumns } from './embrillTable/variables/directTaxColData';
import PdfListModel from 'components/models/PdfListModel';
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { fetchAsyncDeleteDirectPdf } from 'redux/directTaxSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAsyncGetDirectTaxExpense } from 'redux/directTaxSlice';
import { fetchAsyncDeleteDirectTaxExpense } from 'redux/directTaxSlice';
import Swal from 'sweetalert2';
import { poNumberFormate } from 'jsFunctions/helperFunctions';
import dateTimeFormat from 'jsFunctions/dateTimeFormat';
import { currencyFormate } from 'jsFunctions/helperFunctions';
import ExportXLSFile from 'components/ExportXLSFile';
import moment from 'moment';
import { FcApproval } from 'react-icons/fc';
import { MdOutlinePendingActions } from 'react-icons/md';
import ExpenseReports from './expenseGraph';
import { HiReceiptTax } from 'react-icons/hi';
import { CiViewTable } from 'react-icons/ci';
import { PiGraph } from 'react-icons/pi';
import DirectTaxInfoModel from 'components/models/DirectTaxInfoModel';
import { RxCrossCircled } from "react-icons/rx";
import ExportDataModel from 'components/exportData/ExportDataModel';

const EmbrillDirectTax = () => {
  const dispatch = useDispatch();
  const [openDirectTaxModal, setOpneDirectTaxModal] = useState(false);
  const [showPdfList, setShowPdfList] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [activeDataList,setActiveDataList] = useState('approved')
  const [openEditModal, setOpenEditModal] = useState(false);
  const [directTaxEditData, setDirectTaxEditData] = useState(null);
  const [directTaxListData, setDirectTaxListData] = useState([]);
  const [directTaxPdfList, setDirectTaxPdfList] = useState(null);
  const [invoiceData,setInvoiceData] = useState([])
  const [activeGraph,setActiveGraph] = useState(false)
  const [openDirectTaxInfoModel, setOpenDirectTaxInfoModel] = useState(false);
  const currUser = useSelector((state) => state.auth.currUser);


  const [role,setRole] = useState('')  

  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Direct Tax');
      if(roleObj){

        setRole(roleObj?.access)
      }
    }
   
  },[currUser])
  const directTaxList = useSelector(
    (state) => state.directTaxData.directTaxExpense
  );

  const handleCloseDirectTaxModal = () => {
    setOpneDirectTaxModal(false);
    setOpenEditModal(false);
    setDirectTaxEditData(null);
  };
  const handleOpenDirectTaxModal = () => {
    setOpneDirectTaxModal(true);
  };
  const handlerRefreshData = () => {
    dispatch(fetchAsyncGetDirectTaxExpense());
  };
  const deleteFileFromList = (sendData) => {
    dispatch(fetchAsyncDeleteDirectPdf(sendData)).then((response) => {
      handlerRefreshData();
    });
  };


  const getAllApprovedData = ()=>{
    if (directTaxList && directTaxList.length > 0) {
      let listData = [];
      if(role !== "Accountant")
        {
          listData = directTaxList.filter((item)=> item.paymentInfo.totalPayAmountIsApproved === true)
  
        } else {
  
          listData = directTaxList.filter((item)=> item.paymentInfo.totalPayAmountIsApproved === true && currUser.name === item?.paymentInfo?.requestedBy)
        }
      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        return {
          _id:data._id,

          no: index + 1,
          poNo: data.poNo && poNumberFormate(data.poNo),
          orderdate: dateTimeFormat(data.orderDate),
          receivername: data.receiverName,
          approvalAmount: (
            <span
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ), 
          invoice:
          foundInvoiceWithLength  ? (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowPdfList(true);
                  setDirectTaxPdfList(data);
                }}
              />
            ) : (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: 'not-allowed' }}
                color='gray'
              />
            ),
          action: (
            <div className='d-flex'>
              <AiFillEdit
                className='me-2'
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditModal(true);
                  setDirectTaxEditData(data);
                  handleOpenDirectTaxModal();
                }}
              />
               {role === "Admin" && (
              <AiFillDelete
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  Swal.fire({
                    title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                    text:'This action cannot be undone.',
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Delete',
                    showCancelButton: true,
                    customClass: {
                      container: 'sweet_containerImportant',
                      confirmButton: 'sweet_confirmbuttonImportant',
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteDirectTaxData(data);
                    }
                  });
                }}
              />
              )}
            </div>
          ),
        };
      });
      setDirectTaxListData(tableData);
      if (directTaxPdfList) {
        const dataGet = directTaxList.filter(
          (item) => item._id === directTaxPdfList._id
        );
        setDirectTaxPdfList(dataGet[0]);
      }
      if (directTaxEditData) {
        const dataGet = directTaxList.filter(
          (item) => item._id === directTaxEditData._id
        );
        setDirectTaxEditData(dataGet[0]);
      }
    } else {
      setDirectTaxListData([]);
    }
  }



  const getPendingApproveData = ()=>{
    if (directTaxList && directTaxList.length > 0) {
      let listData = []
      if(role !== "Accountant")
      {
        listData = directTaxList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === false))


      } else {
        listData = directTaxList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === false && currUser.name === item?.paymentInfo?.requestedBy))
       
      }

      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        return {
          _id:data._id,

          no: index + 1,
          poNo: data.poNo && poNumberFormate(data.poNo),
          orderdate: dateTimeFormat(data.orderDate),
          receivername: data.receiverName,
          approvalAmount: (
            <span
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),           invoice:
          foundInvoiceWithLength  ? (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowPdfList(true);
                  setDirectTaxPdfList(data);
                }}
              />
            ) : (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: 'not-allowed' }}
                color='gray'
              />
            ),
          action: (
            <div className='d-flex'>
              <AiFillEdit
                className='me-2'
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditModal(true);
                  setDirectTaxEditData(data);
                  handleOpenDirectTaxModal();
                }}
              />
               {role === "Admin" && (
              <AiFillDelete
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  Swal.fire({
                    title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                    text:'This action cannot be undone.',
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Delete',
                    showCancelButton: true,
                    customClass: {
                      container: 'sweet_containerImportant',
                      confirmButton: 'sweet_confirmbuttonImportant',
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteDirectTaxData(data);
                    }
                  });
                }}
              />
               )}
            </div>
          ),
        };
      });
      setDirectTaxListData(tableData);
      if (directTaxPdfList) {
        const dataGet = directTaxList.filter(
          (item) => item._id === directTaxPdfList._id
        );
        setDirectTaxPdfList(dataGet[0]);
      }
      if (directTaxEditData) {
        const dataGet = directTaxList.filter(
          (item) => item._id === directTaxEditData._id
        );
        setDirectTaxEditData(dataGet[0]);
      }
    } else {
      setDirectTaxListData([]);
    }
  }
  const getRejectedData = ()=>{
    if (directTaxList && directTaxList.length > 0) {
      let listData = []
      if(role !== "Accountant")
      {
        listData = directTaxList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === true))
      } else {
        listData = directTaxList.filter((item)=> (item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === true && currUser?.name === item?.paymentInfo?.requestedBy))

      }

      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        console.log(foundInvoiceWithLength)
        return {
          _id:data._id,

          no: index + 1,
          poNo: data.poNo && poNumberFormate(data.poNo),
          orderdate: dateTimeFormat(data.orderDate),
          receivername: data.receiverName,
          approvalAmount: (
            <span
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
          status:'Rejected',
          action: (
            <div className="d-flex">
            
              {
              role === "Admin" ? (
                <AiFillDelete
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  Swal.fire({
                    title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                    text:'This action cannot be undone.',
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Delete',
                    showCancelButton: true,
                    customClass: {
                      container: 'sweet_containerImportant',
                      confirmButton: 'sweet_confirmbuttonImportant',
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteDirectTaxData(data);
                    }
                  });
                }}
              />
              )
              :
              'Not Access'
            }
            </div>
          ),                    

         
        };
      });
      setDirectTaxListData(tableData);
      if (directTaxPdfList) {
        const dataGet = directTaxList.filter(
          (item) => item._id === directTaxPdfList._id
        );
        setDirectTaxPdfList(dataGet[0]);
      }
      if (directTaxEditData) {
        const dataGet = directTaxList.filter(
          (item) => item._id === directTaxEditData._id
        );
        setDirectTaxEditData(dataGet[0]);
      }
    } else {
      setDirectTaxListData([]);
    }
  }

  useEffect(() => {
    if(activeDataList === 'approved'){
      getAllApprovedData();
     }
     else if(activeDataList === 'pending'){

      getPendingApproveData();
    }
    else{
     getRejectedData();
    }
  }, [directTaxList]);

  useEffect(()=>{
    handlerRefreshData();

  },[activeDataList])

  const handleGraph = ()=>{
    setActiveGraph(true)
  }
  const handleTable = ()=>{
    setActiveGraph(false)
  }

  const handleRowClick = (e,_id)=>{
    e.stopPropagation();
    const data = directTaxList.filter((item)=> item?._id === _id)
    setSelectedItem(data[0]);
    setOpenDirectTaxInfoModel(true);
  }

  const closeDirectTaxInfoModel = () => {
    setOpenDirectTaxInfoModel(false);
    setSelectedItem({});
  };

  useEffect(()=>{
    if (directTaxPdfList) {
      const dataGet = directTaxList.filter(
        (item) => item._id === directTaxPdfList._id
      );
      const data = dataGet[0];
      const paymentList = data?.paymentInfo?.list
      let invoices = []
      if(paymentList?.length > 0){
        invoices = paymentList?.flatMap((item, index) => {
          if (item?.invoice) {
            return item.invoice.map(invoice => ({...invoice, listIndex: index }));
          }
          return []; // or return an empty array to avoid undefined values
        });
      }

      setInvoiceData(invoices)
      setDirectTaxPdfList(dataGet[0]);
    }
  },[directTaxPdfList])

  const deleteDirectTaxData = (data) => {
    dispatch(fetchAsyncDeleteDirectTaxExpense(data._id)).then(() => {
      handlerRefreshData();
    });
  };

  useEffect(() => {
    dispatch(fetchAsyncGetDirectTaxExpense());
  }, []);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <div className='button-div'>
        <Tooltip label='Rejected' fontSize='md' borderRadius='2xl'>
          <Button className="add-btn" onClick={()=>setActiveDataList('rejected')}>
            <RxCrossCircled size={30} color="red" />
          </Button>
          </Tooltip>
          {directTaxList && (
       
         <ExportDataModel
         filename={"DirectTax_"}
         type = "directTax"
         role = {role}
        />
          )}
           <Tooltip label='Add New' fontSize='md' borderRadius='2xl'>

          <Button className='btn add-btn' onClick={handleOpenDirectTaxModal}>
          
            <img
              src='https://cdn-icons-png.flaticon.com/128/148/148764.png'
              alt='add'
              style={{ width: '30px', height: '30px', margin: '9px' }}
            />
          </Button>
           </Tooltip>
        </div>
        {
        <div className={`button-div list-btn ${activeGraph ? 'list-btn-disabled' : ''}`}>
          {/* {activeDataList === 'pending' ? */}
          <Tooltip label='Approved Data' fontSize='md' borderRadius='2xl'> 
          <Button className={`btn ${activeDataList !== 'pending' ? 'list-btn-active':''}`} onClick={()=>setActiveDataList('approved')}  disabled={activeGraph}>
             <FcApproval size={30}/>
            
            
          </Button>
          </Tooltip>
            
          {/* : */}

          <Tooltip label='Pending List' fontSize='md' borderRadius='2xl'>

          <Button className={`btn ${activeDataList === 'pending' ? 'list-btn-active':''}`} onClick={()=>setActiveDataList('pending')} disabled={activeGraph}>
             <MdOutlinePendingActions size={30}  />          
          </Button>
          </Tooltip>
{/* } */}
        </div>
        }
      </Box>
      <div 
        className="graph_button button-div direct-tax-Graph-btn"        
       >
        <div  className={`btn graph_icon_button ${!activeGraph ? 'active_graph' : ''}`}  onClick={()=>handleTable()}><CiViewTable  /></div>
        {role !== 'Accountant' ?
        <div  className={`btn graph_icon_button ${activeGraph ? 'active_graph' : ''}`} onClick={()=>handleGraph()}><PiGraph/></div>
        :
        <div  className={`btn graph_icon_button ${activeGraph ? 'active_graph' : ''}`} style={{opacity:'0.3',cursor:'not-allowed'}}><PiGraph/></div>
        }        
       </div>
      <Box pt={{ base: '180px', md: '120px', xl: '120px' }}>
      {activeGraph ?
      <Box className={`graph-card ${activeGraph ? 'graph_active' : ''}`}>
          
      <ExpenseReports
        type='directTax'
        name = 'Direct Tax Total Expense'
        heading = "Direct Tax"
        icon = {HiReceiptTax}
        role={role}
      /> 
      </Box>
      :
        <ColumnTable
        columnsData = {activeDataList === 'rejected' ?rejectedDataColumns :columnsDataColumns}  
          tableData={directTaxListData}
          type={'Direct Tax'}
          expenseType = 'directTax'
          getAllData={(data) => {
            handleOpenDirectTaxModal();
          }}
          openInfoModal = {handleRowClick}
          activeDataList = {activeDataList}
          role={role}

        />
      }

      </Box>
      {openDirectTaxModal && (
        <DirectTax
          openModal={openDirectTaxModal}
          closeModal={handleCloseDirectTaxModal}
          directTaxExpense={[]}
          isEditForm={openEditModal}
          editItem={directTaxEditData}
          refreshEditData={handlerRefreshData}
          activeList = {activeDataList}
          role={role}

        />
      )}
      {showPdfList && (
        <PdfListModel
          pdfData={directTaxPdfList ? invoiceData : []}
          currentId={directTaxPdfList ? directTaxPdfList._id : ''}
          openModal={showPdfList}
          closeModal={() => {
            setShowPdfList(false);
            setDirectTaxPdfList(null);
          }}
          deleteFileFromList={deleteFileFromList}
          role={role}

        />
      )}
       {openDirectTaxInfoModel && (
        <DirectTaxInfoModel
          openInfoModal={openDirectTaxInfoModel}
          closeInfoModal={closeDirectTaxInfoModel}
          selectedData={selectedItem}   
          role={role}
     
        />
      )}
    </>
  );
};

export default EmbrillDirectTax;
