import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddUser = createAsyncThunk(
  "/fetchAsyncAddUser",
  async (data) => {
    try {
      const response = await axiosInstance.post('/user/create', data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncUpdateUser = createAsyncThunk(
  "/fetchAsyncUpdateUser",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/user/update/${data.id}`, data.userData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteUser = createAsyncThunk(
  "/fetchAsyncDeleteUser",
  async (id) => {
    try {
      let url = `${API_URL}/user/delete/${id}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetUser = createAsyncThunk(
  "/fetchAsyncGetUser",
  async () => {
    try {
      let url = `${API_URL}/user/get`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetExpenseBarChart = createAsyncThunk(
  "/fetchAsyncGetExpenseBarChart",
  async (data) => {
    try {
      let url = `${API_URL}/expense/finencialYearData/${data.expanse}?startDate=${data.startYear}-04-01T00:00:00.000Z&endDate=${data.endYear}-03-31T00:00:00.000Z`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error("Token Expire Please Login again!");
      } else {
        throw new Error(error.response.data.message);
      }
    }
  }
);
export const fetchAsyncGetInternalExpenseBarChart = createAsyncThunk(
  "/fetchAsyncGetInternalExpenseBarChart",
  async (data) => {
    try {
      let url = `${API_URL}/expense/internalFinencialYearData`;
      const response = await axiosInstance.post(url,data);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error("Token Expire Please Login again!");
      } else {
        throw new Error(error.response.data.message);
      }
    }
  }
);

export const fetchAsyncGetExpensePiChart = createAsyncThunk(
  "/fetchAsyncGetExpensePiChart",
  async (data) => {
    try {
      let url = `${API_URL}/expense/piChartData?startDate=${data.startTime}-01T00:00:00.000Z&endDate=${data.endTime}-31T00:00:00.000Z`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error("Token Expire Please Login again!");
      } else {
        throw new Error(error.response.data.message);
      }
    }
  }
);

export const fetchAsyncDeleteRoleAccess = createAsyncThunk(
  "/fetchAsyncDeleteRoleAccess",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/user/delete-role-access/${data.id}`, data.moduleData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const AllUserSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncGetUser.pending]: (state) => {},
    [fetchAsyncGetUser.fulfilled]: (state, { payload }) => {
      return payload;
    },
  },
});

export const getAllUser = (state) => state.userData;

export default AllUserSlice.reducer;
